@charset "UTF-8";
/**
  Import Variables
 */
/**
  Import common SASS files
 */
/**
  Import mixins before _utilities
 */
.bg-gray-100 {
  background-color: #fcfcfc !important;
}

a.bg-gray-100:hover, a.bg-gray-100:focus,
button.bg-gray-100:hover,
button.bg-gray-100:focus {
  background-color: #e2e2e2 !important;
}

.bg-gray-200 {
  background-color: #eff2f3 !important;
}

a.bg-gray-200:hover, a.bg-gray-200:focus,
button.bg-gray-200:hover,
button.bg-gray-200:focus {
  background-color: #d2dadd !important;
}

.bg-gray-300 {
  background-color: #e6ebec !important;
}

a.bg-gray-300:hover, a.bg-gray-300:focus,
button.bg-gray-300:hover,
button.bg-gray-300:focus {
  background-color: #c9d2d6 !important;
}

.bg-gray-400 {
  background-color: #d2dadd !important;
}

a.bg-gray-400:hover, a.bg-gray-400:focus,
button.bg-gray-400:hover,
button.bg-gray-400:focus {
  background-color: #b5c2c7 !important;
}

.bg-gray-500 {
  background-color: #a4b3ba !important;
}

a.bg-gray-500:hover, a.bg-gray-500:focus,
button.bg-gray-500:hover,
button.bg-gray-500:focus {
  background-color: #879ba4 !important;
}

.bg-gray-600 {
  background-color: #81969f !important;
}

a.bg-gray-600:hover, a.bg-gray-600:focus,
button.bg-gray-600:hover,
button.bg-gray-600:focus {
  background-color: #667d87 !important;
}

.bg-gray-700 {
  background-color: #627881 !important;
}

a.bg-gray-700:hover, a.bg-gray-700:focus,
button.bg-gray-700:hover,
button.bg-gray-700:focus {
  background-color: #4c5d64 !important;
}

.bg-gray-800 {
  background-color: #364247 !important;
}

a.bg-gray-800:hover, a.bg-gray-800:focus,
button.bg-gray-800:hover,
button.bg-gray-800:focus {
  background-color: #20272a !important;
}

.bg-gray-900 {
  background-color: #212529 !important;
}

a.bg-gray-900:hover, a.bg-gray-900:focus,
button.bg-gray-900:hover,
button.bg-gray-900:focus {
  background-color: #0a0c0d !important;
}

.bg-gray-150 {
  background-color: #f8f9fa !important;
}

a.bg-gray-150:hover, a.bg-gray-150:focus,
button.bg-gray-150:hover,
button.bg-gray-150:focus {
  background-color: #dbe1e3 !important;
}

.page-loading {
  display: none;
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

.sd-m-0 {
  margin: 0 !important;
}

.sd-mt-0,
.sd-my-0 {
  margin-top: 0 !important;
}

.sd-mr-0,
.sd-mx-0 {
  margin-right: 0 !important;
}

.sd-mb-0,
.sd-my-0 {
  margin-bottom: 0 !important;
}

.sd-ml-0,
.sd-mx-0 {
  margin-left: 0 !important;
}

.sd-m-0_25 {
  margin: 0.25rem !important;
}

.sd-mt-0_25,
.sd-my-0_25 {
  margin-top: 0.25rem !important;
}

.sd-mr-0_25,
.sd-mx-0_25 {
  margin-right: 0.25rem !important;
}

.sd-mb-0_25,
.sd-my-0_25 {
  margin-bottom: 0.25rem !important;
}

.sd-ml-0_25,
.sd-mx-0_25 {
  margin-left: 0.25rem !important;
}

.sd-m-0_5 {
  margin: 0.5rem !important;
}

.sd-mt-0_5,
.sd-my-0_5 {
  margin-top: 0.5rem !important;
}

.sd-mr-0_5,
.sd-mx-0_5 {
  margin-right: 0.5rem !important;
}

.sd-mb-0_5,
.sd-my-0_5 {
  margin-bottom: 0.5rem !important;
}

.sd-ml-0_5,
.sd-mx-0_5 {
  margin-left: 0.5rem !important;
}

.sd-m-0_75 {
  margin: 0.75rem !important;
}

.sd-mt-0_75,
.sd-my-0_75 {
  margin-top: 0.75rem !important;
}

.sd-mr-0_75,
.sd-mx-0_75 {
  margin-right: 0.75rem !important;
}

.sd-mb-0_75,
.sd-my-0_75 {
  margin-bottom: 0.75rem !important;
}

.sd-ml-0_75,
.sd-mx-0_75 {
  margin-left: 0.75rem !important;
}

.sd-m-1 {
  margin: 1rem !important;
}

.sd-mt-1,
.sd-my-1 {
  margin-top: 1rem !important;
}

.sd-mr-1,
.sd-mx-1 {
  margin-right: 1rem !important;
}

.sd-mb-1,
.sd-my-1 {
  margin-bottom: 1rem !important;
}

.sd-ml-1,
.sd-mx-1 {
  margin-left: 1rem !important;
}

.sd-m-1_125 {
  margin: 1.125rem !important;
}

.sd-mt-1_125,
.sd-my-1_125 {
  margin-top: 1.125rem !important;
}

.sd-mr-1_125,
.sd-mx-1_125 {
  margin-right: 1.125rem !important;
}

.sd-mb-1_125,
.sd-my-1_125 {
  margin-bottom: 1.125rem !important;
}

.sd-ml-1_125,
.sd-mx-1_125 {
  margin-left: 1.125rem !important;
}

.sd-m-1_25 {
  margin: 1.25rem !important;
}

.sd-mt-1_25,
.sd-my-1_25 {
  margin-top: 1.25rem !important;
}

.sd-mr-1_25,
.sd-mx-1_25 {
  margin-right: 1.25rem !important;
}

.sd-mb-1_25,
.sd-my-1_25 {
  margin-bottom: 1.25rem !important;
}

.sd-ml-1_25,
.sd-mx-1_25 {
  margin-left: 1.25rem !important;
}

.sd-m-1_5 {
  margin: 1.5rem !important;
}

.sd-mt-1_5,
.sd-my-1_5 {
  margin-top: 1.5rem !important;
}

.sd-mr-1_5,
.sd-mx-1_5 {
  margin-right: 1.5rem !important;
}

.sd-mb-1_5,
.sd-my-1_5 {
  margin-bottom: 1.5rem !important;
}

.sd-ml-1_5,
.sd-mx-1_5 {
  margin-left: 1.5rem !important;
}

.sd-m-1_75 {
  margin: 1.75rem !important;
}

.sd-mt-1_75,
.sd-my-1_75 {
  margin-top: 1.75rem !important;
}

.sd-mr-1_75,
.sd-mx-1_75 {
  margin-right: 1.75rem !important;
}

.sd-mb-1_75,
.sd-my-1_75 {
  margin-bottom: 1.75rem !important;
}

.sd-ml-1_75,
.sd-mx-1_75 {
  margin-left: 1.75rem !important;
}

.sd-m-2 {
  margin: 2rem !important;
}

.sd-mt-2,
.sd-my-2 {
  margin-top: 2rem !important;
}

.sd-mr-2,
.sd-mx-2 {
  margin-right: 2rem !important;
}

.sd-mb-2,
.sd-my-2 {
  margin-bottom: 2rem !important;
}

.sd-ml-2,
.sd-mx-2 {
  margin-left: 2rem !important;
}

.sd-m-2_5 {
  margin: 2.5rem !important;
}

.sd-mt-2_5,
.sd-my-2_5 {
  margin-top: 2.5rem !important;
}

.sd-mr-2_5,
.sd-mx-2_5 {
  margin-right: 2.5rem !important;
}

.sd-mb-2_5,
.sd-my-2_5 {
  margin-bottom: 2.5rem !important;
}

.sd-ml-2_5,
.sd-mx-2_5 {
  margin-left: 2.5rem !important;
}

.sd-m-2_75 {
  margin: 2.75rem !important;
}

.sd-mt-2_75,
.sd-my-2_75 {
  margin-top: 2.75rem !important;
}

.sd-mr-2_75,
.sd-mx-2_75 {
  margin-right: 2.75rem !important;
}

.sd-mb-2_75,
.sd-my-2_75 {
  margin-bottom: 2.75rem !important;
}

.sd-ml-2_75,
.sd-mx-2_75 {
  margin-left: 2.75rem !important;
}

.sd-m-3 {
  margin: 3rem !important;
}

.sd-mt-3,
.sd-my-3 {
  margin-top: 3rem !important;
}

.sd-mr-3,
.sd-mx-3 {
  margin-right: 3rem !important;
}

.sd-mb-3,
.sd-my-3 {
  margin-bottom: 3rem !important;
}

.sd-ml-3,
.sd-mx-3 {
  margin-left: 3rem !important;
}

.sd-m-3_25 {
  margin: 3.25rem !important;
}

.sd-mt-3_25,
.sd-my-3_25 {
  margin-top: 3.25rem !important;
}

.sd-mr-3_25,
.sd-mx-3_25 {
  margin-right: 3.25rem !important;
}

.sd-mb-3_25,
.sd-my-3_25 {
  margin-bottom: 3.25rem !important;
}

.sd-ml-3_25,
.sd-mx-3_25 {
  margin-left: 3.25rem !important;
}

.sd-m-3_5 {
  margin: 3.5rem !important;
}

.sd-mt-3_5,
.sd-my-3_5 {
  margin-top: 3.5rem !important;
}

.sd-mr-3_5,
.sd-mx-3_5 {
  margin-right: 3.5rem !important;
}

.sd-mb-3_5,
.sd-my-3_5 {
  margin-bottom: 3.5rem !important;
}

.sd-ml-3_5,
.sd-mx-3_5 {
  margin-left: 3.5rem !important;
}

.sd-m-4 {
  margin: 4rem !important;
}

.sd-mt-4,
.sd-my-4 {
  margin-top: 4rem !important;
}

.sd-mr-4,
.sd-mx-4 {
  margin-right: 4rem !important;
}

.sd-mb-4,
.sd-my-4 {
  margin-bottom: 4rem !important;
}

.sd-ml-4,
.sd-mx-4 {
  margin-left: 4rem !important;
}

.sd-m-5 {
  margin: 5rem !important;
}

.sd-mt-5,
.sd-my-5 {
  margin-top: 5rem !important;
}

.sd-mr-5,
.sd-mx-5 {
  margin-right: 5rem !important;
}

.sd-mb-5,
.sd-my-5 {
  margin-bottom: 5rem !important;
}

.sd-ml-5,
.sd-mx-5 {
  margin-left: 5rem !important;
}

.sd-m-5_5 {
  margin: 5.5rem !important;
}

.sd-mt-5_5,
.sd-my-5_5 {
  margin-top: 5.5rem !important;
}

.sd-mr-5_5,
.sd-mx-5_5 {
  margin-right: 5.5rem !important;
}

.sd-mb-5_5,
.sd-my-5_5 {
  margin-bottom: 5.5rem !important;
}

.sd-ml-5_5,
.sd-mx-5_5 {
  margin-left: 5.5rem !important;
}

.sd-m-6 {
  margin: 6rem !important;
}

.sd-mt-6,
.sd-my-6 {
  margin-top: 6rem !important;
}

.sd-mr-6,
.sd-mx-6 {
  margin-right: 6rem !important;
}

.sd-mb-6,
.sd-my-6 {
  margin-bottom: 6rem !important;
}

.sd-ml-6,
.sd-mx-6 {
  margin-left: 6rem !important;
}

.sd-m-7 {
  margin: 7rem !important;
}

.sd-mt-7,
.sd-my-7 {
  margin-top: 7rem !important;
}

.sd-mr-7,
.sd-mx-7 {
  margin-right: 7rem !important;
}

.sd-mb-7,
.sd-my-7 {
  margin-bottom: 7rem !important;
}

.sd-ml-7,
.sd-mx-7 {
  margin-left: 7rem !important;
}

.sd-m-9 {
  margin: 9rem !important;
}

.sd-mt-9,
.sd-my-9 {
  margin-top: 9rem !important;
}

.sd-mr-9,
.sd-mx-9 {
  margin-right: 9rem !important;
}

.sd-mb-9,
.sd-my-9 {
  margin-bottom: 9rem !important;
}

.sd-ml-9,
.sd-mx-9 {
  margin-left: 9rem !important;
}

.sd-p-0 {
  padding: 0 !important;
}

.sd-pt-0,
.sd-py-0 {
  padding-top: 0 !important;
}

.sd-pr-0,
.sd-px-0 {
  padding-right: 0 !important;
}

.sd-pb-0,
.sd-py-0 {
  padding-bottom: 0 !important;
}

.sd-pl-0,
.sd-px-0 {
  padding-left: 0 !important;
}

.sd-p-0_25 {
  padding: 0.25rem !important;
}

.sd-pt-0_25,
.sd-py-0_25 {
  padding-top: 0.25rem !important;
}

.sd-pr-0_25,
.sd-px-0_25 {
  padding-right: 0.25rem !important;
}

.sd-pb-0_25,
.sd-py-0_25 {
  padding-bottom: 0.25rem !important;
}

.sd-pl-0_25,
.sd-px-0_25 {
  padding-left: 0.25rem !important;
}

.sd-p-0_5 {
  padding: 0.5rem !important;
}

.sd-pt-0_5,
.sd-py-0_5 {
  padding-top: 0.5rem !important;
}

.sd-pr-0_5,
.sd-px-0_5 {
  padding-right: 0.5rem !important;
}

.sd-pb-0_5,
.sd-py-0_5 {
  padding-bottom: 0.5rem !important;
}

.sd-pl-0_5,
.sd-px-0_5 {
  padding-left: 0.5rem !important;
}

.sd-p-0_75 {
  padding: 0.75rem !important;
}

.sd-pt-0_75,
.sd-py-0_75 {
  padding-top: 0.75rem !important;
}

.sd-pr-0_75,
.sd-px-0_75 {
  padding-right: 0.75rem !important;
}

.sd-pb-0_75,
.sd-py-0_75 {
  padding-bottom: 0.75rem !important;
}

.sd-pl-0_75,
.sd-px-0_75 {
  padding-left: 0.75rem !important;
}

.sd-p-1 {
  padding: 1rem !important;
}

.sd-pt-1,
.sd-py-1 {
  padding-top: 1rem !important;
}

.sd-pr-1,
.sd-px-1 {
  padding-right: 1rem !important;
}

.sd-pb-1,
.sd-py-1 {
  padding-bottom: 1rem !important;
}

.sd-pl-1,
.sd-px-1 {
  padding-left: 1rem !important;
}

.sd-p-1_125 {
  padding: 1.125rem !important;
}

.sd-pt-1_125,
.sd-py-1_125 {
  padding-top: 1.125rem !important;
}

.sd-pr-1_125,
.sd-px-1_125 {
  padding-right: 1.125rem !important;
}

.sd-pb-1_125,
.sd-py-1_125 {
  padding-bottom: 1.125rem !important;
}

.sd-pl-1_125,
.sd-px-1_125 {
  padding-left: 1.125rem !important;
}

.sd-p-1_25 {
  padding: 1.25rem !important;
}

.sd-pt-1_25,
.sd-py-1_25 {
  padding-top: 1.25rem !important;
}

.sd-pr-1_25,
.sd-px-1_25 {
  padding-right: 1.25rem !important;
}

.sd-pb-1_25,
.sd-py-1_25 {
  padding-bottom: 1.25rem !important;
}

.sd-pl-1_25,
.sd-px-1_25 {
  padding-left: 1.25rem !important;
}

.sd-p-1_5 {
  padding: 1.5rem !important;
}

.sd-pt-1_5,
.sd-py-1_5 {
  padding-top: 1.5rem !important;
}

.sd-pr-1_5,
.sd-px-1_5 {
  padding-right: 1.5rem !important;
}

.sd-pb-1_5,
.sd-py-1_5 {
  padding-bottom: 1.5rem !important;
}

.sd-pl-1_5,
.sd-px-1_5 {
  padding-left: 1.5rem !important;
}

.sd-p-1_75 {
  padding: 1.75rem !important;
}

.sd-pt-1_75,
.sd-py-1_75 {
  padding-top: 1.75rem !important;
}

.sd-pr-1_75,
.sd-px-1_75 {
  padding-right: 1.75rem !important;
}

.sd-pb-1_75,
.sd-py-1_75 {
  padding-bottom: 1.75rem !important;
}

.sd-pl-1_75,
.sd-px-1_75 {
  padding-left: 1.75rem !important;
}

.sd-p-2 {
  padding: 2rem !important;
}

.sd-pt-2,
.sd-py-2 {
  padding-top: 2rem !important;
}

.sd-pr-2,
.sd-px-2 {
  padding-right: 2rem !important;
}

.sd-pb-2,
.sd-py-2 {
  padding-bottom: 2rem !important;
}

.sd-pl-2,
.sd-px-2 {
  padding-left: 2rem !important;
}

.sd-p-2_5 {
  padding: 2.5rem !important;
}

.sd-pt-2_5,
.sd-py-2_5 {
  padding-top: 2.5rem !important;
}

.sd-pr-2_5,
.sd-px-2_5 {
  padding-right: 2.5rem !important;
}

.sd-pb-2_5,
.sd-py-2_5 {
  padding-bottom: 2.5rem !important;
}

.sd-pl-2_5,
.sd-px-2_5 {
  padding-left: 2.5rem !important;
}

.sd-p-2_75 {
  padding: 2.75rem !important;
}

.sd-pt-2_75,
.sd-py-2_75 {
  padding-top: 2.75rem !important;
}

.sd-pr-2_75,
.sd-px-2_75 {
  padding-right: 2.75rem !important;
}

.sd-pb-2_75,
.sd-py-2_75 {
  padding-bottom: 2.75rem !important;
}

.sd-pl-2_75,
.sd-px-2_75 {
  padding-left: 2.75rem !important;
}

.sd-p-3 {
  padding: 3rem !important;
}

.sd-pt-3,
.sd-py-3 {
  padding-top: 3rem !important;
}

.sd-pr-3,
.sd-px-3 {
  padding-right: 3rem !important;
}

.sd-pb-3,
.sd-py-3 {
  padding-bottom: 3rem !important;
}

.sd-pl-3,
.sd-px-3 {
  padding-left: 3rem !important;
}

.sd-p-3_25 {
  padding: 3.25rem !important;
}

.sd-pt-3_25,
.sd-py-3_25 {
  padding-top: 3.25rem !important;
}

.sd-pr-3_25,
.sd-px-3_25 {
  padding-right: 3.25rem !important;
}

.sd-pb-3_25,
.sd-py-3_25 {
  padding-bottom: 3.25rem !important;
}

.sd-pl-3_25,
.sd-px-3_25 {
  padding-left: 3.25rem !important;
}

.sd-p-3_5 {
  padding: 3.5rem !important;
}

.sd-pt-3_5,
.sd-py-3_5 {
  padding-top: 3.5rem !important;
}

.sd-pr-3_5,
.sd-px-3_5 {
  padding-right: 3.5rem !important;
}

.sd-pb-3_5,
.sd-py-3_5 {
  padding-bottom: 3.5rem !important;
}

.sd-pl-3_5,
.sd-px-3_5 {
  padding-left: 3.5rem !important;
}

.sd-p-4 {
  padding: 4rem !important;
}

.sd-pt-4,
.sd-py-4 {
  padding-top: 4rem !important;
}

.sd-pr-4,
.sd-px-4 {
  padding-right: 4rem !important;
}

.sd-pb-4,
.sd-py-4 {
  padding-bottom: 4rem !important;
}

.sd-pl-4,
.sd-px-4 {
  padding-left: 4rem !important;
}

.sd-p-5 {
  padding: 5rem !important;
}

.sd-pt-5,
.sd-py-5 {
  padding-top: 5rem !important;
}

.sd-pr-5,
.sd-px-5 {
  padding-right: 5rem !important;
}

.sd-pb-5,
.sd-py-5 {
  padding-bottom: 5rem !important;
}

.sd-pl-5,
.sd-px-5 {
  padding-left: 5rem !important;
}

.sd-p-5_5 {
  padding: 5.5rem !important;
}

.sd-pt-5_5,
.sd-py-5_5 {
  padding-top: 5.5rem !important;
}

.sd-pr-5_5,
.sd-px-5_5 {
  padding-right: 5.5rem !important;
}

.sd-pb-5_5,
.sd-py-5_5 {
  padding-bottom: 5.5rem !important;
}

.sd-pl-5_5,
.sd-px-5_5 {
  padding-left: 5.5rem !important;
}

.sd-p-6 {
  padding: 6rem !important;
}

.sd-pt-6,
.sd-py-6 {
  padding-top: 6rem !important;
}

.sd-pr-6,
.sd-px-6 {
  padding-right: 6rem !important;
}

.sd-pb-6,
.sd-py-6 {
  padding-bottom: 6rem !important;
}

.sd-pl-6,
.sd-px-6 {
  padding-left: 6rem !important;
}

.sd-p-7 {
  padding: 7rem !important;
}

.sd-pt-7,
.sd-py-7 {
  padding-top: 7rem !important;
}

.sd-pr-7,
.sd-px-7 {
  padding-right: 7rem !important;
}

.sd-pb-7,
.sd-py-7 {
  padding-bottom: 7rem !important;
}

.sd-pl-7,
.sd-px-7 {
  padding-left: 7rem !important;
}

.sd-p-9 {
  padding: 9rem !important;
}

.sd-pt-9,
.sd-py-9 {
  padding-top: 9rem !important;
}

.sd-pr-9,
.sd-px-9 {
  padding-right: 9rem !important;
}

.sd-pb-9,
.sd-py-9 {
  padding-bottom: 9rem !important;
}

.sd-pl-9,
.sd-px-9 {
  padding-left: 9rem !important;
}

.sd-m-auto {
  margin: auto !important;
}

.sd-mt-auto,
.sd-my-auto {
  margin-top: auto !important;
}

.sd-mr-auto,
.sd-mx-auto {
  margin-right: auto !important;
}

.sd-mb-auto,
.sd-my-auto {
  margin-bottom: auto !important;
}

.sd-ml-auto,
.sd-mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .sd-m-sm-0 {
    margin: 0 !important;
  }
  .sd-mt-sm-0,
  .sd-my-sm-0 {
    margin-top: 0 !important;
  }
  .sd-mr-sm-0,
  .sd-mx-sm-0 {
    margin-right: 0 !important;
  }
  .sd-mb-sm-0,
  .sd-my-sm-0 {
    margin-bottom: 0 !important;
  }
  .sd-ml-sm-0,
  .sd-mx-sm-0 {
    margin-left: 0 !important;
  }
  .sd-m-sm-0_25 {
    margin: 0.25rem !important;
  }
  .sd-mt-sm-0_25,
  .sd-my-sm-0_25 {
    margin-top: 0.25rem !important;
  }
  .sd-mr-sm-0_25,
  .sd-mx-sm-0_25 {
    margin-right: 0.25rem !important;
  }
  .sd-mb-sm-0_25,
  .sd-my-sm-0_25 {
    margin-bottom: 0.25rem !important;
  }
  .sd-ml-sm-0_25,
  .sd-mx-sm-0_25 {
    margin-left: 0.25rem !important;
  }
  .sd-m-sm-0_5 {
    margin: 0.5rem !important;
  }
  .sd-mt-sm-0_5,
  .sd-my-sm-0_5 {
    margin-top: 0.5rem !important;
  }
  .sd-mr-sm-0_5,
  .sd-mx-sm-0_5 {
    margin-right: 0.5rem !important;
  }
  .sd-mb-sm-0_5,
  .sd-my-sm-0_5 {
    margin-bottom: 0.5rem !important;
  }
  .sd-ml-sm-0_5,
  .sd-mx-sm-0_5 {
    margin-left: 0.5rem !important;
  }
  .sd-m-sm-0_75 {
    margin: 0.75rem !important;
  }
  .sd-mt-sm-0_75,
  .sd-my-sm-0_75 {
    margin-top: 0.75rem !important;
  }
  .sd-mr-sm-0_75,
  .sd-mx-sm-0_75 {
    margin-right: 0.75rem !important;
  }
  .sd-mb-sm-0_75,
  .sd-my-sm-0_75 {
    margin-bottom: 0.75rem !important;
  }
  .sd-ml-sm-0_75,
  .sd-mx-sm-0_75 {
    margin-left: 0.75rem !important;
  }
  .sd-m-sm-1 {
    margin: 1rem !important;
  }
  .sd-mt-sm-1,
  .sd-my-sm-1 {
    margin-top: 1rem !important;
  }
  .sd-mr-sm-1,
  .sd-mx-sm-1 {
    margin-right: 1rem !important;
  }
  .sd-mb-sm-1,
  .sd-my-sm-1 {
    margin-bottom: 1rem !important;
  }
  .sd-ml-sm-1,
  .sd-mx-sm-1 {
    margin-left: 1rem !important;
  }
  .sd-m-sm-1_125 {
    margin: 1.125rem !important;
  }
  .sd-mt-sm-1_125,
  .sd-my-sm-1_125 {
    margin-top: 1.125rem !important;
  }
  .sd-mr-sm-1_125,
  .sd-mx-sm-1_125 {
    margin-right: 1.125rem !important;
  }
  .sd-mb-sm-1_125,
  .sd-my-sm-1_125 {
    margin-bottom: 1.125rem !important;
  }
  .sd-ml-sm-1_125,
  .sd-mx-sm-1_125 {
    margin-left: 1.125rem !important;
  }
  .sd-m-sm-1_25 {
    margin: 1.25rem !important;
  }
  .sd-mt-sm-1_25,
  .sd-my-sm-1_25 {
    margin-top: 1.25rem !important;
  }
  .sd-mr-sm-1_25,
  .sd-mx-sm-1_25 {
    margin-right: 1.25rem !important;
  }
  .sd-mb-sm-1_25,
  .sd-my-sm-1_25 {
    margin-bottom: 1.25rem !important;
  }
  .sd-ml-sm-1_25,
  .sd-mx-sm-1_25 {
    margin-left: 1.25rem !important;
  }
  .sd-m-sm-1_5 {
    margin: 1.5rem !important;
  }
  .sd-mt-sm-1_5,
  .sd-my-sm-1_5 {
    margin-top: 1.5rem !important;
  }
  .sd-mr-sm-1_5,
  .sd-mx-sm-1_5 {
    margin-right: 1.5rem !important;
  }
  .sd-mb-sm-1_5,
  .sd-my-sm-1_5 {
    margin-bottom: 1.5rem !important;
  }
  .sd-ml-sm-1_5,
  .sd-mx-sm-1_5 {
    margin-left: 1.5rem !important;
  }
  .sd-m-sm-1_75 {
    margin: 1.75rem !important;
  }
  .sd-mt-sm-1_75,
  .sd-my-sm-1_75 {
    margin-top: 1.75rem !important;
  }
  .sd-mr-sm-1_75,
  .sd-mx-sm-1_75 {
    margin-right: 1.75rem !important;
  }
  .sd-mb-sm-1_75,
  .sd-my-sm-1_75 {
    margin-bottom: 1.75rem !important;
  }
  .sd-ml-sm-1_75,
  .sd-mx-sm-1_75 {
    margin-left: 1.75rem !important;
  }
  .sd-m-sm-2 {
    margin: 2rem !important;
  }
  .sd-mt-sm-2,
  .sd-my-sm-2 {
    margin-top: 2rem !important;
  }
  .sd-mr-sm-2,
  .sd-mx-sm-2 {
    margin-right: 2rem !important;
  }
  .sd-mb-sm-2,
  .sd-my-sm-2 {
    margin-bottom: 2rem !important;
  }
  .sd-ml-sm-2,
  .sd-mx-sm-2 {
    margin-left: 2rem !important;
  }
  .sd-m-sm-2_5 {
    margin: 2.5rem !important;
  }
  .sd-mt-sm-2_5,
  .sd-my-sm-2_5 {
    margin-top: 2.5rem !important;
  }
  .sd-mr-sm-2_5,
  .sd-mx-sm-2_5 {
    margin-right: 2.5rem !important;
  }
  .sd-mb-sm-2_5,
  .sd-my-sm-2_5 {
    margin-bottom: 2.5rem !important;
  }
  .sd-ml-sm-2_5,
  .sd-mx-sm-2_5 {
    margin-left: 2.5rem !important;
  }
  .sd-m-sm-2_75 {
    margin: 2.75rem !important;
  }
  .sd-mt-sm-2_75,
  .sd-my-sm-2_75 {
    margin-top: 2.75rem !important;
  }
  .sd-mr-sm-2_75,
  .sd-mx-sm-2_75 {
    margin-right: 2.75rem !important;
  }
  .sd-mb-sm-2_75,
  .sd-my-sm-2_75 {
    margin-bottom: 2.75rem !important;
  }
  .sd-ml-sm-2_75,
  .sd-mx-sm-2_75 {
    margin-left: 2.75rem !important;
  }
  .sd-m-sm-3 {
    margin: 3rem !important;
  }
  .sd-mt-sm-3,
  .sd-my-sm-3 {
    margin-top: 3rem !important;
  }
  .sd-mr-sm-3,
  .sd-mx-sm-3 {
    margin-right: 3rem !important;
  }
  .sd-mb-sm-3,
  .sd-my-sm-3 {
    margin-bottom: 3rem !important;
  }
  .sd-ml-sm-3,
  .sd-mx-sm-3 {
    margin-left: 3rem !important;
  }
  .sd-m-sm-3_25 {
    margin: 3.25rem !important;
  }
  .sd-mt-sm-3_25,
  .sd-my-sm-3_25 {
    margin-top: 3.25rem !important;
  }
  .sd-mr-sm-3_25,
  .sd-mx-sm-3_25 {
    margin-right: 3.25rem !important;
  }
  .sd-mb-sm-3_25,
  .sd-my-sm-3_25 {
    margin-bottom: 3.25rem !important;
  }
  .sd-ml-sm-3_25,
  .sd-mx-sm-3_25 {
    margin-left: 3.25rem !important;
  }
  .sd-m-sm-3_5 {
    margin: 3.5rem !important;
  }
  .sd-mt-sm-3_5,
  .sd-my-sm-3_5 {
    margin-top: 3.5rem !important;
  }
  .sd-mr-sm-3_5,
  .sd-mx-sm-3_5 {
    margin-right: 3.5rem !important;
  }
  .sd-mb-sm-3_5,
  .sd-my-sm-3_5 {
    margin-bottom: 3.5rem !important;
  }
  .sd-ml-sm-3_5,
  .sd-mx-sm-3_5 {
    margin-left: 3.5rem !important;
  }
  .sd-m-sm-4 {
    margin: 4rem !important;
  }
  .sd-mt-sm-4,
  .sd-my-sm-4 {
    margin-top: 4rem !important;
  }
  .sd-mr-sm-4,
  .sd-mx-sm-4 {
    margin-right: 4rem !important;
  }
  .sd-mb-sm-4,
  .sd-my-sm-4 {
    margin-bottom: 4rem !important;
  }
  .sd-ml-sm-4,
  .sd-mx-sm-4 {
    margin-left: 4rem !important;
  }
  .sd-m-sm-5 {
    margin: 5rem !important;
  }
  .sd-mt-sm-5,
  .sd-my-sm-5 {
    margin-top: 5rem !important;
  }
  .sd-mr-sm-5,
  .sd-mx-sm-5 {
    margin-right: 5rem !important;
  }
  .sd-mb-sm-5,
  .sd-my-sm-5 {
    margin-bottom: 5rem !important;
  }
  .sd-ml-sm-5,
  .sd-mx-sm-5 {
    margin-left: 5rem !important;
  }
  .sd-m-sm-5_5 {
    margin: 5.5rem !important;
  }
  .sd-mt-sm-5_5,
  .sd-my-sm-5_5 {
    margin-top: 5.5rem !important;
  }
  .sd-mr-sm-5_5,
  .sd-mx-sm-5_5 {
    margin-right: 5.5rem !important;
  }
  .sd-mb-sm-5_5,
  .sd-my-sm-5_5 {
    margin-bottom: 5.5rem !important;
  }
  .sd-ml-sm-5_5,
  .sd-mx-sm-5_5 {
    margin-left: 5.5rem !important;
  }
  .sd-m-sm-6 {
    margin: 6rem !important;
  }
  .sd-mt-sm-6,
  .sd-my-sm-6 {
    margin-top: 6rem !important;
  }
  .sd-mr-sm-6,
  .sd-mx-sm-6 {
    margin-right: 6rem !important;
  }
  .sd-mb-sm-6,
  .sd-my-sm-6 {
    margin-bottom: 6rem !important;
  }
  .sd-ml-sm-6,
  .sd-mx-sm-6 {
    margin-left: 6rem !important;
  }
  .sd-m-sm-7 {
    margin: 7rem !important;
  }
  .sd-mt-sm-7,
  .sd-my-sm-7 {
    margin-top: 7rem !important;
  }
  .sd-mr-sm-7,
  .sd-mx-sm-7 {
    margin-right: 7rem !important;
  }
  .sd-mb-sm-7,
  .sd-my-sm-7 {
    margin-bottom: 7rem !important;
  }
  .sd-ml-sm-7,
  .sd-mx-sm-7 {
    margin-left: 7rem !important;
  }
  .sd-m-sm-9 {
    margin: 9rem !important;
  }
  .sd-mt-sm-9,
  .sd-my-sm-9 {
    margin-top: 9rem !important;
  }
  .sd-mr-sm-9,
  .sd-mx-sm-9 {
    margin-right: 9rem !important;
  }
  .sd-mb-sm-9,
  .sd-my-sm-9 {
    margin-bottom: 9rem !important;
  }
  .sd-ml-sm-9,
  .sd-mx-sm-9 {
    margin-left: 9rem !important;
  }
  .sd-p-sm-0 {
    padding: 0 !important;
  }
  .sd-pt-sm-0,
  .sd-py-sm-0 {
    padding-top: 0 !important;
  }
  .sd-pr-sm-0,
  .sd-px-sm-0 {
    padding-right: 0 !important;
  }
  .sd-pb-sm-0,
  .sd-py-sm-0 {
    padding-bottom: 0 !important;
  }
  .sd-pl-sm-0,
  .sd-px-sm-0 {
    padding-left: 0 !important;
  }
  .sd-p-sm-0_25 {
    padding: 0.25rem !important;
  }
  .sd-pt-sm-0_25,
  .sd-py-sm-0_25 {
    padding-top: 0.25rem !important;
  }
  .sd-pr-sm-0_25,
  .sd-px-sm-0_25 {
    padding-right: 0.25rem !important;
  }
  .sd-pb-sm-0_25,
  .sd-py-sm-0_25 {
    padding-bottom: 0.25rem !important;
  }
  .sd-pl-sm-0_25,
  .sd-px-sm-0_25 {
    padding-left: 0.25rem !important;
  }
  .sd-p-sm-0_5 {
    padding: 0.5rem !important;
  }
  .sd-pt-sm-0_5,
  .sd-py-sm-0_5 {
    padding-top: 0.5rem !important;
  }
  .sd-pr-sm-0_5,
  .sd-px-sm-0_5 {
    padding-right: 0.5rem !important;
  }
  .sd-pb-sm-0_5,
  .sd-py-sm-0_5 {
    padding-bottom: 0.5rem !important;
  }
  .sd-pl-sm-0_5,
  .sd-px-sm-0_5 {
    padding-left: 0.5rem !important;
  }
  .sd-p-sm-0_75 {
    padding: 0.75rem !important;
  }
  .sd-pt-sm-0_75,
  .sd-py-sm-0_75 {
    padding-top: 0.75rem !important;
  }
  .sd-pr-sm-0_75,
  .sd-px-sm-0_75 {
    padding-right: 0.75rem !important;
  }
  .sd-pb-sm-0_75,
  .sd-py-sm-0_75 {
    padding-bottom: 0.75rem !important;
  }
  .sd-pl-sm-0_75,
  .sd-px-sm-0_75 {
    padding-left: 0.75rem !important;
  }
  .sd-p-sm-1 {
    padding: 1rem !important;
  }
  .sd-pt-sm-1,
  .sd-py-sm-1 {
    padding-top: 1rem !important;
  }
  .sd-pr-sm-1,
  .sd-px-sm-1 {
    padding-right: 1rem !important;
  }
  .sd-pb-sm-1,
  .sd-py-sm-1 {
    padding-bottom: 1rem !important;
  }
  .sd-pl-sm-1,
  .sd-px-sm-1 {
    padding-left: 1rem !important;
  }
  .sd-p-sm-1_125 {
    padding: 1.125rem !important;
  }
  .sd-pt-sm-1_125,
  .sd-py-sm-1_125 {
    padding-top: 1.125rem !important;
  }
  .sd-pr-sm-1_125,
  .sd-px-sm-1_125 {
    padding-right: 1.125rem !important;
  }
  .sd-pb-sm-1_125,
  .sd-py-sm-1_125 {
    padding-bottom: 1.125rem !important;
  }
  .sd-pl-sm-1_125,
  .sd-px-sm-1_125 {
    padding-left: 1.125rem !important;
  }
  .sd-p-sm-1_25 {
    padding: 1.25rem !important;
  }
  .sd-pt-sm-1_25,
  .sd-py-sm-1_25 {
    padding-top: 1.25rem !important;
  }
  .sd-pr-sm-1_25,
  .sd-px-sm-1_25 {
    padding-right: 1.25rem !important;
  }
  .sd-pb-sm-1_25,
  .sd-py-sm-1_25 {
    padding-bottom: 1.25rem !important;
  }
  .sd-pl-sm-1_25,
  .sd-px-sm-1_25 {
    padding-left: 1.25rem !important;
  }
  .sd-p-sm-1_5 {
    padding: 1.5rem !important;
  }
  .sd-pt-sm-1_5,
  .sd-py-sm-1_5 {
    padding-top: 1.5rem !important;
  }
  .sd-pr-sm-1_5,
  .sd-px-sm-1_5 {
    padding-right: 1.5rem !important;
  }
  .sd-pb-sm-1_5,
  .sd-py-sm-1_5 {
    padding-bottom: 1.5rem !important;
  }
  .sd-pl-sm-1_5,
  .sd-px-sm-1_5 {
    padding-left: 1.5rem !important;
  }
  .sd-p-sm-1_75 {
    padding: 1.75rem !important;
  }
  .sd-pt-sm-1_75,
  .sd-py-sm-1_75 {
    padding-top: 1.75rem !important;
  }
  .sd-pr-sm-1_75,
  .sd-px-sm-1_75 {
    padding-right: 1.75rem !important;
  }
  .sd-pb-sm-1_75,
  .sd-py-sm-1_75 {
    padding-bottom: 1.75rem !important;
  }
  .sd-pl-sm-1_75,
  .sd-px-sm-1_75 {
    padding-left: 1.75rem !important;
  }
  .sd-p-sm-2 {
    padding: 2rem !important;
  }
  .sd-pt-sm-2,
  .sd-py-sm-2 {
    padding-top: 2rem !important;
  }
  .sd-pr-sm-2,
  .sd-px-sm-2 {
    padding-right: 2rem !important;
  }
  .sd-pb-sm-2,
  .sd-py-sm-2 {
    padding-bottom: 2rem !important;
  }
  .sd-pl-sm-2,
  .sd-px-sm-2 {
    padding-left: 2rem !important;
  }
  .sd-p-sm-2_5 {
    padding: 2.5rem !important;
  }
  .sd-pt-sm-2_5,
  .sd-py-sm-2_5 {
    padding-top: 2.5rem !important;
  }
  .sd-pr-sm-2_5,
  .sd-px-sm-2_5 {
    padding-right: 2.5rem !important;
  }
  .sd-pb-sm-2_5,
  .sd-py-sm-2_5 {
    padding-bottom: 2.5rem !important;
  }
  .sd-pl-sm-2_5,
  .sd-px-sm-2_5 {
    padding-left: 2.5rem !important;
  }
  .sd-p-sm-2_75 {
    padding: 2.75rem !important;
  }
  .sd-pt-sm-2_75,
  .sd-py-sm-2_75 {
    padding-top: 2.75rem !important;
  }
  .sd-pr-sm-2_75,
  .sd-px-sm-2_75 {
    padding-right: 2.75rem !important;
  }
  .sd-pb-sm-2_75,
  .sd-py-sm-2_75 {
    padding-bottom: 2.75rem !important;
  }
  .sd-pl-sm-2_75,
  .sd-px-sm-2_75 {
    padding-left: 2.75rem !important;
  }
  .sd-p-sm-3 {
    padding: 3rem !important;
  }
  .sd-pt-sm-3,
  .sd-py-sm-3 {
    padding-top: 3rem !important;
  }
  .sd-pr-sm-3,
  .sd-px-sm-3 {
    padding-right: 3rem !important;
  }
  .sd-pb-sm-3,
  .sd-py-sm-3 {
    padding-bottom: 3rem !important;
  }
  .sd-pl-sm-3,
  .sd-px-sm-3 {
    padding-left: 3rem !important;
  }
  .sd-p-sm-3_25 {
    padding: 3.25rem !important;
  }
  .sd-pt-sm-3_25,
  .sd-py-sm-3_25 {
    padding-top: 3.25rem !important;
  }
  .sd-pr-sm-3_25,
  .sd-px-sm-3_25 {
    padding-right: 3.25rem !important;
  }
  .sd-pb-sm-3_25,
  .sd-py-sm-3_25 {
    padding-bottom: 3.25rem !important;
  }
  .sd-pl-sm-3_25,
  .sd-px-sm-3_25 {
    padding-left: 3.25rem !important;
  }
  .sd-p-sm-3_5 {
    padding: 3.5rem !important;
  }
  .sd-pt-sm-3_5,
  .sd-py-sm-3_5 {
    padding-top: 3.5rem !important;
  }
  .sd-pr-sm-3_5,
  .sd-px-sm-3_5 {
    padding-right: 3.5rem !important;
  }
  .sd-pb-sm-3_5,
  .sd-py-sm-3_5 {
    padding-bottom: 3.5rem !important;
  }
  .sd-pl-sm-3_5,
  .sd-px-sm-3_5 {
    padding-left: 3.5rem !important;
  }
  .sd-p-sm-4 {
    padding: 4rem !important;
  }
  .sd-pt-sm-4,
  .sd-py-sm-4 {
    padding-top: 4rem !important;
  }
  .sd-pr-sm-4,
  .sd-px-sm-4 {
    padding-right: 4rem !important;
  }
  .sd-pb-sm-4,
  .sd-py-sm-4 {
    padding-bottom: 4rem !important;
  }
  .sd-pl-sm-4,
  .sd-px-sm-4 {
    padding-left: 4rem !important;
  }
  .sd-p-sm-5 {
    padding: 5rem !important;
  }
  .sd-pt-sm-5,
  .sd-py-sm-5 {
    padding-top: 5rem !important;
  }
  .sd-pr-sm-5,
  .sd-px-sm-5 {
    padding-right: 5rem !important;
  }
  .sd-pb-sm-5,
  .sd-py-sm-5 {
    padding-bottom: 5rem !important;
  }
  .sd-pl-sm-5,
  .sd-px-sm-5 {
    padding-left: 5rem !important;
  }
  .sd-p-sm-5_5 {
    padding: 5.5rem !important;
  }
  .sd-pt-sm-5_5,
  .sd-py-sm-5_5 {
    padding-top: 5.5rem !important;
  }
  .sd-pr-sm-5_5,
  .sd-px-sm-5_5 {
    padding-right: 5.5rem !important;
  }
  .sd-pb-sm-5_5,
  .sd-py-sm-5_5 {
    padding-bottom: 5.5rem !important;
  }
  .sd-pl-sm-5_5,
  .sd-px-sm-5_5 {
    padding-left: 5.5rem !important;
  }
  .sd-p-sm-6 {
    padding: 6rem !important;
  }
  .sd-pt-sm-6,
  .sd-py-sm-6 {
    padding-top: 6rem !important;
  }
  .sd-pr-sm-6,
  .sd-px-sm-6 {
    padding-right: 6rem !important;
  }
  .sd-pb-sm-6,
  .sd-py-sm-6 {
    padding-bottom: 6rem !important;
  }
  .sd-pl-sm-6,
  .sd-px-sm-6 {
    padding-left: 6rem !important;
  }
  .sd-p-sm-7 {
    padding: 7rem !important;
  }
  .sd-pt-sm-7,
  .sd-py-sm-7 {
    padding-top: 7rem !important;
  }
  .sd-pr-sm-7,
  .sd-px-sm-7 {
    padding-right: 7rem !important;
  }
  .sd-pb-sm-7,
  .sd-py-sm-7 {
    padding-bottom: 7rem !important;
  }
  .sd-pl-sm-7,
  .sd-px-sm-7 {
    padding-left: 7rem !important;
  }
  .sd-p-sm-9 {
    padding: 9rem !important;
  }
  .sd-pt-sm-9,
  .sd-py-sm-9 {
    padding-top: 9rem !important;
  }
  .sd-pr-sm-9,
  .sd-px-sm-9 {
    padding-right: 9rem !important;
  }
  .sd-pb-sm-9,
  .sd-py-sm-9 {
    padding-bottom: 9rem !important;
  }
  .sd-pl-sm-9,
  .sd-px-sm-9 {
    padding-left: 9rem !important;
  }
  .sd-m-sm-auto {
    margin: auto !important;
  }
  .sd-mt-sm-auto,
  .sd-my-sm-auto {
    margin-top: auto !important;
  }
  .sd-mr-sm-auto,
  .sd-mx-sm-auto {
    margin-right: auto !important;
  }
  .sd-mb-sm-auto,
  .sd-my-sm-auto {
    margin-bottom: auto !important;
  }
  .sd-ml-sm-auto,
  .sd-mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .sd-m-md-0 {
    margin: 0 !important;
  }
  .sd-mt-md-0,
  .sd-my-md-0 {
    margin-top: 0 !important;
  }
  .sd-mr-md-0,
  .sd-mx-md-0 {
    margin-right: 0 !important;
  }
  .sd-mb-md-0,
  .sd-my-md-0 {
    margin-bottom: 0 !important;
  }
  .sd-ml-md-0,
  .sd-mx-md-0 {
    margin-left: 0 !important;
  }
  .sd-m-md-0_25 {
    margin: 0.25rem !important;
  }
  .sd-mt-md-0_25,
  .sd-my-md-0_25 {
    margin-top: 0.25rem !important;
  }
  .sd-mr-md-0_25,
  .sd-mx-md-0_25 {
    margin-right: 0.25rem !important;
  }
  .sd-mb-md-0_25,
  .sd-my-md-0_25 {
    margin-bottom: 0.25rem !important;
  }
  .sd-ml-md-0_25,
  .sd-mx-md-0_25 {
    margin-left: 0.25rem !important;
  }
  .sd-m-md-0_5 {
    margin: 0.5rem !important;
  }
  .sd-mt-md-0_5,
  .sd-my-md-0_5 {
    margin-top: 0.5rem !important;
  }
  .sd-mr-md-0_5,
  .sd-mx-md-0_5 {
    margin-right: 0.5rem !important;
  }
  .sd-mb-md-0_5,
  .sd-my-md-0_5 {
    margin-bottom: 0.5rem !important;
  }
  .sd-ml-md-0_5,
  .sd-mx-md-0_5 {
    margin-left: 0.5rem !important;
  }
  .sd-m-md-0_75 {
    margin: 0.75rem !important;
  }
  .sd-mt-md-0_75,
  .sd-my-md-0_75 {
    margin-top: 0.75rem !important;
  }
  .sd-mr-md-0_75,
  .sd-mx-md-0_75 {
    margin-right: 0.75rem !important;
  }
  .sd-mb-md-0_75,
  .sd-my-md-0_75 {
    margin-bottom: 0.75rem !important;
  }
  .sd-ml-md-0_75,
  .sd-mx-md-0_75 {
    margin-left: 0.75rem !important;
  }
  .sd-m-md-1 {
    margin: 1rem !important;
  }
  .sd-mt-md-1,
  .sd-my-md-1 {
    margin-top: 1rem !important;
  }
  .sd-mr-md-1,
  .sd-mx-md-1 {
    margin-right: 1rem !important;
  }
  .sd-mb-md-1,
  .sd-my-md-1 {
    margin-bottom: 1rem !important;
  }
  .sd-ml-md-1,
  .sd-mx-md-1 {
    margin-left: 1rem !important;
  }
  .sd-m-md-1_125 {
    margin: 1.125rem !important;
  }
  .sd-mt-md-1_125,
  .sd-my-md-1_125 {
    margin-top: 1.125rem !important;
  }
  .sd-mr-md-1_125,
  .sd-mx-md-1_125 {
    margin-right: 1.125rem !important;
  }
  .sd-mb-md-1_125,
  .sd-my-md-1_125 {
    margin-bottom: 1.125rem !important;
  }
  .sd-ml-md-1_125,
  .sd-mx-md-1_125 {
    margin-left: 1.125rem !important;
  }
  .sd-m-md-1_25 {
    margin: 1.25rem !important;
  }
  .sd-mt-md-1_25,
  .sd-my-md-1_25 {
    margin-top: 1.25rem !important;
  }
  .sd-mr-md-1_25,
  .sd-mx-md-1_25 {
    margin-right: 1.25rem !important;
  }
  .sd-mb-md-1_25,
  .sd-my-md-1_25 {
    margin-bottom: 1.25rem !important;
  }
  .sd-ml-md-1_25,
  .sd-mx-md-1_25 {
    margin-left: 1.25rem !important;
  }
  .sd-m-md-1_5 {
    margin: 1.5rem !important;
  }
  .sd-mt-md-1_5,
  .sd-my-md-1_5 {
    margin-top: 1.5rem !important;
  }
  .sd-mr-md-1_5,
  .sd-mx-md-1_5 {
    margin-right: 1.5rem !important;
  }
  .sd-mb-md-1_5,
  .sd-my-md-1_5 {
    margin-bottom: 1.5rem !important;
  }
  .sd-ml-md-1_5,
  .sd-mx-md-1_5 {
    margin-left: 1.5rem !important;
  }
  .sd-m-md-1_75 {
    margin: 1.75rem !important;
  }
  .sd-mt-md-1_75,
  .sd-my-md-1_75 {
    margin-top: 1.75rem !important;
  }
  .sd-mr-md-1_75,
  .sd-mx-md-1_75 {
    margin-right: 1.75rem !important;
  }
  .sd-mb-md-1_75,
  .sd-my-md-1_75 {
    margin-bottom: 1.75rem !important;
  }
  .sd-ml-md-1_75,
  .sd-mx-md-1_75 {
    margin-left: 1.75rem !important;
  }
  .sd-m-md-2 {
    margin: 2rem !important;
  }
  .sd-mt-md-2,
  .sd-my-md-2 {
    margin-top: 2rem !important;
  }
  .sd-mr-md-2,
  .sd-mx-md-2 {
    margin-right: 2rem !important;
  }
  .sd-mb-md-2,
  .sd-my-md-2 {
    margin-bottom: 2rem !important;
  }
  .sd-ml-md-2,
  .sd-mx-md-2 {
    margin-left: 2rem !important;
  }
  .sd-m-md-2_5 {
    margin: 2.5rem !important;
  }
  .sd-mt-md-2_5,
  .sd-my-md-2_5 {
    margin-top: 2.5rem !important;
  }
  .sd-mr-md-2_5,
  .sd-mx-md-2_5 {
    margin-right: 2.5rem !important;
  }
  .sd-mb-md-2_5,
  .sd-my-md-2_5 {
    margin-bottom: 2.5rem !important;
  }
  .sd-ml-md-2_5,
  .sd-mx-md-2_5 {
    margin-left: 2.5rem !important;
  }
  .sd-m-md-2_75 {
    margin: 2.75rem !important;
  }
  .sd-mt-md-2_75,
  .sd-my-md-2_75 {
    margin-top: 2.75rem !important;
  }
  .sd-mr-md-2_75,
  .sd-mx-md-2_75 {
    margin-right: 2.75rem !important;
  }
  .sd-mb-md-2_75,
  .sd-my-md-2_75 {
    margin-bottom: 2.75rem !important;
  }
  .sd-ml-md-2_75,
  .sd-mx-md-2_75 {
    margin-left: 2.75rem !important;
  }
  .sd-m-md-3 {
    margin: 3rem !important;
  }
  .sd-mt-md-3,
  .sd-my-md-3 {
    margin-top: 3rem !important;
  }
  .sd-mr-md-3,
  .sd-mx-md-3 {
    margin-right: 3rem !important;
  }
  .sd-mb-md-3,
  .sd-my-md-3 {
    margin-bottom: 3rem !important;
  }
  .sd-ml-md-3,
  .sd-mx-md-3 {
    margin-left: 3rem !important;
  }
  .sd-m-md-3_25 {
    margin: 3.25rem !important;
  }
  .sd-mt-md-3_25,
  .sd-my-md-3_25 {
    margin-top: 3.25rem !important;
  }
  .sd-mr-md-3_25,
  .sd-mx-md-3_25 {
    margin-right: 3.25rem !important;
  }
  .sd-mb-md-3_25,
  .sd-my-md-3_25 {
    margin-bottom: 3.25rem !important;
  }
  .sd-ml-md-3_25,
  .sd-mx-md-3_25 {
    margin-left: 3.25rem !important;
  }
  .sd-m-md-3_5 {
    margin: 3.5rem !important;
  }
  .sd-mt-md-3_5,
  .sd-my-md-3_5 {
    margin-top: 3.5rem !important;
  }
  .sd-mr-md-3_5,
  .sd-mx-md-3_5 {
    margin-right: 3.5rem !important;
  }
  .sd-mb-md-3_5,
  .sd-my-md-3_5 {
    margin-bottom: 3.5rem !important;
  }
  .sd-ml-md-3_5,
  .sd-mx-md-3_5 {
    margin-left: 3.5rem !important;
  }
  .sd-m-md-4 {
    margin: 4rem !important;
  }
  .sd-mt-md-4,
  .sd-my-md-4 {
    margin-top: 4rem !important;
  }
  .sd-mr-md-4,
  .sd-mx-md-4 {
    margin-right: 4rem !important;
  }
  .sd-mb-md-4,
  .sd-my-md-4 {
    margin-bottom: 4rem !important;
  }
  .sd-ml-md-4,
  .sd-mx-md-4 {
    margin-left: 4rem !important;
  }
  .sd-m-md-5 {
    margin: 5rem !important;
  }
  .sd-mt-md-5,
  .sd-my-md-5 {
    margin-top: 5rem !important;
  }
  .sd-mr-md-5,
  .sd-mx-md-5 {
    margin-right: 5rem !important;
  }
  .sd-mb-md-5,
  .sd-my-md-5 {
    margin-bottom: 5rem !important;
  }
  .sd-ml-md-5,
  .sd-mx-md-5 {
    margin-left: 5rem !important;
  }
  .sd-m-md-5_5 {
    margin: 5.5rem !important;
  }
  .sd-mt-md-5_5,
  .sd-my-md-5_5 {
    margin-top: 5.5rem !important;
  }
  .sd-mr-md-5_5,
  .sd-mx-md-5_5 {
    margin-right: 5.5rem !important;
  }
  .sd-mb-md-5_5,
  .sd-my-md-5_5 {
    margin-bottom: 5.5rem !important;
  }
  .sd-ml-md-5_5,
  .sd-mx-md-5_5 {
    margin-left: 5.5rem !important;
  }
  .sd-m-md-6 {
    margin: 6rem !important;
  }
  .sd-mt-md-6,
  .sd-my-md-6 {
    margin-top: 6rem !important;
  }
  .sd-mr-md-6,
  .sd-mx-md-6 {
    margin-right: 6rem !important;
  }
  .sd-mb-md-6,
  .sd-my-md-6 {
    margin-bottom: 6rem !important;
  }
  .sd-ml-md-6,
  .sd-mx-md-6 {
    margin-left: 6rem !important;
  }
  .sd-m-md-7 {
    margin: 7rem !important;
  }
  .sd-mt-md-7,
  .sd-my-md-7 {
    margin-top: 7rem !important;
  }
  .sd-mr-md-7,
  .sd-mx-md-7 {
    margin-right: 7rem !important;
  }
  .sd-mb-md-7,
  .sd-my-md-7 {
    margin-bottom: 7rem !important;
  }
  .sd-ml-md-7,
  .sd-mx-md-7 {
    margin-left: 7rem !important;
  }
  .sd-m-md-9 {
    margin: 9rem !important;
  }
  .sd-mt-md-9,
  .sd-my-md-9 {
    margin-top: 9rem !important;
  }
  .sd-mr-md-9,
  .sd-mx-md-9 {
    margin-right: 9rem !important;
  }
  .sd-mb-md-9,
  .sd-my-md-9 {
    margin-bottom: 9rem !important;
  }
  .sd-ml-md-9,
  .sd-mx-md-9 {
    margin-left: 9rem !important;
  }
  .sd-p-md-0 {
    padding: 0 !important;
  }
  .sd-pt-md-0,
  .sd-py-md-0 {
    padding-top: 0 !important;
  }
  .sd-pr-md-0,
  .sd-px-md-0 {
    padding-right: 0 !important;
  }
  .sd-pb-md-0,
  .sd-py-md-0 {
    padding-bottom: 0 !important;
  }
  .sd-pl-md-0,
  .sd-px-md-0 {
    padding-left: 0 !important;
  }
  .sd-p-md-0_25 {
    padding: 0.25rem !important;
  }
  .sd-pt-md-0_25,
  .sd-py-md-0_25 {
    padding-top: 0.25rem !important;
  }
  .sd-pr-md-0_25,
  .sd-px-md-0_25 {
    padding-right: 0.25rem !important;
  }
  .sd-pb-md-0_25,
  .sd-py-md-0_25 {
    padding-bottom: 0.25rem !important;
  }
  .sd-pl-md-0_25,
  .sd-px-md-0_25 {
    padding-left: 0.25rem !important;
  }
  .sd-p-md-0_5 {
    padding: 0.5rem !important;
  }
  .sd-pt-md-0_5,
  .sd-py-md-0_5 {
    padding-top: 0.5rem !important;
  }
  .sd-pr-md-0_5,
  .sd-px-md-0_5 {
    padding-right: 0.5rem !important;
  }
  .sd-pb-md-0_5,
  .sd-py-md-0_5 {
    padding-bottom: 0.5rem !important;
  }
  .sd-pl-md-0_5,
  .sd-px-md-0_5 {
    padding-left: 0.5rem !important;
  }
  .sd-p-md-0_75 {
    padding: 0.75rem !important;
  }
  .sd-pt-md-0_75,
  .sd-py-md-0_75 {
    padding-top: 0.75rem !important;
  }
  .sd-pr-md-0_75,
  .sd-px-md-0_75 {
    padding-right: 0.75rem !important;
  }
  .sd-pb-md-0_75,
  .sd-py-md-0_75 {
    padding-bottom: 0.75rem !important;
  }
  .sd-pl-md-0_75,
  .sd-px-md-0_75 {
    padding-left: 0.75rem !important;
  }
  .sd-p-md-1 {
    padding: 1rem !important;
  }
  .sd-pt-md-1,
  .sd-py-md-1 {
    padding-top: 1rem !important;
  }
  .sd-pr-md-1,
  .sd-px-md-1 {
    padding-right: 1rem !important;
  }
  .sd-pb-md-1,
  .sd-py-md-1 {
    padding-bottom: 1rem !important;
  }
  .sd-pl-md-1,
  .sd-px-md-1 {
    padding-left: 1rem !important;
  }
  .sd-p-md-1_125 {
    padding: 1.125rem !important;
  }
  .sd-pt-md-1_125,
  .sd-py-md-1_125 {
    padding-top: 1.125rem !important;
  }
  .sd-pr-md-1_125,
  .sd-px-md-1_125 {
    padding-right: 1.125rem !important;
  }
  .sd-pb-md-1_125,
  .sd-py-md-1_125 {
    padding-bottom: 1.125rem !important;
  }
  .sd-pl-md-1_125,
  .sd-px-md-1_125 {
    padding-left: 1.125rem !important;
  }
  .sd-p-md-1_25 {
    padding: 1.25rem !important;
  }
  .sd-pt-md-1_25,
  .sd-py-md-1_25 {
    padding-top: 1.25rem !important;
  }
  .sd-pr-md-1_25,
  .sd-px-md-1_25 {
    padding-right: 1.25rem !important;
  }
  .sd-pb-md-1_25,
  .sd-py-md-1_25 {
    padding-bottom: 1.25rem !important;
  }
  .sd-pl-md-1_25,
  .sd-px-md-1_25 {
    padding-left: 1.25rem !important;
  }
  .sd-p-md-1_5 {
    padding: 1.5rem !important;
  }
  .sd-pt-md-1_5,
  .sd-py-md-1_5 {
    padding-top: 1.5rem !important;
  }
  .sd-pr-md-1_5,
  .sd-px-md-1_5 {
    padding-right: 1.5rem !important;
  }
  .sd-pb-md-1_5,
  .sd-py-md-1_5 {
    padding-bottom: 1.5rem !important;
  }
  .sd-pl-md-1_5,
  .sd-px-md-1_5 {
    padding-left: 1.5rem !important;
  }
  .sd-p-md-1_75 {
    padding: 1.75rem !important;
  }
  .sd-pt-md-1_75,
  .sd-py-md-1_75 {
    padding-top: 1.75rem !important;
  }
  .sd-pr-md-1_75,
  .sd-px-md-1_75 {
    padding-right: 1.75rem !important;
  }
  .sd-pb-md-1_75,
  .sd-py-md-1_75 {
    padding-bottom: 1.75rem !important;
  }
  .sd-pl-md-1_75,
  .sd-px-md-1_75 {
    padding-left: 1.75rem !important;
  }
  .sd-p-md-2 {
    padding: 2rem !important;
  }
  .sd-pt-md-2,
  .sd-py-md-2 {
    padding-top: 2rem !important;
  }
  .sd-pr-md-2,
  .sd-px-md-2 {
    padding-right: 2rem !important;
  }
  .sd-pb-md-2,
  .sd-py-md-2 {
    padding-bottom: 2rem !important;
  }
  .sd-pl-md-2,
  .sd-px-md-2 {
    padding-left: 2rem !important;
  }
  .sd-p-md-2_5 {
    padding: 2.5rem !important;
  }
  .sd-pt-md-2_5,
  .sd-py-md-2_5 {
    padding-top: 2.5rem !important;
  }
  .sd-pr-md-2_5,
  .sd-px-md-2_5 {
    padding-right: 2.5rem !important;
  }
  .sd-pb-md-2_5,
  .sd-py-md-2_5 {
    padding-bottom: 2.5rem !important;
  }
  .sd-pl-md-2_5,
  .sd-px-md-2_5 {
    padding-left: 2.5rem !important;
  }
  .sd-p-md-2_75 {
    padding: 2.75rem !important;
  }
  .sd-pt-md-2_75,
  .sd-py-md-2_75 {
    padding-top: 2.75rem !important;
  }
  .sd-pr-md-2_75,
  .sd-px-md-2_75 {
    padding-right: 2.75rem !important;
  }
  .sd-pb-md-2_75,
  .sd-py-md-2_75 {
    padding-bottom: 2.75rem !important;
  }
  .sd-pl-md-2_75,
  .sd-px-md-2_75 {
    padding-left: 2.75rem !important;
  }
  .sd-p-md-3 {
    padding: 3rem !important;
  }
  .sd-pt-md-3,
  .sd-py-md-3 {
    padding-top: 3rem !important;
  }
  .sd-pr-md-3,
  .sd-px-md-3 {
    padding-right: 3rem !important;
  }
  .sd-pb-md-3,
  .sd-py-md-3 {
    padding-bottom: 3rem !important;
  }
  .sd-pl-md-3,
  .sd-px-md-3 {
    padding-left: 3rem !important;
  }
  .sd-p-md-3_25 {
    padding: 3.25rem !important;
  }
  .sd-pt-md-3_25,
  .sd-py-md-3_25 {
    padding-top: 3.25rem !important;
  }
  .sd-pr-md-3_25,
  .sd-px-md-3_25 {
    padding-right: 3.25rem !important;
  }
  .sd-pb-md-3_25,
  .sd-py-md-3_25 {
    padding-bottom: 3.25rem !important;
  }
  .sd-pl-md-3_25,
  .sd-px-md-3_25 {
    padding-left: 3.25rem !important;
  }
  .sd-p-md-3_5 {
    padding: 3.5rem !important;
  }
  .sd-pt-md-3_5,
  .sd-py-md-3_5 {
    padding-top: 3.5rem !important;
  }
  .sd-pr-md-3_5,
  .sd-px-md-3_5 {
    padding-right: 3.5rem !important;
  }
  .sd-pb-md-3_5,
  .sd-py-md-3_5 {
    padding-bottom: 3.5rem !important;
  }
  .sd-pl-md-3_5,
  .sd-px-md-3_5 {
    padding-left: 3.5rem !important;
  }
  .sd-p-md-4 {
    padding: 4rem !important;
  }
  .sd-pt-md-4,
  .sd-py-md-4 {
    padding-top: 4rem !important;
  }
  .sd-pr-md-4,
  .sd-px-md-4 {
    padding-right: 4rem !important;
  }
  .sd-pb-md-4,
  .sd-py-md-4 {
    padding-bottom: 4rem !important;
  }
  .sd-pl-md-4,
  .sd-px-md-4 {
    padding-left: 4rem !important;
  }
  .sd-p-md-5 {
    padding: 5rem !important;
  }
  .sd-pt-md-5,
  .sd-py-md-5 {
    padding-top: 5rem !important;
  }
  .sd-pr-md-5,
  .sd-px-md-5 {
    padding-right: 5rem !important;
  }
  .sd-pb-md-5,
  .sd-py-md-5 {
    padding-bottom: 5rem !important;
  }
  .sd-pl-md-5,
  .sd-px-md-5 {
    padding-left: 5rem !important;
  }
  .sd-p-md-5_5 {
    padding: 5.5rem !important;
  }
  .sd-pt-md-5_5,
  .sd-py-md-5_5 {
    padding-top: 5.5rem !important;
  }
  .sd-pr-md-5_5,
  .sd-px-md-5_5 {
    padding-right: 5.5rem !important;
  }
  .sd-pb-md-5_5,
  .sd-py-md-5_5 {
    padding-bottom: 5.5rem !important;
  }
  .sd-pl-md-5_5,
  .sd-px-md-5_5 {
    padding-left: 5.5rem !important;
  }
  .sd-p-md-6 {
    padding: 6rem !important;
  }
  .sd-pt-md-6,
  .sd-py-md-6 {
    padding-top: 6rem !important;
  }
  .sd-pr-md-6,
  .sd-px-md-6 {
    padding-right: 6rem !important;
  }
  .sd-pb-md-6,
  .sd-py-md-6 {
    padding-bottom: 6rem !important;
  }
  .sd-pl-md-6,
  .sd-px-md-6 {
    padding-left: 6rem !important;
  }
  .sd-p-md-7 {
    padding: 7rem !important;
  }
  .sd-pt-md-7,
  .sd-py-md-7 {
    padding-top: 7rem !important;
  }
  .sd-pr-md-7,
  .sd-px-md-7 {
    padding-right: 7rem !important;
  }
  .sd-pb-md-7,
  .sd-py-md-7 {
    padding-bottom: 7rem !important;
  }
  .sd-pl-md-7,
  .sd-px-md-7 {
    padding-left: 7rem !important;
  }
  .sd-p-md-9 {
    padding: 9rem !important;
  }
  .sd-pt-md-9,
  .sd-py-md-9 {
    padding-top: 9rem !important;
  }
  .sd-pr-md-9,
  .sd-px-md-9 {
    padding-right: 9rem !important;
  }
  .sd-pb-md-9,
  .sd-py-md-9 {
    padding-bottom: 9rem !important;
  }
  .sd-pl-md-9,
  .sd-px-md-9 {
    padding-left: 9rem !important;
  }
  .sd-m-md-auto {
    margin: auto !important;
  }
  .sd-mt-md-auto,
  .sd-my-md-auto {
    margin-top: auto !important;
  }
  .sd-mr-md-auto,
  .sd-mx-md-auto {
    margin-right: auto !important;
  }
  .sd-mb-md-auto,
  .sd-my-md-auto {
    margin-bottom: auto !important;
  }
  .sd-ml-md-auto,
  .sd-mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .sd-m-lg-0 {
    margin: 0 !important;
  }
  .sd-mt-lg-0,
  .sd-my-lg-0 {
    margin-top: 0 !important;
  }
  .sd-mr-lg-0,
  .sd-mx-lg-0 {
    margin-right: 0 !important;
  }
  .sd-mb-lg-0,
  .sd-my-lg-0 {
    margin-bottom: 0 !important;
  }
  .sd-ml-lg-0,
  .sd-mx-lg-0 {
    margin-left: 0 !important;
  }
  .sd-m-lg-0_25 {
    margin: 0.25rem !important;
  }
  .sd-mt-lg-0_25,
  .sd-my-lg-0_25 {
    margin-top: 0.25rem !important;
  }
  .sd-mr-lg-0_25,
  .sd-mx-lg-0_25 {
    margin-right: 0.25rem !important;
  }
  .sd-mb-lg-0_25,
  .sd-my-lg-0_25 {
    margin-bottom: 0.25rem !important;
  }
  .sd-ml-lg-0_25,
  .sd-mx-lg-0_25 {
    margin-left: 0.25rem !important;
  }
  .sd-m-lg-0_5 {
    margin: 0.5rem !important;
  }
  .sd-mt-lg-0_5,
  .sd-my-lg-0_5 {
    margin-top: 0.5rem !important;
  }
  .sd-mr-lg-0_5,
  .sd-mx-lg-0_5 {
    margin-right: 0.5rem !important;
  }
  .sd-mb-lg-0_5,
  .sd-my-lg-0_5 {
    margin-bottom: 0.5rem !important;
  }
  .sd-ml-lg-0_5,
  .sd-mx-lg-0_5 {
    margin-left: 0.5rem !important;
  }
  .sd-m-lg-0_75 {
    margin: 0.75rem !important;
  }
  .sd-mt-lg-0_75,
  .sd-my-lg-0_75 {
    margin-top: 0.75rem !important;
  }
  .sd-mr-lg-0_75,
  .sd-mx-lg-0_75 {
    margin-right: 0.75rem !important;
  }
  .sd-mb-lg-0_75,
  .sd-my-lg-0_75 {
    margin-bottom: 0.75rem !important;
  }
  .sd-ml-lg-0_75,
  .sd-mx-lg-0_75 {
    margin-left: 0.75rem !important;
  }
  .sd-m-lg-1 {
    margin: 1rem !important;
  }
  .sd-mt-lg-1,
  .sd-my-lg-1 {
    margin-top: 1rem !important;
  }
  .sd-mr-lg-1,
  .sd-mx-lg-1 {
    margin-right: 1rem !important;
  }
  .sd-mb-lg-1,
  .sd-my-lg-1 {
    margin-bottom: 1rem !important;
  }
  .sd-ml-lg-1,
  .sd-mx-lg-1 {
    margin-left: 1rem !important;
  }
  .sd-m-lg-1_125 {
    margin: 1.125rem !important;
  }
  .sd-mt-lg-1_125,
  .sd-my-lg-1_125 {
    margin-top: 1.125rem !important;
  }
  .sd-mr-lg-1_125,
  .sd-mx-lg-1_125 {
    margin-right: 1.125rem !important;
  }
  .sd-mb-lg-1_125,
  .sd-my-lg-1_125 {
    margin-bottom: 1.125rem !important;
  }
  .sd-ml-lg-1_125,
  .sd-mx-lg-1_125 {
    margin-left: 1.125rem !important;
  }
  .sd-m-lg-1_25 {
    margin: 1.25rem !important;
  }
  .sd-mt-lg-1_25,
  .sd-my-lg-1_25 {
    margin-top: 1.25rem !important;
  }
  .sd-mr-lg-1_25,
  .sd-mx-lg-1_25 {
    margin-right: 1.25rem !important;
  }
  .sd-mb-lg-1_25,
  .sd-my-lg-1_25 {
    margin-bottom: 1.25rem !important;
  }
  .sd-ml-lg-1_25,
  .sd-mx-lg-1_25 {
    margin-left: 1.25rem !important;
  }
  .sd-m-lg-1_5 {
    margin: 1.5rem !important;
  }
  .sd-mt-lg-1_5,
  .sd-my-lg-1_5 {
    margin-top: 1.5rem !important;
  }
  .sd-mr-lg-1_5,
  .sd-mx-lg-1_5 {
    margin-right: 1.5rem !important;
  }
  .sd-mb-lg-1_5,
  .sd-my-lg-1_5 {
    margin-bottom: 1.5rem !important;
  }
  .sd-ml-lg-1_5,
  .sd-mx-lg-1_5 {
    margin-left: 1.5rem !important;
  }
  .sd-m-lg-1_75 {
    margin: 1.75rem !important;
  }
  .sd-mt-lg-1_75,
  .sd-my-lg-1_75 {
    margin-top: 1.75rem !important;
  }
  .sd-mr-lg-1_75,
  .sd-mx-lg-1_75 {
    margin-right: 1.75rem !important;
  }
  .sd-mb-lg-1_75,
  .sd-my-lg-1_75 {
    margin-bottom: 1.75rem !important;
  }
  .sd-ml-lg-1_75,
  .sd-mx-lg-1_75 {
    margin-left: 1.75rem !important;
  }
  .sd-m-lg-2 {
    margin: 2rem !important;
  }
  .sd-mt-lg-2,
  .sd-my-lg-2 {
    margin-top: 2rem !important;
  }
  .sd-mr-lg-2,
  .sd-mx-lg-2 {
    margin-right: 2rem !important;
  }
  .sd-mb-lg-2,
  .sd-my-lg-2 {
    margin-bottom: 2rem !important;
  }
  .sd-ml-lg-2,
  .sd-mx-lg-2 {
    margin-left: 2rem !important;
  }
  .sd-m-lg-2_5 {
    margin: 2.5rem !important;
  }
  .sd-mt-lg-2_5,
  .sd-my-lg-2_5 {
    margin-top: 2.5rem !important;
  }
  .sd-mr-lg-2_5,
  .sd-mx-lg-2_5 {
    margin-right: 2.5rem !important;
  }
  .sd-mb-lg-2_5,
  .sd-my-lg-2_5 {
    margin-bottom: 2.5rem !important;
  }
  .sd-ml-lg-2_5,
  .sd-mx-lg-2_5 {
    margin-left: 2.5rem !important;
  }
  .sd-m-lg-2_75 {
    margin: 2.75rem !important;
  }
  .sd-mt-lg-2_75,
  .sd-my-lg-2_75 {
    margin-top: 2.75rem !important;
  }
  .sd-mr-lg-2_75,
  .sd-mx-lg-2_75 {
    margin-right: 2.75rem !important;
  }
  .sd-mb-lg-2_75,
  .sd-my-lg-2_75 {
    margin-bottom: 2.75rem !important;
  }
  .sd-ml-lg-2_75,
  .sd-mx-lg-2_75 {
    margin-left: 2.75rem !important;
  }
  .sd-m-lg-3 {
    margin: 3rem !important;
  }
  .sd-mt-lg-3,
  .sd-my-lg-3 {
    margin-top: 3rem !important;
  }
  .sd-mr-lg-3,
  .sd-mx-lg-3 {
    margin-right: 3rem !important;
  }
  .sd-mb-lg-3,
  .sd-my-lg-3 {
    margin-bottom: 3rem !important;
  }
  .sd-ml-lg-3,
  .sd-mx-lg-3 {
    margin-left: 3rem !important;
  }
  .sd-m-lg-3_25 {
    margin: 3.25rem !important;
  }
  .sd-mt-lg-3_25,
  .sd-my-lg-3_25 {
    margin-top: 3.25rem !important;
  }
  .sd-mr-lg-3_25,
  .sd-mx-lg-3_25 {
    margin-right: 3.25rem !important;
  }
  .sd-mb-lg-3_25,
  .sd-my-lg-3_25 {
    margin-bottom: 3.25rem !important;
  }
  .sd-ml-lg-3_25,
  .sd-mx-lg-3_25 {
    margin-left: 3.25rem !important;
  }
  .sd-m-lg-3_5 {
    margin: 3.5rem !important;
  }
  .sd-mt-lg-3_5,
  .sd-my-lg-3_5 {
    margin-top: 3.5rem !important;
  }
  .sd-mr-lg-3_5,
  .sd-mx-lg-3_5 {
    margin-right: 3.5rem !important;
  }
  .sd-mb-lg-3_5,
  .sd-my-lg-3_5 {
    margin-bottom: 3.5rem !important;
  }
  .sd-ml-lg-3_5,
  .sd-mx-lg-3_5 {
    margin-left: 3.5rem !important;
  }
  .sd-m-lg-4 {
    margin: 4rem !important;
  }
  .sd-mt-lg-4,
  .sd-my-lg-4 {
    margin-top: 4rem !important;
  }
  .sd-mr-lg-4,
  .sd-mx-lg-4 {
    margin-right: 4rem !important;
  }
  .sd-mb-lg-4,
  .sd-my-lg-4 {
    margin-bottom: 4rem !important;
  }
  .sd-ml-lg-4,
  .sd-mx-lg-4 {
    margin-left: 4rem !important;
  }
  .sd-m-lg-5 {
    margin: 5rem !important;
  }
  .sd-mt-lg-5,
  .sd-my-lg-5 {
    margin-top: 5rem !important;
  }
  .sd-mr-lg-5,
  .sd-mx-lg-5 {
    margin-right: 5rem !important;
  }
  .sd-mb-lg-5,
  .sd-my-lg-5 {
    margin-bottom: 5rem !important;
  }
  .sd-ml-lg-5,
  .sd-mx-lg-5 {
    margin-left: 5rem !important;
  }
  .sd-m-lg-5_5 {
    margin: 5.5rem !important;
  }
  .sd-mt-lg-5_5,
  .sd-my-lg-5_5 {
    margin-top: 5.5rem !important;
  }
  .sd-mr-lg-5_5,
  .sd-mx-lg-5_5 {
    margin-right: 5.5rem !important;
  }
  .sd-mb-lg-5_5,
  .sd-my-lg-5_5 {
    margin-bottom: 5.5rem !important;
  }
  .sd-ml-lg-5_5,
  .sd-mx-lg-5_5 {
    margin-left: 5.5rem !important;
  }
  .sd-m-lg-6 {
    margin: 6rem !important;
  }
  .sd-mt-lg-6,
  .sd-my-lg-6 {
    margin-top: 6rem !important;
  }
  .sd-mr-lg-6,
  .sd-mx-lg-6 {
    margin-right: 6rem !important;
  }
  .sd-mb-lg-6,
  .sd-my-lg-6 {
    margin-bottom: 6rem !important;
  }
  .sd-ml-lg-6,
  .sd-mx-lg-6 {
    margin-left: 6rem !important;
  }
  .sd-m-lg-7 {
    margin: 7rem !important;
  }
  .sd-mt-lg-7,
  .sd-my-lg-7 {
    margin-top: 7rem !important;
  }
  .sd-mr-lg-7,
  .sd-mx-lg-7 {
    margin-right: 7rem !important;
  }
  .sd-mb-lg-7,
  .sd-my-lg-7 {
    margin-bottom: 7rem !important;
  }
  .sd-ml-lg-7,
  .sd-mx-lg-7 {
    margin-left: 7rem !important;
  }
  .sd-m-lg-9 {
    margin: 9rem !important;
  }
  .sd-mt-lg-9,
  .sd-my-lg-9 {
    margin-top: 9rem !important;
  }
  .sd-mr-lg-9,
  .sd-mx-lg-9 {
    margin-right: 9rem !important;
  }
  .sd-mb-lg-9,
  .sd-my-lg-9 {
    margin-bottom: 9rem !important;
  }
  .sd-ml-lg-9,
  .sd-mx-lg-9 {
    margin-left: 9rem !important;
  }
  .sd-p-lg-0 {
    padding: 0 !important;
  }
  .sd-pt-lg-0,
  .sd-py-lg-0 {
    padding-top: 0 !important;
  }
  .sd-pr-lg-0,
  .sd-px-lg-0 {
    padding-right: 0 !important;
  }
  .sd-pb-lg-0,
  .sd-py-lg-0 {
    padding-bottom: 0 !important;
  }
  .sd-pl-lg-0,
  .sd-px-lg-0 {
    padding-left: 0 !important;
  }
  .sd-p-lg-0_25 {
    padding: 0.25rem !important;
  }
  .sd-pt-lg-0_25,
  .sd-py-lg-0_25 {
    padding-top: 0.25rem !important;
  }
  .sd-pr-lg-0_25,
  .sd-px-lg-0_25 {
    padding-right: 0.25rem !important;
  }
  .sd-pb-lg-0_25,
  .sd-py-lg-0_25 {
    padding-bottom: 0.25rem !important;
  }
  .sd-pl-lg-0_25,
  .sd-px-lg-0_25 {
    padding-left: 0.25rem !important;
  }
  .sd-p-lg-0_5 {
    padding: 0.5rem !important;
  }
  .sd-pt-lg-0_5,
  .sd-py-lg-0_5 {
    padding-top: 0.5rem !important;
  }
  .sd-pr-lg-0_5,
  .sd-px-lg-0_5 {
    padding-right: 0.5rem !important;
  }
  .sd-pb-lg-0_5,
  .sd-py-lg-0_5 {
    padding-bottom: 0.5rem !important;
  }
  .sd-pl-lg-0_5,
  .sd-px-lg-0_5 {
    padding-left: 0.5rem !important;
  }
  .sd-p-lg-0_75 {
    padding: 0.75rem !important;
  }
  .sd-pt-lg-0_75,
  .sd-py-lg-0_75 {
    padding-top: 0.75rem !important;
  }
  .sd-pr-lg-0_75,
  .sd-px-lg-0_75 {
    padding-right: 0.75rem !important;
  }
  .sd-pb-lg-0_75,
  .sd-py-lg-0_75 {
    padding-bottom: 0.75rem !important;
  }
  .sd-pl-lg-0_75,
  .sd-px-lg-0_75 {
    padding-left: 0.75rem !important;
  }
  .sd-p-lg-1 {
    padding: 1rem !important;
  }
  .sd-pt-lg-1,
  .sd-py-lg-1 {
    padding-top: 1rem !important;
  }
  .sd-pr-lg-1,
  .sd-px-lg-1 {
    padding-right: 1rem !important;
  }
  .sd-pb-lg-1,
  .sd-py-lg-1 {
    padding-bottom: 1rem !important;
  }
  .sd-pl-lg-1,
  .sd-px-lg-1 {
    padding-left: 1rem !important;
  }
  .sd-p-lg-1_125 {
    padding: 1.125rem !important;
  }
  .sd-pt-lg-1_125,
  .sd-py-lg-1_125 {
    padding-top: 1.125rem !important;
  }
  .sd-pr-lg-1_125,
  .sd-px-lg-1_125 {
    padding-right: 1.125rem !important;
  }
  .sd-pb-lg-1_125,
  .sd-py-lg-1_125 {
    padding-bottom: 1.125rem !important;
  }
  .sd-pl-lg-1_125,
  .sd-px-lg-1_125 {
    padding-left: 1.125rem !important;
  }
  .sd-p-lg-1_25 {
    padding: 1.25rem !important;
  }
  .sd-pt-lg-1_25,
  .sd-py-lg-1_25 {
    padding-top: 1.25rem !important;
  }
  .sd-pr-lg-1_25,
  .sd-px-lg-1_25 {
    padding-right: 1.25rem !important;
  }
  .sd-pb-lg-1_25,
  .sd-py-lg-1_25 {
    padding-bottom: 1.25rem !important;
  }
  .sd-pl-lg-1_25,
  .sd-px-lg-1_25 {
    padding-left: 1.25rem !important;
  }
  .sd-p-lg-1_5 {
    padding: 1.5rem !important;
  }
  .sd-pt-lg-1_5,
  .sd-py-lg-1_5 {
    padding-top: 1.5rem !important;
  }
  .sd-pr-lg-1_5,
  .sd-px-lg-1_5 {
    padding-right: 1.5rem !important;
  }
  .sd-pb-lg-1_5,
  .sd-py-lg-1_5 {
    padding-bottom: 1.5rem !important;
  }
  .sd-pl-lg-1_5,
  .sd-px-lg-1_5 {
    padding-left: 1.5rem !important;
  }
  .sd-p-lg-1_75 {
    padding: 1.75rem !important;
  }
  .sd-pt-lg-1_75,
  .sd-py-lg-1_75 {
    padding-top: 1.75rem !important;
  }
  .sd-pr-lg-1_75,
  .sd-px-lg-1_75 {
    padding-right: 1.75rem !important;
  }
  .sd-pb-lg-1_75,
  .sd-py-lg-1_75 {
    padding-bottom: 1.75rem !important;
  }
  .sd-pl-lg-1_75,
  .sd-px-lg-1_75 {
    padding-left: 1.75rem !important;
  }
  .sd-p-lg-2 {
    padding: 2rem !important;
  }
  .sd-pt-lg-2,
  .sd-py-lg-2 {
    padding-top: 2rem !important;
  }
  .sd-pr-lg-2,
  .sd-px-lg-2 {
    padding-right: 2rem !important;
  }
  .sd-pb-lg-2,
  .sd-py-lg-2 {
    padding-bottom: 2rem !important;
  }
  .sd-pl-lg-2,
  .sd-px-lg-2 {
    padding-left: 2rem !important;
  }
  .sd-p-lg-2_5 {
    padding: 2.5rem !important;
  }
  .sd-pt-lg-2_5,
  .sd-py-lg-2_5 {
    padding-top: 2.5rem !important;
  }
  .sd-pr-lg-2_5,
  .sd-px-lg-2_5 {
    padding-right: 2.5rem !important;
  }
  .sd-pb-lg-2_5,
  .sd-py-lg-2_5 {
    padding-bottom: 2.5rem !important;
  }
  .sd-pl-lg-2_5,
  .sd-px-lg-2_5 {
    padding-left: 2.5rem !important;
  }
  .sd-p-lg-2_75 {
    padding: 2.75rem !important;
  }
  .sd-pt-lg-2_75,
  .sd-py-lg-2_75 {
    padding-top: 2.75rem !important;
  }
  .sd-pr-lg-2_75,
  .sd-px-lg-2_75 {
    padding-right: 2.75rem !important;
  }
  .sd-pb-lg-2_75,
  .sd-py-lg-2_75 {
    padding-bottom: 2.75rem !important;
  }
  .sd-pl-lg-2_75,
  .sd-px-lg-2_75 {
    padding-left: 2.75rem !important;
  }
  .sd-p-lg-3 {
    padding: 3rem !important;
  }
  .sd-pt-lg-3,
  .sd-py-lg-3 {
    padding-top: 3rem !important;
  }
  .sd-pr-lg-3,
  .sd-px-lg-3 {
    padding-right: 3rem !important;
  }
  .sd-pb-lg-3,
  .sd-py-lg-3 {
    padding-bottom: 3rem !important;
  }
  .sd-pl-lg-3,
  .sd-px-lg-3 {
    padding-left: 3rem !important;
  }
  .sd-p-lg-3_25 {
    padding: 3.25rem !important;
  }
  .sd-pt-lg-3_25,
  .sd-py-lg-3_25 {
    padding-top: 3.25rem !important;
  }
  .sd-pr-lg-3_25,
  .sd-px-lg-3_25 {
    padding-right: 3.25rem !important;
  }
  .sd-pb-lg-3_25,
  .sd-py-lg-3_25 {
    padding-bottom: 3.25rem !important;
  }
  .sd-pl-lg-3_25,
  .sd-px-lg-3_25 {
    padding-left: 3.25rem !important;
  }
  .sd-p-lg-3_5 {
    padding: 3.5rem !important;
  }
  .sd-pt-lg-3_5,
  .sd-py-lg-3_5 {
    padding-top: 3.5rem !important;
  }
  .sd-pr-lg-3_5,
  .sd-px-lg-3_5 {
    padding-right: 3.5rem !important;
  }
  .sd-pb-lg-3_5,
  .sd-py-lg-3_5 {
    padding-bottom: 3.5rem !important;
  }
  .sd-pl-lg-3_5,
  .sd-px-lg-3_5 {
    padding-left: 3.5rem !important;
  }
  .sd-p-lg-4 {
    padding: 4rem !important;
  }
  .sd-pt-lg-4,
  .sd-py-lg-4 {
    padding-top: 4rem !important;
  }
  .sd-pr-lg-4,
  .sd-px-lg-4 {
    padding-right: 4rem !important;
  }
  .sd-pb-lg-4,
  .sd-py-lg-4 {
    padding-bottom: 4rem !important;
  }
  .sd-pl-lg-4,
  .sd-px-lg-4 {
    padding-left: 4rem !important;
  }
  .sd-p-lg-5 {
    padding: 5rem !important;
  }
  .sd-pt-lg-5,
  .sd-py-lg-5 {
    padding-top: 5rem !important;
  }
  .sd-pr-lg-5,
  .sd-px-lg-5 {
    padding-right: 5rem !important;
  }
  .sd-pb-lg-5,
  .sd-py-lg-5 {
    padding-bottom: 5rem !important;
  }
  .sd-pl-lg-5,
  .sd-px-lg-5 {
    padding-left: 5rem !important;
  }
  .sd-p-lg-5_5 {
    padding: 5.5rem !important;
  }
  .sd-pt-lg-5_5,
  .sd-py-lg-5_5 {
    padding-top: 5.5rem !important;
  }
  .sd-pr-lg-5_5,
  .sd-px-lg-5_5 {
    padding-right: 5.5rem !important;
  }
  .sd-pb-lg-5_5,
  .sd-py-lg-5_5 {
    padding-bottom: 5.5rem !important;
  }
  .sd-pl-lg-5_5,
  .sd-px-lg-5_5 {
    padding-left: 5.5rem !important;
  }
  .sd-p-lg-6 {
    padding: 6rem !important;
  }
  .sd-pt-lg-6,
  .sd-py-lg-6 {
    padding-top: 6rem !important;
  }
  .sd-pr-lg-6,
  .sd-px-lg-6 {
    padding-right: 6rem !important;
  }
  .sd-pb-lg-6,
  .sd-py-lg-6 {
    padding-bottom: 6rem !important;
  }
  .sd-pl-lg-6,
  .sd-px-lg-6 {
    padding-left: 6rem !important;
  }
  .sd-p-lg-7 {
    padding: 7rem !important;
  }
  .sd-pt-lg-7,
  .sd-py-lg-7 {
    padding-top: 7rem !important;
  }
  .sd-pr-lg-7,
  .sd-px-lg-7 {
    padding-right: 7rem !important;
  }
  .sd-pb-lg-7,
  .sd-py-lg-7 {
    padding-bottom: 7rem !important;
  }
  .sd-pl-lg-7,
  .sd-px-lg-7 {
    padding-left: 7rem !important;
  }
  .sd-p-lg-9 {
    padding: 9rem !important;
  }
  .sd-pt-lg-9,
  .sd-py-lg-9 {
    padding-top: 9rem !important;
  }
  .sd-pr-lg-9,
  .sd-px-lg-9 {
    padding-right: 9rem !important;
  }
  .sd-pb-lg-9,
  .sd-py-lg-9 {
    padding-bottom: 9rem !important;
  }
  .sd-pl-lg-9,
  .sd-px-lg-9 {
    padding-left: 9rem !important;
  }
  .sd-m-lg-auto {
    margin: auto !important;
  }
  .sd-mt-lg-auto,
  .sd-my-lg-auto {
    margin-top: auto !important;
  }
  .sd-mr-lg-auto,
  .sd-mx-lg-auto {
    margin-right: auto !important;
  }
  .sd-mb-lg-auto,
  .sd-my-lg-auto {
    margin-bottom: auto !important;
  }
  .sd-ml-lg-auto,
  .sd-mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .sd-m-xl-0 {
    margin: 0 !important;
  }
  .sd-mt-xl-0,
  .sd-my-xl-0 {
    margin-top: 0 !important;
  }
  .sd-mr-xl-0,
  .sd-mx-xl-0 {
    margin-right: 0 !important;
  }
  .sd-mb-xl-0,
  .sd-my-xl-0 {
    margin-bottom: 0 !important;
  }
  .sd-ml-xl-0,
  .sd-mx-xl-0 {
    margin-left: 0 !important;
  }
  .sd-m-xl-0_25 {
    margin: 0.25rem !important;
  }
  .sd-mt-xl-0_25,
  .sd-my-xl-0_25 {
    margin-top: 0.25rem !important;
  }
  .sd-mr-xl-0_25,
  .sd-mx-xl-0_25 {
    margin-right: 0.25rem !important;
  }
  .sd-mb-xl-0_25,
  .sd-my-xl-0_25 {
    margin-bottom: 0.25rem !important;
  }
  .sd-ml-xl-0_25,
  .sd-mx-xl-0_25 {
    margin-left: 0.25rem !important;
  }
  .sd-m-xl-0_5 {
    margin: 0.5rem !important;
  }
  .sd-mt-xl-0_5,
  .sd-my-xl-0_5 {
    margin-top: 0.5rem !important;
  }
  .sd-mr-xl-0_5,
  .sd-mx-xl-0_5 {
    margin-right: 0.5rem !important;
  }
  .sd-mb-xl-0_5,
  .sd-my-xl-0_5 {
    margin-bottom: 0.5rem !important;
  }
  .sd-ml-xl-0_5,
  .sd-mx-xl-0_5 {
    margin-left: 0.5rem !important;
  }
  .sd-m-xl-0_75 {
    margin: 0.75rem !important;
  }
  .sd-mt-xl-0_75,
  .sd-my-xl-0_75 {
    margin-top: 0.75rem !important;
  }
  .sd-mr-xl-0_75,
  .sd-mx-xl-0_75 {
    margin-right: 0.75rem !important;
  }
  .sd-mb-xl-0_75,
  .sd-my-xl-0_75 {
    margin-bottom: 0.75rem !important;
  }
  .sd-ml-xl-0_75,
  .sd-mx-xl-0_75 {
    margin-left: 0.75rem !important;
  }
  .sd-m-xl-1 {
    margin: 1rem !important;
  }
  .sd-mt-xl-1,
  .sd-my-xl-1 {
    margin-top: 1rem !important;
  }
  .sd-mr-xl-1,
  .sd-mx-xl-1 {
    margin-right: 1rem !important;
  }
  .sd-mb-xl-1,
  .sd-my-xl-1 {
    margin-bottom: 1rem !important;
  }
  .sd-ml-xl-1,
  .sd-mx-xl-1 {
    margin-left: 1rem !important;
  }
  .sd-m-xl-1_125 {
    margin: 1.125rem !important;
  }
  .sd-mt-xl-1_125,
  .sd-my-xl-1_125 {
    margin-top: 1.125rem !important;
  }
  .sd-mr-xl-1_125,
  .sd-mx-xl-1_125 {
    margin-right: 1.125rem !important;
  }
  .sd-mb-xl-1_125,
  .sd-my-xl-1_125 {
    margin-bottom: 1.125rem !important;
  }
  .sd-ml-xl-1_125,
  .sd-mx-xl-1_125 {
    margin-left: 1.125rem !important;
  }
  .sd-m-xl-1_25 {
    margin: 1.25rem !important;
  }
  .sd-mt-xl-1_25,
  .sd-my-xl-1_25 {
    margin-top: 1.25rem !important;
  }
  .sd-mr-xl-1_25,
  .sd-mx-xl-1_25 {
    margin-right: 1.25rem !important;
  }
  .sd-mb-xl-1_25,
  .sd-my-xl-1_25 {
    margin-bottom: 1.25rem !important;
  }
  .sd-ml-xl-1_25,
  .sd-mx-xl-1_25 {
    margin-left: 1.25rem !important;
  }
  .sd-m-xl-1_5 {
    margin: 1.5rem !important;
  }
  .sd-mt-xl-1_5,
  .sd-my-xl-1_5 {
    margin-top: 1.5rem !important;
  }
  .sd-mr-xl-1_5,
  .sd-mx-xl-1_5 {
    margin-right: 1.5rem !important;
  }
  .sd-mb-xl-1_5,
  .sd-my-xl-1_5 {
    margin-bottom: 1.5rem !important;
  }
  .sd-ml-xl-1_5,
  .sd-mx-xl-1_5 {
    margin-left: 1.5rem !important;
  }
  .sd-m-xl-1_75 {
    margin: 1.75rem !important;
  }
  .sd-mt-xl-1_75,
  .sd-my-xl-1_75 {
    margin-top: 1.75rem !important;
  }
  .sd-mr-xl-1_75,
  .sd-mx-xl-1_75 {
    margin-right: 1.75rem !important;
  }
  .sd-mb-xl-1_75,
  .sd-my-xl-1_75 {
    margin-bottom: 1.75rem !important;
  }
  .sd-ml-xl-1_75,
  .sd-mx-xl-1_75 {
    margin-left: 1.75rem !important;
  }
  .sd-m-xl-2 {
    margin: 2rem !important;
  }
  .sd-mt-xl-2,
  .sd-my-xl-2 {
    margin-top: 2rem !important;
  }
  .sd-mr-xl-2,
  .sd-mx-xl-2 {
    margin-right: 2rem !important;
  }
  .sd-mb-xl-2,
  .sd-my-xl-2 {
    margin-bottom: 2rem !important;
  }
  .sd-ml-xl-2,
  .sd-mx-xl-2 {
    margin-left: 2rem !important;
  }
  .sd-m-xl-2_5 {
    margin: 2.5rem !important;
  }
  .sd-mt-xl-2_5,
  .sd-my-xl-2_5 {
    margin-top: 2.5rem !important;
  }
  .sd-mr-xl-2_5,
  .sd-mx-xl-2_5 {
    margin-right: 2.5rem !important;
  }
  .sd-mb-xl-2_5,
  .sd-my-xl-2_5 {
    margin-bottom: 2.5rem !important;
  }
  .sd-ml-xl-2_5,
  .sd-mx-xl-2_5 {
    margin-left: 2.5rem !important;
  }
  .sd-m-xl-2_75 {
    margin: 2.75rem !important;
  }
  .sd-mt-xl-2_75,
  .sd-my-xl-2_75 {
    margin-top: 2.75rem !important;
  }
  .sd-mr-xl-2_75,
  .sd-mx-xl-2_75 {
    margin-right: 2.75rem !important;
  }
  .sd-mb-xl-2_75,
  .sd-my-xl-2_75 {
    margin-bottom: 2.75rem !important;
  }
  .sd-ml-xl-2_75,
  .sd-mx-xl-2_75 {
    margin-left: 2.75rem !important;
  }
  .sd-m-xl-3 {
    margin: 3rem !important;
  }
  .sd-mt-xl-3,
  .sd-my-xl-3 {
    margin-top: 3rem !important;
  }
  .sd-mr-xl-3,
  .sd-mx-xl-3 {
    margin-right: 3rem !important;
  }
  .sd-mb-xl-3,
  .sd-my-xl-3 {
    margin-bottom: 3rem !important;
  }
  .sd-ml-xl-3,
  .sd-mx-xl-3 {
    margin-left: 3rem !important;
  }
  .sd-m-xl-3_25 {
    margin: 3.25rem !important;
  }
  .sd-mt-xl-3_25,
  .sd-my-xl-3_25 {
    margin-top: 3.25rem !important;
  }
  .sd-mr-xl-3_25,
  .sd-mx-xl-3_25 {
    margin-right: 3.25rem !important;
  }
  .sd-mb-xl-3_25,
  .sd-my-xl-3_25 {
    margin-bottom: 3.25rem !important;
  }
  .sd-ml-xl-3_25,
  .sd-mx-xl-3_25 {
    margin-left: 3.25rem !important;
  }
  .sd-m-xl-3_5 {
    margin: 3.5rem !important;
  }
  .sd-mt-xl-3_5,
  .sd-my-xl-3_5 {
    margin-top: 3.5rem !important;
  }
  .sd-mr-xl-3_5,
  .sd-mx-xl-3_5 {
    margin-right: 3.5rem !important;
  }
  .sd-mb-xl-3_5,
  .sd-my-xl-3_5 {
    margin-bottom: 3.5rem !important;
  }
  .sd-ml-xl-3_5,
  .sd-mx-xl-3_5 {
    margin-left: 3.5rem !important;
  }
  .sd-m-xl-4 {
    margin: 4rem !important;
  }
  .sd-mt-xl-4,
  .sd-my-xl-4 {
    margin-top: 4rem !important;
  }
  .sd-mr-xl-4,
  .sd-mx-xl-4 {
    margin-right: 4rem !important;
  }
  .sd-mb-xl-4,
  .sd-my-xl-4 {
    margin-bottom: 4rem !important;
  }
  .sd-ml-xl-4,
  .sd-mx-xl-4 {
    margin-left: 4rem !important;
  }
  .sd-m-xl-5 {
    margin: 5rem !important;
  }
  .sd-mt-xl-5,
  .sd-my-xl-5 {
    margin-top: 5rem !important;
  }
  .sd-mr-xl-5,
  .sd-mx-xl-5 {
    margin-right: 5rem !important;
  }
  .sd-mb-xl-5,
  .sd-my-xl-5 {
    margin-bottom: 5rem !important;
  }
  .sd-ml-xl-5,
  .sd-mx-xl-5 {
    margin-left: 5rem !important;
  }
  .sd-m-xl-5_5 {
    margin: 5.5rem !important;
  }
  .sd-mt-xl-5_5,
  .sd-my-xl-5_5 {
    margin-top: 5.5rem !important;
  }
  .sd-mr-xl-5_5,
  .sd-mx-xl-5_5 {
    margin-right: 5.5rem !important;
  }
  .sd-mb-xl-5_5,
  .sd-my-xl-5_5 {
    margin-bottom: 5.5rem !important;
  }
  .sd-ml-xl-5_5,
  .sd-mx-xl-5_5 {
    margin-left: 5.5rem !important;
  }
  .sd-m-xl-6 {
    margin: 6rem !important;
  }
  .sd-mt-xl-6,
  .sd-my-xl-6 {
    margin-top: 6rem !important;
  }
  .sd-mr-xl-6,
  .sd-mx-xl-6 {
    margin-right: 6rem !important;
  }
  .sd-mb-xl-6,
  .sd-my-xl-6 {
    margin-bottom: 6rem !important;
  }
  .sd-ml-xl-6,
  .sd-mx-xl-6 {
    margin-left: 6rem !important;
  }
  .sd-m-xl-7 {
    margin: 7rem !important;
  }
  .sd-mt-xl-7,
  .sd-my-xl-7 {
    margin-top: 7rem !important;
  }
  .sd-mr-xl-7,
  .sd-mx-xl-7 {
    margin-right: 7rem !important;
  }
  .sd-mb-xl-7,
  .sd-my-xl-7 {
    margin-bottom: 7rem !important;
  }
  .sd-ml-xl-7,
  .sd-mx-xl-7 {
    margin-left: 7rem !important;
  }
  .sd-m-xl-9 {
    margin: 9rem !important;
  }
  .sd-mt-xl-9,
  .sd-my-xl-9 {
    margin-top: 9rem !important;
  }
  .sd-mr-xl-9,
  .sd-mx-xl-9 {
    margin-right: 9rem !important;
  }
  .sd-mb-xl-9,
  .sd-my-xl-9 {
    margin-bottom: 9rem !important;
  }
  .sd-ml-xl-9,
  .sd-mx-xl-9 {
    margin-left: 9rem !important;
  }
  .sd-p-xl-0 {
    padding: 0 !important;
  }
  .sd-pt-xl-0,
  .sd-py-xl-0 {
    padding-top: 0 !important;
  }
  .sd-pr-xl-0,
  .sd-px-xl-0 {
    padding-right: 0 !important;
  }
  .sd-pb-xl-0,
  .sd-py-xl-0 {
    padding-bottom: 0 !important;
  }
  .sd-pl-xl-0,
  .sd-px-xl-0 {
    padding-left: 0 !important;
  }
  .sd-p-xl-0_25 {
    padding: 0.25rem !important;
  }
  .sd-pt-xl-0_25,
  .sd-py-xl-0_25 {
    padding-top: 0.25rem !important;
  }
  .sd-pr-xl-0_25,
  .sd-px-xl-0_25 {
    padding-right: 0.25rem !important;
  }
  .sd-pb-xl-0_25,
  .sd-py-xl-0_25 {
    padding-bottom: 0.25rem !important;
  }
  .sd-pl-xl-0_25,
  .sd-px-xl-0_25 {
    padding-left: 0.25rem !important;
  }
  .sd-p-xl-0_5 {
    padding: 0.5rem !important;
  }
  .sd-pt-xl-0_5,
  .sd-py-xl-0_5 {
    padding-top: 0.5rem !important;
  }
  .sd-pr-xl-0_5,
  .sd-px-xl-0_5 {
    padding-right: 0.5rem !important;
  }
  .sd-pb-xl-0_5,
  .sd-py-xl-0_5 {
    padding-bottom: 0.5rem !important;
  }
  .sd-pl-xl-0_5,
  .sd-px-xl-0_5 {
    padding-left: 0.5rem !important;
  }
  .sd-p-xl-0_75 {
    padding: 0.75rem !important;
  }
  .sd-pt-xl-0_75,
  .sd-py-xl-0_75 {
    padding-top: 0.75rem !important;
  }
  .sd-pr-xl-0_75,
  .sd-px-xl-0_75 {
    padding-right: 0.75rem !important;
  }
  .sd-pb-xl-0_75,
  .sd-py-xl-0_75 {
    padding-bottom: 0.75rem !important;
  }
  .sd-pl-xl-0_75,
  .sd-px-xl-0_75 {
    padding-left: 0.75rem !important;
  }
  .sd-p-xl-1 {
    padding: 1rem !important;
  }
  .sd-pt-xl-1,
  .sd-py-xl-1 {
    padding-top: 1rem !important;
  }
  .sd-pr-xl-1,
  .sd-px-xl-1 {
    padding-right: 1rem !important;
  }
  .sd-pb-xl-1,
  .sd-py-xl-1 {
    padding-bottom: 1rem !important;
  }
  .sd-pl-xl-1,
  .sd-px-xl-1 {
    padding-left: 1rem !important;
  }
  .sd-p-xl-1_125 {
    padding: 1.125rem !important;
  }
  .sd-pt-xl-1_125,
  .sd-py-xl-1_125 {
    padding-top: 1.125rem !important;
  }
  .sd-pr-xl-1_125,
  .sd-px-xl-1_125 {
    padding-right: 1.125rem !important;
  }
  .sd-pb-xl-1_125,
  .sd-py-xl-1_125 {
    padding-bottom: 1.125rem !important;
  }
  .sd-pl-xl-1_125,
  .sd-px-xl-1_125 {
    padding-left: 1.125rem !important;
  }
  .sd-p-xl-1_25 {
    padding: 1.25rem !important;
  }
  .sd-pt-xl-1_25,
  .sd-py-xl-1_25 {
    padding-top: 1.25rem !important;
  }
  .sd-pr-xl-1_25,
  .sd-px-xl-1_25 {
    padding-right: 1.25rem !important;
  }
  .sd-pb-xl-1_25,
  .sd-py-xl-1_25 {
    padding-bottom: 1.25rem !important;
  }
  .sd-pl-xl-1_25,
  .sd-px-xl-1_25 {
    padding-left: 1.25rem !important;
  }
  .sd-p-xl-1_5 {
    padding: 1.5rem !important;
  }
  .sd-pt-xl-1_5,
  .sd-py-xl-1_5 {
    padding-top: 1.5rem !important;
  }
  .sd-pr-xl-1_5,
  .sd-px-xl-1_5 {
    padding-right: 1.5rem !important;
  }
  .sd-pb-xl-1_5,
  .sd-py-xl-1_5 {
    padding-bottom: 1.5rem !important;
  }
  .sd-pl-xl-1_5,
  .sd-px-xl-1_5 {
    padding-left: 1.5rem !important;
  }
  .sd-p-xl-1_75 {
    padding: 1.75rem !important;
  }
  .sd-pt-xl-1_75,
  .sd-py-xl-1_75 {
    padding-top: 1.75rem !important;
  }
  .sd-pr-xl-1_75,
  .sd-px-xl-1_75 {
    padding-right: 1.75rem !important;
  }
  .sd-pb-xl-1_75,
  .sd-py-xl-1_75 {
    padding-bottom: 1.75rem !important;
  }
  .sd-pl-xl-1_75,
  .sd-px-xl-1_75 {
    padding-left: 1.75rem !important;
  }
  .sd-p-xl-2 {
    padding: 2rem !important;
  }
  .sd-pt-xl-2,
  .sd-py-xl-2 {
    padding-top: 2rem !important;
  }
  .sd-pr-xl-2,
  .sd-px-xl-2 {
    padding-right: 2rem !important;
  }
  .sd-pb-xl-2,
  .sd-py-xl-2 {
    padding-bottom: 2rem !important;
  }
  .sd-pl-xl-2,
  .sd-px-xl-2 {
    padding-left: 2rem !important;
  }
  .sd-p-xl-2_5 {
    padding: 2.5rem !important;
  }
  .sd-pt-xl-2_5,
  .sd-py-xl-2_5 {
    padding-top: 2.5rem !important;
  }
  .sd-pr-xl-2_5,
  .sd-px-xl-2_5 {
    padding-right: 2.5rem !important;
  }
  .sd-pb-xl-2_5,
  .sd-py-xl-2_5 {
    padding-bottom: 2.5rem !important;
  }
  .sd-pl-xl-2_5,
  .sd-px-xl-2_5 {
    padding-left: 2.5rem !important;
  }
  .sd-p-xl-2_75 {
    padding: 2.75rem !important;
  }
  .sd-pt-xl-2_75,
  .sd-py-xl-2_75 {
    padding-top: 2.75rem !important;
  }
  .sd-pr-xl-2_75,
  .sd-px-xl-2_75 {
    padding-right: 2.75rem !important;
  }
  .sd-pb-xl-2_75,
  .sd-py-xl-2_75 {
    padding-bottom: 2.75rem !important;
  }
  .sd-pl-xl-2_75,
  .sd-px-xl-2_75 {
    padding-left: 2.75rem !important;
  }
  .sd-p-xl-3 {
    padding: 3rem !important;
  }
  .sd-pt-xl-3,
  .sd-py-xl-3 {
    padding-top: 3rem !important;
  }
  .sd-pr-xl-3,
  .sd-px-xl-3 {
    padding-right: 3rem !important;
  }
  .sd-pb-xl-3,
  .sd-py-xl-3 {
    padding-bottom: 3rem !important;
  }
  .sd-pl-xl-3,
  .sd-px-xl-3 {
    padding-left: 3rem !important;
  }
  .sd-p-xl-3_25 {
    padding: 3.25rem !important;
  }
  .sd-pt-xl-3_25,
  .sd-py-xl-3_25 {
    padding-top: 3.25rem !important;
  }
  .sd-pr-xl-3_25,
  .sd-px-xl-3_25 {
    padding-right: 3.25rem !important;
  }
  .sd-pb-xl-3_25,
  .sd-py-xl-3_25 {
    padding-bottom: 3.25rem !important;
  }
  .sd-pl-xl-3_25,
  .sd-px-xl-3_25 {
    padding-left: 3.25rem !important;
  }
  .sd-p-xl-3_5 {
    padding: 3.5rem !important;
  }
  .sd-pt-xl-3_5,
  .sd-py-xl-3_5 {
    padding-top: 3.5rem !important;
  }
  .sd-pr-xl-3_5,
  .sd-px-xl-3_5 {
    padding-right: 3.5rem !important;
  }
  .sd-pb-xl-3_5,
  .sd-py-xl-3_5 {
    padding-bottom: 3.5rem !important;
  }
  .sd-pl-xl-3_5,
  .sd-px-xl-3_5 {
    padding-left: 3.5rem !important;
  }
  .sd-p-xl-4 {
    padding: 4rem !important;
  }
  .sd-pt-xl-4,
  .sd-py-xl-4 {
    padding-top: 4rem !important;
  }
  .sd-pr-xl-4,
  .sd-px-xl-4 {
    padding-right: 4rem !important;
  }
  .sd-pb-xl-4,
  .sd-py-xl-4 {
    padding-bottom: 4rem !important;
  }
  .sd-pl-xl-4,
  .sd-px-xl-4 {
    padding-left: 4rem !important;
  }
  .sd-p-xl-5 {
    padding: 5rem !important;
  }
  .sd-pt-xl-5,
  .sd-py-xl-5 {
    padding-top: 5rem !important;
  }
  .sd-pr-xl-5,
  .sd-px-xl-5 {
    padding-right: 5rem !important;
  }
  .sd-pb-xl-5,
  .sd-py-xl-5 {
    padding-bottom: 5rem !important;
  }
  .sd-pl-xl-5,
  .sd-px-xl-5 {
    padding-left: 5rem !important;
  }
  .sd-p-xl-5_5 {
    padding: 5.5rem !important;
  }
  .sd-pt-xl-5_5,
  .sd-py-xl-5_5 {
    padding-top: 5.5rem !important;
  }
  .sd-pr-xl-5_5,
  .sd-px-xl-5_5 {
    padding-right: 5.5rem !important;
  }
  .sd-pb-xl-5_5,
  .sd-py-xl-5_5 {
    padding-bottom: 5.5rem !important;
  }
  .sd-pl-xl-5_5,
  .sd-px-xl-5_5 {
    padding-left: 5.5rem !important;
  }
  .sd-p-xl-6 {
    padding: 6rem !important;
  }
  .sd-pt-xl-6,
  .sd-py-xl-6 {
    padding-top: 6rem !important;
  }
  .sd-pr-xl-6,
  .sd-px-xl-6 {
    padding-right: 6rem !important;
  }
  .sd-pb-xl-6,
  .sd-py-xl-6 {
    padding-bottom: 6rem !important;
  }
  .sd-pl-xl-6,
  .sd-px-xl-6 {
    padding-left: 6rem !important;
  }
  .sd-p-xl-7 {
    padding: 7rem !important;
  }
  .sd-pt-xl-7,
  .sd-py-xl-7 {
    padding-top: 7rem !important;
  }
  .sd-pr-xl-7,
  .sd-px-xl-7 {
    padding-right: 7rem !important;
  }
  .sd-pb-xl-7,
  .sd-py-xl-7 {
    padding-bottom: 7rem !important;
  }
  .sd-pl-xl-7,
  .sd-px-xl-7 {
    padding-left: 7rem !important;
  }
  .sd-p-xl-9 {
    padding: 9rem !important;
  }
  .sd-pt-xl-9,
  .sd-py-xl-9 {
    padding-top: 9rem !important;
  }
  .sd-pr-xl-9,
  .sd-px-xl-9 {
    padding-right: 9rem !important;
  }
  .sd-pb-xl-9,
  .sd-py-xl-9 {
    padding-bottom: 9rem !important;
  }
  .sd-pl-xl-9,
  .sd-px-xl-9 {
    padding-left: 9rem !important;
  }
  .sd-m-xl-auto {
    margin: auto !important;
  }
  .sd-mt-xl-auto,
  .sd-my-xl-auto {
    margin-top: auto !important;
  }
  .sd-mr-xl-auto,
  .sd-mx-xl-auto {
    margin-right: auto !important;
  }
  .sd-mb-xl-auto,
  .sd-my-xl-auto {
    margin-bottom: auto !important;
  }
  .sd-ml-xl-auto,
  .sd-mx-xl-auto {
    margin-left: auto !important;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.position-relative {
  position: relative;
}

.content-visibility {
  content-visibility: auto;
}

.overflow-wrapper {
  padding: 0 !important;
}

.overflow-wrapper > * {
  padding-right: 15px;
  padding-left: 15px;
}

.overflow-wrapper > * .overflow-auto {
  padding-left: 15px;
}

.overflow-element:last-of-type {
  margin-right: 15px;
}

/**
  Original Bootstrap SASS files
 */
/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #81969f;
  --gray-dark: #364247;
  --primary-light: #d1f2df;
  --primary-lighter: #e8f8ef;
  --primary-dark: #17aa55;
  --accent-light: #a1d4e9;
  --accent-lighter: #d0e9f4;
  --warning-light: #fce5da;
  --danger-light: #f7dddc;
  --primary: #1abd5e;
  --secondary: #fff;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ef7b45;
  --danger: #d9534f;
  --light: #fcfcfc;
  --dark: #364247;
  --accent: #1493c8;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "ProximaNova";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #627881;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #1abd5e;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #17aa55;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #81969f;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1,
.postcode-hero-container .postcode-hero-title, .h2, .banner .banner-body .banner-title, .form-headline, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "SofiaPro";
  font-weight: 700;
  line-height: 1.2;
  color: #364247;
}

h1,
.h1,
.postcode-hero-container .postcode-hero-title {
  font-size: 2rem;
}

h2, .h2, .banner .banner-body .banner-title, .form-headline {
  font-size: 1.5rem;
}

h3, .h3 {
  font-size: 1.5rem;
}

h4, .h4 {
  font-size: 1rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #81969f;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #e6ebec;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #81969f;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.25rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container, .b2c-page-container, .hero-container,
.container-fluid,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1200px) {
  .container, .b2c-page-container, .hero-container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1200px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #627881;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e6ebec;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e6ebec;
}

.table tbody + tbody {
  border-top: 2px solid #e6ebec;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #e6ebec;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #e6ebec;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff;
}

.table-hover tbody tr:hover {
  color: #627881;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #bfedd2;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #88ddab;
}

.table-hover .table-primary:hover {
  background-color: #abe7c4;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #abe7c4;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: white;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: white;
}

.table-hover .table-secondary:hover {
  background-color: #f2f2f2;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #f2f2f2;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fbdacb;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f7ba9e;
}

.table-hover .table-warning:hover {
  background-color: #f9c9b3;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #f9c9b3;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f4cfce;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #eba6a3;
}

.table-hover .table-danger:hover {
  background-color: #efbbb9;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #efbbb9;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fefefe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fdfdfd;
}

.table-hover .table-light:hover {
  background-color: #f1f1f1;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #f1f1f1;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c7cacb;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #969d9f;
}

.table-hover .table-dark:hover {
  background-color: #babdbf;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #babdbf;
}

.table-accent,
.table-accent > th,
.table-accent > td {
  background-color: #bde1f0;
}

.table-accent th,
.table-accent td,
.table-accent thead th,
.table-accent tbody + tbody {
  border-color: #85c7e2;
}

.table-hover .table-accent:hover {
  background-color: #a8d8eb;
}

.table-hover .table-accent:hover > td,
.table-hover .table-accent:hover > th {
  background-color: #a8d8eb;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #364247;
  border-color: #47565d;
}

.table .thead-light th {
  color: #627881;
  background-color: #e6ebec;
  border-color: #e6ebec;
}

.table-dark {
  color: #fff;
  background-color: #364247;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #47565d;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.125rem + 2px);
  padding: 0.5625rem 0.9375rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #364247;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d2dadd;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #364247;
}

.form-control:focus {
  color: #364247;
  background-color: #fff;
  border-color: #d2dadd;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(209, 242, 223, 0.25);
}

.form-control::placeholder {
  color: #a4b3ba;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e6ebec;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #364247;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.5625rem + 1px);
  padding-bottom: calc(0.5625rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.8125rem + 1px);
  padding-bottom: calc(0.8125rem + 1px);
  font-size: 1.125rem;
  line-height: 1.444444;
}

.col-form-label-sm {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  font-size: 0.875rem;
  line-height: 1.428571;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.5625rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #627881;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.428571em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.428571;
  border-radius: 2px;
}

.form-control-lg {
  height: calc(1.444444em + 1.625rem + 2px);
  padding: 0.8125rem 0.75rem;
  font-size: 1.125rem;
  line-height: 1.444444;
  border-radius: 2px;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #81969f;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .valid-tooltip,
.form-row > [class*="col-"] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 1.125rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.28125rem) center;
  background-size: calc(0.75em + 0.5625rem) calc(0.75em + 0.5625rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.125rem);
  background-position: top calc(0.375em + 0.28125rem) right calc(0.375em + 0.28125rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 3.53125rem);
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzM2NDI0NyIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICA8Zz4KICAgICAgICAgICAgICAgICAgICA8Zz4KICAgICAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTggNS44NDFsNi42NzUtNS43MmMuMjEtLjE4LjUyNS0uMTU2LjcwNS4wNTQuMTguMjEuMTU1LjUyNS0uMDU1LjcwNWwtNyA2Yy0uMTg3LjE2LS40NjMuMTYtLjY1IDBsLTctNkMuNDY1LjcuNDQuMzg0LjYyLjE3NWMuMTgtLjIxLjQ5Ni0uMjM0LjcwNS0uMDU1TDggNS44NDF6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzI5IC0zODEpIHRyYW5zbGF0ZSgxNSAzNjIpIHRyYW5zbGF0ZSgzMTQgMTkpIHRyYW5zbGF0ZSgwIDUpIi8+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K") right 0.9375rem center/16px 16px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 2.6875rem/calc(0.75em + 0.5625rem) calc(0.75em + 0.5625rem) no-repeat;
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #d9534f;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(217, 83, 79, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .invalid-tooltip,
.form-row > [class*="col-"] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #d9534f;
  padding-right: calc(1.5em + 1.125rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d9534f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d9534f' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.28125rem) center;
  background-size: calc(0.75em + 0.5625rem) calc(0.75em + 0.5625rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #d9534f;
  box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.125rem);
  background-position: top calc(0.375em + 0.28125rem) right calc(0.375em + 0.28125rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #d9534f;
  padding-right: calc(0.75em + 3.53125rem);
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzM2NDI0NyIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICA8Zz4KICAgICAgICAgICAgICAgICAgICA8Zz4KICAgICAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTggNS44NDFsNi42NzUtNS43MmMuMjEtLjE4LjUyNS0uMTU2LjcwNS4wNTQuMTguMjEuMTU1LjUyNS0uMDU1LjcwNWwtNyA2Yy0uMTg3LjE2LS40NjMuMTYtLjY1IDBsLTctNkMuNDY1LjcuNDQuMzg0LjYyLjE3NWMuMTgtLjIxLjQ5Ni0uMjM0LjcwNS0uMDU1TDggNS44NDF6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzI5IC0zODEpIHRyYW5zbGF0ZSgxNSAzNjIpIHRyYW5zbGF0ZSgzMTQgMTkpIHRyYW5zbGF0ZSgwIDUpIi8+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K") right 0.9375rem center/16px 16px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d9534f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d9534f' stroke='none'/%3e%3c/svg%3e") center right 2.6875rem/calc(0.75em + 0.5625rem) calc(0.75em + 0.5625rem) no-repeat;
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #d9534f;
  box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #d9534f;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #d9534f;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #d9534f;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e27c79;
  background-color: #e27c79;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #d9534f;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #d9534f;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #d9534f;
  box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-family: "SofiaPro";
  font-weight: 400;
  color: #627881;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.625rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #627881;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(209, 242, 223, 0.25);
}

.btn.disabled, .btn[disabled], .btn:disabled {
  opacity: 0.5;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
a.btn[disabled],
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #1abd5e;
  border-color: #1abd5e;
}

.btn-primary:hover {
  color: #fff;
  background-color: #159b4d;
  border-color: #149048;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #159b4d;
  border-color: #149048;
  box-shadow: 0 0 0 0.2rem rgba(60, 199, 118, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #1abd5e;
  border-color: #1abd5e;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #149048;
  border-color: #128542;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 199, 118, 0.5);
}

.btn-secondary {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-secondary:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ef7b45;
  border-color: #ef7b45;
}

.btn-warning:hover {
  color: #fff;
  background-color: #ec6222;
  border-color: #eb5a16;
}

.btn-warning:focus, .btn-warning.focus {
  color: #fff;
  background-color: #ec6222;
  border-color: #eb5a16;
  box-shadow: 0 0 0 0.2rem rgba(208, 110, 65, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ef7b45;
  border-color: #ef7b45;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #eb5a16;
  border-color: #e15513;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(208, 110, 65, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}

.btn-danger:hover {
  color: #fff;
  background-color: #d23430;
  border-color: #c9302c;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #d23430;
  border-color: #c9302c;
  box-shadow: 0 0 0 0.2rem rgba(223, 109, 105, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c9302c;
  border-color: #bf2e29;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 109, 105, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #fcfcfc;
  border-color: #fcfcfc;
}

.btn-light:hover {
  color: #212529;
  background-color: #e9e9e9;
  border-color: #e2e2e2;
}

.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e9e9e9;
  border-color: #e2e2e2;
  box-shadow: 0 0 0 0.2rem rgba(219, 220, 220, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #fcfcfc;
  border-color: #fcfcfc;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #e2e2e2;
  border-color: gainsboro;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(219, 220, 220, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #364247;
  border-color: #364247;
}

.btn-dark:hover {
  color: #fff;
  background-color: #252e31;
  border-color: #20272a;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #252e31;
  border-color: #20272a;
  box-shadow: 0 0 0 0.2rem rgba(84, 94, 99, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #364247;
  border-color: #364247;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #20272a;
  border-color: #1a2023;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(84, 94, 99, 0.5);
}

.btn-accent {
  color: #fff;
  background-color: #1493c8;
  border-color: #1493c8;
}

.btn-accent:hover {
  color: #fff;
  background-color: #1179a5;
  border-color: #0f719a;
}

.btn-accent:focus, .btn-accent.focus {
  color: #fff;
  background-color: #1179a5;
  border-color: #0f719a;
  box-shadow: 0 0 0 0.2rem rgba(55, 163, 208, 0.5);
}

.btn-accent.disabled, .btn-accent:disabled {
  color: #fff;
  background-color: #1493c8;
  border-color: #1493c8;
}

.btn-accent:not(:disabled):not(.disabled):active, .btn-accent:not(:disabled):not(.disabled).active,
.show > .btn-accent.dropdown-toggle {
  color: #fff;
  background-color: #0f719a;
  border-color: #0e688e;
}

.btn-accent:not(:disabled):not(.disabled):active:focus, .btn-accent:not(:disabled):not(.disabled).active:focus,
.show > .btn-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(55, 163, 208, 0.5);
}

.btn-outline-primary {
  color: #1abd5e;
  border-color: #1abd5e;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #1abd5e;
  border-color: #1abd5e;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(26, 189, 94, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #1abd5e;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #1abd5e;
  border-color: #1abd5e;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(26, 189, 94, 0.5);
}

.btn-outline-secondary {
  color: #fff;
  border-color: #fff;
}

.btn-outline-secondary:hover {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ef7b45;
  border-color: #ef7b45;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ef7b45;
  border-color: #ef7b45;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 123, 69, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ef7b45;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ef7b45;
  border-color: #ef7b45;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 123, 69, 0.5);
}

.btn-outline-danger {
  color: #d9534f;
  border-color: #d9534f;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #d9534f;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.5);
}

.btn-outline-light {
  color: #fcfcfc;
  border-color: #fcfcfc;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #fcfcfc;
  border-color: #fcfcfc;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(252, 252, 252, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #fcfcfc;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #fcfcfc;
  border-color: #fcfcfc;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(252, 252, 252, 0.5);
}

.btn-outline-dark {
  color: #364247;
  border-color: #364247;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #364247;
  border-color: #364247;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(54, 66, 71, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #364247;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #364247;
  border-color: #364247;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(54, 66, 71, 0.5);
}

.btn-outline-accent {
  color: #1493c8;
  border-color: #1493c8;
}

.btn-outline-accent:hover {
  color: #fff;
  background-color: #1493c8;
  border-color: #1493c8;
}

.btn-outline-accent:focus, .btn-outline-accent.focus {
  box-shadow: 0 0 0 0.2rem rgba(20, 147, 200, 0.5);
}

.btn-outline-accent.disabled, .btn-outline-accent:disabled {
  color: #1493c8;
  background-color: transparent;
}

.btn-outline-accent:not(:disabled):not(.disabled):active, .btn-outline-accent:not(:disabled):not(.disabled).active,
.show > .btn-outline-accent.dropdown-toggle {
  color: #fff;
  background-color: #1493c8;
  border-color: #1493c8;
}

.btn-outline-accent:not(:disabled):not(.disabled):active:focus, .btn-outline-accent:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(20, 147, 200, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #1abd5e;
  text-decoration: none;
}

.btn-link:hover {
  color: #17aa55;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #81969f;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 1rem 1.5rem;
  font-size: 1.125rem;
  line-height: 1.25rem;
  border-radius: 0.25rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.34rem 0.9375rem;
  font-size: 0.875rem;
  line-height: 1.428571;
  border-radius: 0.25rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #627881;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #eff2f3;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 1rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #eff2f3;
}

.dropdown-item.active, .dropdown-item:active {
  color: #1abd5e;
  text-decoration: none;
  background-color: #d1f2df;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #a4b3ba;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #81969f;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 1rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.703125rem;
  padding-left: 0.703125rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > .form-control:nth-last-child(n + 3),
.input-group.has-validation > .custom-select:nth-last-child(n + 3),
.input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.5625rem 0.9375rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #364247;
  text-align: center;
  white-space: nowrap;
  background-color: #eff2f3;
  border: 1px solid #d2dadd;
  border-radius: 2px;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.444444em + 1.625rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.8125rem 0.75rem;
  font-size: 1.125rem;
  line-height: 1.444444;
  border-radius: 2px;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.428571em + 0.75rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.428571;
  border-radius: 2px;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2.6875rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #1abd5e;
  border-color: #d2dadd;
  background-color: #fff;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #d2dadd;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #d2dadd;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #1abd5e;
  background-color: #fff;
  border-color: #fff;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #81969f;
}

.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e6ebec;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #a4b3ba solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50% / 100% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 2px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='10' viewBox='0 -1 12 12'%3E%3Cpath fill='%2319AD4A' fill-rule='nonzero' d='M2.355 4.463a1.419 1.419 0 0 0-2.006.14 1.43 1.43 0 0 0 .14 2.013L3.968 9.65a1.42 1.42 0 0 0 2.045-.188l5.677-7.146a1.43 1.43 0 0 0-.226-2.005 1.419 1.419 0 0 0-1.998.227L4.714 6.52 2.355 4.463z'/%3E%3C/svg%3E%0A");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #d1f2df;
  background-color: #d1f2df;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%231abd5e' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(26, 189, 94, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(26, 189, 94, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%231abd5e'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(26, 189, 94, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #a4b3ba;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(26, 189, 94, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.125rem + 2px);
  padding: 0.5625rem 2.6875rem 0.5625rem 0.9375rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #364247;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzM2NDI0NyIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICA8Zz4KICAgICAgICAgICAgICAgICAgICA8Zz4KICAgICAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTggNS44NDFsNi42NzUtNS43MmMuMjEtLjE4LjUyNS0uMTU2LjcwNS4wNTQuMTguMjEuMTU1LjUyNS0uMDU1LjcwNWwtNyA2Yy0uMTg3LjE2LS40NjMuMTYtLjY1IDBsLTctNkMuNDY1LjcuNDQuMzg0LjYyLjE3NWMuMTgtLjIxLjQ5Ni0uMjM0LjcwNS0uMDU1TDggNS44NDF6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzI5IC0zODEpIHRyYW5zbGF0ZSgxNSAzNjIpIHRyYW5zbGF0ZSgzMTQgMTkpIHRyYW5zbGF0ZSgwIDUpIi8+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K") right 0.9375rem center/16px 16px no-repeat;
  border: 1px solid #d2dadd;
  border-radius: 0.25rem;
  appearance: none;
}

.custom-select:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(102, 175, 233, 0.5);
}

.custom-select:focus::-ms-value {
  color: #364247;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.9375rem;
  background-image: none;
}

.custom-select:disabled {
  color: #81969f;
  background-color: #eff2f3;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #364247;
}

.custom-select-sm {
  height: calc(1.428571em + 0.75rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.75rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.444444em + 1.625rem + 2px);
  padding-top: 0.8125rem;
  padding-bottom: 0.8125rem;
  padding-left: 0.75rem;
  font-size: 112%;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.125rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 1.125rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #d2dadd;
  box-shadow: 0 0 0 0.2rem rgba(209, 242, 223, 0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e6ebec;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 1.125rem + 2px);
  padding: 0.5625rem 0.9375rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #364247;
  background-color: #fff;
  border: 1px solid #d2dadd;
  border-radius: 2px;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 1.125rem);
  padding: 0.5625rem 0.9375rem;
  line-height: 1.5;
  color: #364247;
  content: "Browse";
  background-color: #eff2f3;
  border-left: inherit;
  border-radius: 0 2px 2px 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(209, 242, 223, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(209, 242, 223, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(209, 242, 223, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #d1f2df;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: white;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e6ebec;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #d1f2df;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: white;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e6ebec;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #d1f2df;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: white;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #e6ebec;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #e6ebec;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #a4b3ba;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #a4b3ba;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #a4b3ba;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.53125rem 0.75rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #81969f;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #d2dadd;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: transparent;
}

.nav-tabs .nav-link.disabled {
  color: #81969f;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #627881;
  background-color: #eff2f3;
  border-color: #d2dadd #d2dadd #eff2f3;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #1abd5e;
  background-color: #d1f2df;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar, .page-navigation-navbar, .b2b-navbar, .postcode-hero-navigation-navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar .container, .page-navigation-navbar .container, .b2b-navbar .container, .postcode-hero-navigation-navbar .container, .navbar .b2c-page-container, .page-navigation-navbar .b2c-page-container, .b2b-navbar .b2c-page-container, .postcode-hero-navigation-navbar .b2c-page-container, .navbar .hero-container, .page-navigation-navbar .hero-container, .b2b-navbar .hero-container, .postcode-hero-navigation-navbar .hero-container,
.navbar .container-fluid,
.page-navigation-navbar .container-fluid,
.b2b-navbar .container-fluid,
.postcode-hero-navigation-navbar .container-fluid, .navbar .container-xl, .page-navigation-navbar .container-xl, .b2b-navbar .container-xl, .postcode-hero-navigation-navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 1rem;
  font-size: 1.125rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.53125rem;
  padding-bottom: 0.53125rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.125rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container, .navbar-expand-sm > .b2c-page-container, .navbar-expand-sm > .hero-container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-sm > .container, .navbar-expand-sm > .b2c-page-container, .navbar-expand-sm > .hero-container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container, .navbar-expand-md > .b2c-page-container, .navbar-expand-md > .hero-container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-md > .container, .navbar-expand-md > .b2c-page-container, .navbar-expand-md > .hero-container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container, .navbar-expand-lg > .b2c-page-container, .navbar-expand-lg > .hero-container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-lg > .container, .navbar-expand-lg > .b2c-page-container, .navbar-expand-lg > .hero-container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container, .navbar-expand-xl > .b2c-page-container, .navbar-expand-xl > .hero-container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-xl > .container, .navbar-expand-xl > .b2c-page-container, .navbar-expand-xl > .hero-container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container, .navbar-expand > .b2c-page-container, .navbar-expand > .hero-container,
.navbar-expand > .container-fluid, .navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand > .container, .navbar-expand > .b2c-page-container, .navbar-expand > .hero-container,
.navbar-expand > .container-fluid, .navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: #364247;
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #364247;
}

.navbar-light .navbar-nav .nav-link {
  color: #364247;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #364247;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #364247;
}

.navbar-light .navbar-toggler {
  color: #364247;
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23364247' fill-rule='nonzero' d='M2.5 6a.5.5 0 1 1 0-1h19a.5.5 0 1 1 0 1h-19zm0 6.5a.5.5 0 0 1 0-1h19a.5.5 0 1 1 0 1h-19zm0 6.5a.5.5 0 1 1 0-1h19a.5.5 0 1 1 0 1h-19z'/%3E%3C/svg%3E%0A");
}

.navbar-light .navbar-text {
  color: #364247;
}

.navbar-light .navbar-text a {
  color: #364247;
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: #364247;
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card, .post-card-container .post-card-wrapper .post-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
}

.card > hr, .post-card-container .post-card-wrapper .post-card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group, .post-card-container .post-card-wrapper .post-card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child, .post-card-container .post-card-wrapper .post-card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.card > .list-group:last-child, .post-card-container .post-card-wrapper .post-card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.card > .card-header + .list-group, .post-card-container .post-card-wrapper .post-card > .card-header + .list-group,
.card > .list-group + .card-footer,
.post-card-container .post-card-wrapper .post-card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 1.375rem;
}

.card-subtitle {
  margin-top: -0.6875rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 1.375rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: 3px 3px 0 0;
}

.card-footer {
  padding: 1.375rem 1.25rem;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 3px 3px;
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -1.375rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 3px;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.card-deck .card, .card-deck .post-card-container .post-card-wrapper .post-card, .post-card-container .post-card-wrapper .card-deck .post-card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card, .card-deck .post-card-container .post-card-wrapper .post-card, .post-card-container .post-card-wrapper .card-deck .post-card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card, .post-card-container .post-card-wrapper .card-group > .post-card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card, .post-card-container .post-card-wrapper .card-group > .post-card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card, .post-card-container .post-card-wrapper .card-group > .post-card + .card, .post-card-container .post-card-wrapper .card-group > .card + .post-card, .post-card-container .post-card-wrapper .card-group > .post-card + .post-card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child), .post-card-container .post-card-wrapper .card-group > .post-card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top, .post-card-container .post-card-wrapper .card-group > .post-card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header,
  .post-card-container .post-card-wrapper .card-group > .post-card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom, .post-card-container .post-card-wrapper .card-group > .post-card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer,
  .post-card-container .post-card-wrapper .card-group > .post-card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child), .post-card-container .post-card-wrapper .card-group > .post-card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top, .post-card-container .post-card-wrapper .card-group > .post-card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header,
  .post-card-container .post-card-wrapper .card-group > .post-card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom, .post-card-container .post-card-wrapper .card-group > .post-card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer,
  .post-card-container .post-card-wrapper .card-group > .post-card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card, .card-columns .post-card-container .post-card-wrapper .post-card, .post-card-container .post-card-wrapper .card-columns .post-card {
  margin-bottom: 1.375rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card, .card-columns .post-card-container .post-card-wrapper .post-card, .post-card-container .post-card-wrapper .card-columns .post-card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card, .post-card-container .post-card-wrapper .accordion > .post-card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type), .post-card-container .post-card-wrapper .accordion > .post-card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type), .post-card-container .post-card-wrapper .accordion > .post-card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header, .post-card-container .post-card-wrapper .accordion > .post-card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #eff2f3;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #81969f;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #81969f;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.375rem 0.6rem;
  margin-left: 0;
  line-height: 1.2rem;
  color: #364247;
  background-color: transparent;
  border: 0 solid transparent;
}

.page-link:hover {
  z-index: 2;
  color: #364247;
  text-decoration: none;
  background-color: #eff2f3;
  border-color: #eff2f3;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(209, 242, 223, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #1abd5e;
  background-color: #d1f2df;
  border-color: transparent;
}

.page-item.disabled .page-link {
  color: #81969f;
  pointer-events: none;
  cursor: auto;
  background-color: transparent;
  border-color: #d2dadd;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
  line-height: 1.444444;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.428571;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.badge {
  display: inline-block;
  padding: 3px 10px;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #1abd5e;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #149048;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(26, 189, 94, 0.5);
}

.badge-secondary {
  color: #212529;
  background-color: #fff;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #212529;
  background-color: #e6e6e6;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ef7b45;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #eb5a16;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(239, 123, 69, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #d9534f;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #c9302c;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #fcfcfc;
}

a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #e2e2e2;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(252, 252, 252, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #364247;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #20272a;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(54, 66, 71, 0.5);
}

.badge-accent {
  color: #fff;
  background-color: #1493c8;
}

a.badge-accent:hover, a.badge-accent:focus {
  color: #fff;
  background-color: #0f719a;
}

a.badge-accent:focus, a.badge-accent.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(20, 147, 200, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #d1f2df;
  border-radius: 0.25rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #0e6231;
  background-color: #d1f2df;
  border-color: #bfedd2;
}

.alert-primary hr {
  border-top-color: #abe7c4;
}

.alert-primary .alert-link {
  color: #08351b;
}

.alert-secondary {
  color: #858585;
  background-color: white;
  border-color: white;
}

.alert-secondary hr {
  border-top-color: #f2f2f2;
}

.alert-secondary .alert-link {
  color: #6c6c6c;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #7c4024;
  background-color: #fce5da;
  border-color: #fbdacb;
}

.alert-warning hr {
  border-top-color: #f9c9b3;
}

.alert-warning .alert-link {
  color: #542c19;
}

.alert-danger {
  color: #712b29;
  background-color: #f7dddc;
  border-color: #f4cfce;
}

.alert-danger hr {
  border-top-color: #efbbb9;
}

.alert-danger .alert-link {
  color: #4c1d1b;
}

.alert-light {
  color: #838383;
  background-color: #fefefe;
  border-color: #fefefe;
}

.alert-light hr {
  border-top-color: #f1f1f1;
}

.alert-light .alert-link {
  color: #6a6a6a;
}

.alert-dark {
  color: #1c2225;
  background-color: #d7d9da;
  border-color: #c7cacb;
}

.alert-dark hr {
  border-top-color: #babdbf;
}

.alert-dark .alert-link {
  color: #060708;
}

.alert-accent {
  color: #0a4c68;
  background-color: #d0e9f4;
  border-color: #bde1f0;
}

.alert-accent hr {
  border-top-color: #a8d8eb;
}

.alert-accent .alert-link {
  color: #062a39;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #eff2f3;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #1abd5e;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #627881;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #627881;
  text-decoration: none;
  background-color: #fcfcfc;
}

.list-group-item-action:active {
  color: #627881;
  background-color: #eff2f3;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #81969f;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #1abd5e;
  background-color: #d1f2df;
  border-color: #d1f2df;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #0e6231;
  background-color: #bfedd2;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #0e6231;
  background-color: #abe7c4;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #0e6231;
  border-color: #0e6231;
}

.list-group-item-secondary {
  color: #858585;
  background-color: white;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #7c4024;
  background-color: #fbdacb;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #7c4024;
  background-color: #f9c9b3;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #7c4024;
  border-color: #7c4024;
}

.list-group-item-danger {
  color: #712b29;
  background-color: #f4cfce;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #712b29;
  background-color: #efbbb9;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #712b29;
  border-color: #712b29;
}

.list-group-item-light {
  color: #838383;
  background-color: #fefefe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #838383;
  background-color: #f1f1f1;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #838383;
  border-color: #838383;
}

.list-group-item-dark {
  color: #1c2225;
  background-color: #c7cacb;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1c2225;
  background-color: #babdbf;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1c2225;
  border-color: #1c2225;
}

.list-group-item-accent {
  color: #0a4c68;
  background-color: #bde1f0;
}

.list-group-item-accent.list-group-item-action:hover, .list-group-item-accent.list-group-item-action:focus {
  color: #0a4c68;
  background-color: #a8d8eb;
}

.list-group-item-accent.list-group-item-action.active {
  color: #fff;
  background-color: #0a4c68;
  border-color: #0a4c68;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #364247;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #364247;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #81969f;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #627881;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e6ebec;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e6ebec;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 570px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 370px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 870px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.25rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.25rem 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.25rem 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #364247;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #627881;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #1abd5e !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #149048 !important;
}

.bg-secondary {
  background-color: #fff !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #e6e6e6 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ef7b45 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #eb5a16 !important;
}

.bg-danger {
  background-color: #d9534f !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #c9302c !important;
}

.bg-light {
  background-color: #fcfcfc !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e2e2e2 !important;
}

.bg-dark {
  background-color: #364247 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #20272a !important;
}

.bg-accent {
  background-color: #1493c8 !important;
}

a.bg-accent:hover, a.bg-accent:focus,
button.bg-accent:hover,
button.bg-accent:focus {
  background-color: #0f719a !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border, .custom-control.control-checkbox-btn {
  border: 1px solid #e6ebec !important;
}

.border-top {
  border-top: 1px solid #e6ebec !important;
}

.border-right {
  border-right: 1px solid #e6ebec !important;
}

.border-bottom, .flex-table-data {
  border-bottom: 1px solid #e6ebec !important;
}

.border-left {
  border-left: 1px solid #e6ebec !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #1abd5e !important;
}

.border-secondary {
  border-color: #fff !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ef7b45 !important;
}

.border-danger {
  border-color: #d9534f !important;
}

.border-light {
  border-color: #fcfcfc !important;
}

.border-dark {
  border-color: #364247 !important;
}

.border-accent {
  border-color: #1493c8 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.25rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex, .timeline-vertical .timeline-item, .logo-container, .companyprofile-logo-container, .navbar .navbar-brand, .page-navigation-navbar .navbar-brand, .b2b-navbar .navbar-brand, .postcode-hero-navigation-navbar .navbar-brand, .page-item .page-link, .postcode-hero-container .postcode-hero-form-container .postcode-hero-text, .company-logo-container-wrapper .company-logo-container, .nav-list-element {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column, .card-header.select-container {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap, .card-header {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center, .page-item .page-link, .postcode-hero-container .postcode-hero-form-container .postcode-hero-text, .company-logo-container-wrapper .company-logo-container {
  justify-content: center !important;
}

.justify-content-between, .nav-list-element {
  justify-content: space-between !important;
}

.justify-content-around, .logo-container, .companyprofile-logo-container {
  justify-content: space-around !important;
}

.align-items-start, .card-header.select-container {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center, .timeline-vertical .timeline-item, .postcode-hero-container .postcode-hero-form-container .postcode-hero-text, .company-logo-container-wrapper .company-logo-container, .nav-list-element {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row, .card-header.select-container {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center, .card-header.select-container {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row, .static-field {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.08), 0 0 14px 0 rgba(0, 0, 0, 0.16) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #1abd5e !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #117a3d !important;
}

.text-secondary {
  color: #fff !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #d9d9d9 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ef7b45 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #d55012 !important;
}

.text-danger {
  color: #d9534f !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #b52b27 !important;
}

.text-light {
  color: #fcfcfc !important;
}

a.text-light:hover, a.text-light:focus {
  color: #d6d6d6 !important;
}

.text-dark {
  color: #364247 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #151a1c !important;
}

.text-accent, .nav-list-element {
  color: #1493c8 !important;
}

a.text-accent:hover, a.nav-list-element:hover, a.text-accent:focus, a.nav-list-element:focus {
  color: #0d6082 !important;
}

.text-body {
  color: #627881 !important;
}

.text-muted {
  color: #81969f !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #a4b3ba;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container, .b2c-page-container, .hero-container {
    min-width: 992px !important;
  }
  .navbar, .page-navigation-navbar, .b2b-navbar, .postcode-hero-navigation-navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e6ebec !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #e6ebec;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #e6ebec;
  }
}

/**
  Custom SASS files
 */
.action-panel .panel-link {
  padding-bottom: 0;
  font-size: 1rem;
  color: #364247;
  border-bottom: none;
}

.action-panel .list-group-item .list-group-item:last-of-type {
  padding-bottom: 0;
  background-color: #d0e9f4;
}

.sd-pnotify {
  position: fixed;
  z-index: 9999;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.08), 0 0 14px 0 rgba(0, 0, 0, 0.16);
}

.sd-pnotify .alert {
  margin-bottom: 0;
}

.alert-primary {
  color: #1493c8;
  background-color: #d0e9f4;
  border-color: #d0e9f4;
}

.alert-success {
  color: #1abd5e;
  background-color: #d1f2df;
  border-color: #d1f2df;
}

.alert-warning {
  color: #ef7b45;
  background-color: #fce5da;
  border-color: #fce5da;
}

.alert-danger {
  color: #d9534f;
  background-color: #f7dddc;
  border-color: #f7dddc;
}

.alert-internal {
  font-weight: 400;
}

.alert-internal .alert {
  margin-bottom: 0;
}

.arrow-dropdown-toggle {
  position: relative;
}

.arrow-dropdown-toggle::after {
  transform: rotate(0deg);
}

.arrow-dropdown-toggle::after {
  display: inline-block;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23364247' fill-rule='nonzero' d='M10.716 3.645a.455.455 0 0 1 .568.71l-5 4a.455.455 0 0 1-.568 0l-5-4a.455.455 0 0 1 .568-.71L6 7.418l4.716-3.773z'/%3E%3C/svg%3E%0A") no-repeat;
  border: 0;
}

.arrow-dropdown-toggle::after {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
}

.arrow-dropdown-toggle.hide-arrow-dropdown-toggle::after {
  visibility: hidden;
}

.arrow-dropdown-toggle.arrow-dropdown-transition::after {
  transition-duration: .1s;
}

.show > .arrow-dropdown-toggle-rotate-90deg::after {
  transform: rotate(90deg);
}

.show > .arrow-dropdown-toggle-rotate-180deg::after {
  transform: rotate(180deg);
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-right.arrow-dropdown-toggle-primary::after {
  transform: rotate(0deg);
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-right.arrow-dropdown-toggle-primary::after {
  display: inline-block;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%231abd5e' fill-rule='nonzero' d='M3.645 1.284a.455.455 0 0 1 .71-.568l4 5a.455.455 0 0 1 0 .568l-4 5a.455.455 0 0 1-.71-.568L7.418 6 3.645 1.284z'/%3E%3C/svg%3E%0A") no-repeat;
  border: 0;
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-right.arrow-dropdown-toggle-primary::after {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-right.arrow-dropdown-toggle-accent::after {
  transform: rotate(0deg);
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-right.arrow-dropdown-toggle-accent::after {
  display: inline-block;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%231493c8' fill-rule='nonzero' d='M3.645 1.284a.455.455 0 0 1 .71-.568l4 5a.455.455 0 0 1 0 .568l-4 5a.455.455 0 0 1-.71-.568L7.418 6 3.645 1.284z'/%3E%3C/svg%3E%0A") no-repeat;
  border: 0;
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-right.arrow-dropdown-toggle-accent::after {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-bottom.arrow-dropdown-toggle-primary::after {
  transform: rotate(0deg);
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-bottom.arrow-dropdown-toggle-primary::after {
  display: inline-block;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%231abd5e' fill-rule='nonzero' d='M10.716 3.645a.455.455 0 0 1 .568.71l-5 4a.455.455 0 0 1-.568 0l-5-4a.455.455 0 0 1 .568-.71L6 7.418l4.716-3.773z'/%3E%3C/svg%3E%0A") no-repeat;
  border: 0;
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-bottom.arrow-dropdown-toggle-primary::after {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-bottom.arrow-dropdown-toggle-accent::after {
  transform: rotate(0deg);
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-bottom.arrow-dropdown-toggle-accent::after {
  display: inline-block;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%231493c8' fill-rule='nonzero' d='M10.716 3.645a.455.455 0 0 1 .568.71l-5 4a.455.455 0 0 1-.568 0l-5-4a.455.455 0 0 1 .568-.71L6 7.418l4.716-3.773z'/%3E%3C/svg%3E%0A") no-repeat;
  border: 0;
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-bottom.arrow-dropdown-toggle-accent::after {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-left.arrow-dropdown-toggle-primary::after {
  transform: rotate(180deg);
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-left.arrow-dropdown-toggle-primary::after {
  display: inline-block;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%231abd5e' fill-rule='nonzero' d='M3.645 1.284a.455.455 0 0 1 .71-.568l4 5a.455.455 0 0 1 0 .568l-4 5a.455.455 0 0 1-.71-.568L7.418 6 3.645 1.284z'/%3E%3C/svg%3E%0A") no-repeat;
  border: 0;
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-left.arrow-dropdown-toggle-primary::after {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-left.arrow-dropdown-toggle-accent::after {
  transform: rotate(180deg);
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-left.arrow-dropdown-toggle-accent::after {
  display: inline-block;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%231493c8' fill-rule='nonzero' d='M3.645 1.284a.455.455 0 0 1 .71-.568l4 5a.455.455 0 0 1 0 .568l-4 5a.455.455 0 0 1-.71-.568L7.418 6 3.645 1.284z'/%3E%3C/svg%3E%0A") no-repeat;
  border: 0;
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-left.arrow-dropdown-toggle-accent::after {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-prepend::before {
  transform: rotate(0deg);
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-prepend::before {
  display: inline-block;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23364247' fill-rule='nonzero' d='M10.716 3.645a.455.455 0 0 1 .568.71l-5 4a.455.455 0 0 1-.568 0l-5-4a.455.455 0 0 1 .568-.71L6 7.418l4.716-3.773z'/%3E%3C/svg%3E%0A") no-repeat;
  border: 0;
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-prepend::before {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-prepend.hide-arrow-dropdown-toggle::before {
  visibility: hidden;
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-prepend.arrow-dropdown-transition::before {
  transition-duration: .1s;
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-prepend::after {
  display: none;
}

.show > .arrow-dropdown-toggle-rotate-90deg::before {
  transform: rotate(90deg);
}

.show > .arrow-dropdown-toggle-rotate-180deg::before {
  transform: rotate(180deg);
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-prepend.arrow-dropdown-toggle-right.arrow-dropdown-toggle-primary::before {
  transform: rotate(0deg);
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-prepend.arrow-dropdown-toggle-right.arrow-dropdown-toggle-primary::before {
  display: inline-block;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%231abd5e' fill-rule='nonzero' d='M3.645 1.284a.455.455 0 0 1 .71-.568l4 5a.455.455 0 0 1 0 .568l-4 5a.455.455 0 0 1-.71-.568L7.418 6 3.645 1.284z'/%3E%3C/svg%3E%0A") no-repeat;
  border: 0;
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-prepend.arrow-dropdown-toggle-right.arrow-dropdown-toggle-primary::before {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-prepend.arrow-dropdown-toggle-right.arrow-dropdown-toggle-accent::before {
  transform: rotate(0deg);
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-prepend.arrow-dropdown-toggle-right.arrow-dropdown-toggle-accent::before {
  display: inline-block;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%231493c8' fill-rule='nonzero' d='M3.645 1.284a.455.455 0 0 1 .71-.568l4 5a.455.455 0 0 1 0 .568l-4 5a.455.455 0 0 1-.71-.568L7.418 6 3.645 1.284z'/%3E%3C/svg%3E%0A") no-repeat;
  border: 0;
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-prepend.arrow-dropdown-toggle-right.arrow-dropdown-toggle-accent::before {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-prepend.arrow-dropdown-toggle-bottom.arrow-dropdown-toggle-primary::before {
  transform: rotate(0deg);
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-prepend.arrow-dropdown-toggle-bottom.arrow-dropdown-toggle-primary::before {
  display: inline-block;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%231abd5e' fill-rule='nonzero' d='M10.716 3.645a.455.455 0 0 1 .568.71l-5 4a.455.455 0 0 1-.568 0l-5-4a.455.455 0 0 1 .568-.71L6 7.418l4.716-3.773z'/%3E%3C/svg%3E%0A") no-repeat;
  border: 0;
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-prepend.arrow-dropdown-toggle-bottom.arrow-dropdown-toggle-primary::before {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-prepend.arrow-dropdown-toggle-bottom.arrow-dropdown-toggle-accent::before {
  transform: rotate(0deg);
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-prepend.arrow-dropdown-toggle-bottom.arrow-dropdown-toggle-accent::before {
  display: inline-block;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%231493c8' fill-rule='nonzero' d='M10.716 3.645a.455.455 0 0 1 .568.71l-5 4a.455.455 0 0 1-.568 0l-5-4a.455.455 0 0 1 .568-.71L6 7.418l4.716-3.773z'/%3E%3C/svg%3E%0A") no-repeat;
  border: 0;
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-prepend.arrow-dropdown-toggle-bottom.arrow-dropdown-toggle-accent::before {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-prepend.arrow-dropdown-toggle-left.arrow-dropdown-toggle-primary::before {
  transform: rotate(180deg);
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-prepend.arrow-dropdown-toggle-left.arrow-dropdown-toggle-primary::before {
  display: inline-block;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%231abd5e' fill-rule='nonzero' d='M3.645 1.284a.455.455 0 0 1 .71-.568l4 5a.455.455 0 0 1 0 .568l-4 5a.455.455 0 0 1-.71-.568L7.418 6 3.645 1.284z'/%3E%3C/svg%3E%0A") no-repeat;
  border: 0;
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-prepend.arrow-dropdown-toggle-left.arrow-dropdown-toggle-primary::before {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-prepend.arrow-dropdown-toggle-left.arrow-dropdown-toggle-accent::before {
  transform: rotate(180deg);
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-prepend.arrow-dropdown-toggle-left.arrow-dropdown-toggle-accent::before {
  display: inline-block;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%231493c8' fill-rule='nonzero' d='M3.645 1.284a.455.455 0 0 1 .71-.568l4 5a.455.455 0 0 1 0 .568l-4 5a.455.455 0 0 1-.71-.568L7.418 6 3.645 1.284z'/%3E%3C/svg%3E%0A") no-repeat;
  border: 0;
}

.arrow-dropdown-toggle.arrow-dropdown-toggle-prepend.arrow-dropdown-toggle-left.arrow-dropdown-toggle-accent::before {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
}

.arrow-accent-right::after {
  transform: rotate(0deg);
}

.arrow-accent-right::after {
  display: inline-block;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%231493c8' fill-rule='nonzero' d='M3.645 1.284a.455.455 0 0 1 .71-.568l4 5a.455.455 0 0 1 0 .568l-4 5a.455.455 0 0 1-.71-.568L7.418 6 3.645 1.284z'/%3E%3C/svg%3E%0A") no-repeat;
  border: 0;
}

.arrow-accent-right::after {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
}

.arrow-accent-left::before {
  transform: rotate(180deg);
}

.arrow-accent-left::before {
  display: inline-block;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%231493c8' fill-rule='nonzero' d='M3.645 1.284a.455.455 0 0 1 .71-.568l4 5a.455.455 0 0 1 0 .568l-4 5a.455.455 0 0 1-.71-.568L7.418 6 3.645 1.284z'/%3E%3C/svg%3E%0A") no-repeat;
  border: 0;
}

.arrow-accent-left::before {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
}

.arrow-primary-right::after {
  transform: rotate(0deg);
}

.arrow-primary-right::after {
  display: inline-block;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%231abd5e' fill-rule='nonzero' d='M3.645 1.284a.455.455 0 0 1 .71-.568l4 5a.455.455 0 0 1 0 .568l-4 5a.455.455 0 0 1-.71-.568L7.418 6 3.645 1.284z'/%3E%3C/svg%3E%0A") no-repeat;
  border: 0;
}

.arrow-primary-right::after {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
}

.arrow-primary-left::after {
  transform: rotate(0deg);
}

.arrow-primary-left::after {
  display: inline-block;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%231abd5e' fill-rule='nonzero' d='M3.645 1.284a.455.455 0 0 1 .71-.568l4 5a.455.455 0 0 1 0 .568l-4 5a.455.455 0 0 1-.71-.568L7.418 6 3.645 1.284z'/%3E%3C/svg%3E%0A") no-repeat;
  border: 0;
}

.arrow-primary-left::after {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
}

.arrow-primary-left::after {
  transform: rotate(180deg);
}

.arrow-primary-top::after {
  transform: rotate(0deg);
}

.arrow-primary-top::after {
  display: inline-block;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%231abd5e' fill-rule='nonzero' d='M10.716 3.645a.455.455 0 0 1 .568.71l-5 4a.455.455 0 0 1-.568 0l-5-4a.455.455 0 0 1 .568-.71L6 7.418l4.716-3.773z'/%3E%3C/svg%3E%0A") no-repeat;
  border: 0;
}

.arrow-primary-top::after {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
}

.arrow-primary-top::after {
  transform: rotate(180deg);
}

.arrow-accent-bottom::after {
  transform: rotate(0deg);
}

.arrow-accent-bottom::after {
  display: inline-block;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%231493c8' fill-rule='nonzero' d='M10.716 3.645a.455.455 0 0 1 .568.71l-5 4a.455.455 0 0 1-.568 0l-5-4a.455.455 0 0 1 .568-.71L6 7.418l4.716-3.773z'/%3E%3C/svg%3E%0A") no-repeat;
  border: 0;
}

.arrow-accent-bottom::after {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
}

.arrow-bottom::after {
  transform: rotate(0deg);
}

.arrow-bottom::after {
  display: inline-block;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23627881' fill-rule='nonzero' d='M10.716 3.645a.455.455 0 0 1 .568.71l-5 4a.455.455 0 0 1-.568 0l-5-4a.455.455 0 0 1 .568-.71L6 7.418l4.716-3.773z'/%3E%3C/svg%3E%0A") no-repeat;
  border: 0;
}

.arrow-bottom::after {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
}

.arrow-top::after {
  transform: rotate(0deg);
}

.arrow-top::after {
  display: inline-block;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23364247' fill-rule='nonzero' d='M10.716 3.645a.455.455 0 0 1 .568.71l-5 4a.455.455 0 0 1-.568 0l-5-4a.455.455 0 0 1 .568-.71L6 7.418l4.716-3.773z'/%3E%3C/svg%3E%0A") no-repeat;
  border: 0;
}

.arrow-top::after {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
}

.arrow-top::after {
  transform: rotate(180deg);
}

.arrow-top.collapsed::after {
  transform: rotate(360deg);
}

.arrow-top.collapsed::after {
  transform: rotate(0deg);
}

.arrow-top.collapsed::after {
  display: inline-block;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23627881' fill-rule='nonzero' d='M10.716 3.645a.455.455 0 0 1 .568.71l-5 4a.455.455 0 0 1-.568 0l-5-4a.455.455 0 0 1 .568-.71L6 7.418l4.716-3.773z'/%3E%3C/svg%3E%0A") no-repeat;
  border: 0;
}

.arrow-top.collapsed::after {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
}

.arrow-primary-right-rotate-90:not(.hide-arrow)::before {
  transform: rotate(0deg);
}

.arrow-primary-right-rotate-90:not(.hide-arrow)::before {
  display: inline-block;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%231abd5e' fill-rule='nonzero' d='M3.645 1.284a.455.455 0 0 1 .71-.568l4 5a.455.455 0 0 1 0 .568l-4 5a.455.455 0 0 1-.71-.568L7.418 6 3.645 1.284z'/%3E%3C/svg%3E%0A") no-repeat;
  border: 0;
}

.arrow-primary-right-rotate-90:not(.hide-arrow)::before {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
}

.show .arrow-primary-right-rotate-90:not(.hide-arrow)::before {
  transform: rotate(0deg);
}

.show .arrow-primary-right-rotate-90:not(.hide-arrow)::before {
  display: inline-block;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%231abd5e' fill-rule='nonzero' d='M10.716 3.645a.455.455 0 0 1 .568.71l-5 4a.455.455 0 0 1-.568 0l-5-4a.455.455 0 0 1 .568-.71L6 7.418l4.716-3.773z'/%3E%3C/svg%3E%0A") no-repeat;
  border: 0;
}

.show .arrow-primary-right-rotate-90:not(.hide-arrow)::before {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
}

.badge-gray {
  background-color: #e6ebec;
}

.badge-gray-200 {
  background-color: #e6ebec;
}

.badge {
  font-family: "SofiaPro";
  line-height: 1.5;
}

.badge-small {
  padding: 2px 8px;
  font-size: 0.875rem;
}

.badge-large {
  padding: 4px 12px;
  font-size: 1rem;
}

.badge-radius-lg {
  border-radius: 8px;
}

.badge-accent-lighter {
  background-color: #d0e9f4;
}

.badge-accent-lighter[href]:hover {
  text-decoration: none;
  background-color: #a1d4e9;
}

.badge-primary-lighter {
  color: #1abd5e;
  background-color: #d1f2df;
}

.badge-intermediate {
  opacity: .7;
}

.badge-margin-xs {
  margin: 2px;
}

.badge-notification {
  width: 8px;
  height: 8px;
  background: #e6ebec;
  border-radius: 4px;
}

.border-light {
  border: 1px solid #e6ebec !important;
}

.border-bold {
  border-width: 2px !important;
}

.border-radius-bottom {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.border-radius {
  border-radius: 4px;
}

.border-radius-top {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.border-shadow {
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.16);
}

.border-bottom-last:last-of-type {
  border-bottom: 1px solid #e6ebec;
}

.border-bottom-md-right {
  border-bottom: 1px solid #e6ebec;
}

@media (min-width: 768px) {
  .border-bottom-md-right {
    border-right: 1px solid #e6ebec;
    border-bottom: none;
  }
}

@media (min-width: 768px) {
  .border-right-odd:nth-child(odd) {
    border-right: 1px solid #e6ebec;
  }
}

.border-dashed-gray-100 {
  border: 2px dashed #fcfcfc;
}

.border-dashed-gray-200 {
  border: 2px dashed #eff2f3;
}

.border-dashed-gray-300 {
  border: 2px dashed #e6ebec;
}

.border-dashed-gray-400 {
  border: 2px dashed #d2dadd;
}

.border-dashed-gray-500 {
  border: 2px dashed #a4b3ba;
}

.border-dashed-gray-600 {
  border: 2px dashed #81969f;
}

.border-dashed-gray-700 {
  border: 2px dashed #627881;
}

.border-dashed-gray-800 {
  border: 2px dashed #364247;
}

.border-dashed-gray-900 {
  border: 2px dashed #212529;
}

.border-dashed-gray-150 {
  border: 2px dashed #f8f9fa;
}

.outline-primary-bold {
  outline: 2px solid #1abd5e !important;
}

.btn {
  font-family: "SofiaPro";
  box-shadow: 0 -2px 0 0 rgba(0, 0, 0, 0.1) inset;
}

.btn[class*="btn-outline-"], .btn.btn-secondary, .btn.btn-link {
  box-shadow: none;
}

.btn.btn-link {
  padding: 0;
  text-decoration: underline;
}

.btn.btn-link[class*="arrow-"]::before {
  margin-right: 0.5rem;
}

.btn-padding {
  padding: 0.625rem 1rem;
}

.btn-icon {
  padding: 0.625rem 1rem;
  background: transparent;
  border: 0;
}

.btn-no-outline {
  font-weight: 700;
  color: #627881;
  background: none;
  border: 0;
  box-shadow: none;
}

.btn-no-outline:hover {
  color: #364247;
}

.btn-no-outline.disabled {
  color: #a4b3ba;
}

.btn-no-outline svg {
  margin-bottom: -2px;
}

.btn-primary {
  color: #fff;
  background-color: #1abd5e;
  border-color: #1abd5e;
  border: 0;
}

.btn-primary:hover {
  color: #fff;
  background-color: #17aa55;
  border-color: #17aa55;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #17aa55;
  border-color: #17aa55;
  box-shadow: 0 0 0 0.2rem rgba(60, 199, 118, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #1abd5e;
  border-color: #1abd5e;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #17aa55;
  border-color: #17aa55;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 199, 118, 0.5);
}

.btn-primary.btn {
  padding: 0.625rem 1.5rem;
}

.btn-primary.btn.btn-sm, .btn-group-sm > .btn-primary.btn {
  padding: 0.44rem 1.0375rem;
}

.btn-primary.btn.btn-lg, .btn-group-lg > .btn-primary.btn {
  padding: 1rem 1.5rem;
}

.btn-secondary {
  color: #212529;
  background-color: #fff;
  border-color: #d2dadd;
  color: #1abd5e;
  background-color: transparent;
  border-width: 1.5px;
}

.btn-secondary:hover {
  color: #212529;
  background-color: #fff;
  border-color: #a4b3ba;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #212529;
  background-color: #fff;
  border-color: #a4b3ba;
  box-shadow: 0 0 0 0.2rem rgba(184, 191, 194, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #212529;
  background-color: #fff;
  border-color: #d2dadd;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #212529;
  background-color: #fff;
  border-color: #d2dadd;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 191, 194, 0.5);
}

.btn-secondary:hover {
  background-color: transparent;
}

.btn-secondary:not(:disabled):not(.disabled):active {
  background-color: transparent;
}

.btn-secondary:disabled {
  color: #1abd5e;
}

.btn-secondary:not(:disabled):hover {
  color: #17aa55;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #17aa55;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  color: #17aa55;
}

.btn-transparent {
  color: #fff;
  background-color: transparent;
  border-color: #a4b3ba;
  color: #1abd5e;
  border-width: 1.5px;
  box-shadow: none;
}

.btn-transparent:hover {
  color: #fff;
  background-color: transparent;
  border-color: #81969f;
}

.btn-transparent:focus, .btn-transparent.focus {
  color: #fff;
  background-color: transparent;
  border-color: #81969f;
  box-shadow: 0 0 0 0.2rem rgba(177, 191, 196, 0.5);
}

.btn-transparent.disabled, .btn-transparent:disabled {
  color: #fff;
  background-color: transparent;
  border-color: #a4b3ba;
}

.btn-transparent:not(:disabled):not(.disabled):active, .btn-transparent:not(:disabled):not(.disabled).active,
.show > .btn-transparent.dropdown-toggle {
  color: #fff;
  background-color: transparent;
  border-color: #a4b3ba;
}

.btn-transparent:not(:disabled):not(.disabled):active:focus, .btn-transparent:not(:disabled):not(.disabled).active:focus,
.show > .btn-transparent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(177, 191, 196, 0.5);
}

.btn-transparent:not(:disabled):hover {
  color: #17aa55;
}

.btn-transparent:disabled {
  color: #d1f2df;
}

.btn-transparent:not(:disabled):not(.disabled):active, .btn-transparent:not(:disabled):not(.disabled).active,
.show > .btn-transparent.dropdown-toggle {
  color: #17aa55;
}

.btn-transparent:not(:disabled):not(.disabled):active:focus, .btn-transparent:not(:disabled):not(.disabled).active:focus,
.show > .btn-transparent.dropdown-toggle:focus {
  color: #17aa55;
}

.btn-input-group {
  color: #212529;
  background-color: #fff;
  border-color: #d2dadd;
  display: flex;
  padding-right: .5rem;
  padding-left: .5rem;
  box-shadow: none;
}

.btn-input-group:hover {
  color: #212529;
  background-color: #eff2f3;
  border-color: #a4b3ba;
}

.btn-input-group:focus, .btn-input-group.focus {
  color: #212529;
  background-color: #eff2f3;
  border-color: #a4b3ba;
  box-shadow: 0 0 0 0.2rem rgba(184, 191, 194, 0.5);
}

.btn-input-group.disabled, .btn-input-group:disabled {
  color: #212529;
  background-color: #fff;
  border-color: #d2dadd;
}

.btn-input-group:not(:disabled):not(.disabled):active, .btn-input-group:not(:disabled):not(.disabled).active,
.show > .btn-input-group.dropdown-toggle {
  color: #212529;
  background-color: #fff;
  border-color: #d2dadd;
}

.btn-input-group:not(:disabled):not(.disabled):active:focus, .btn-input-group:not(:disabled):not(.disabled).active:focus,
.show > .btn-input-group.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 191, 194, 0.5);
}

.clickable:hover {
  cursor: pointer;
}

.clickable:hover .card-header {
  color: #364247;
}

button:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn-high-contrast {
  color: #364247;
  background-color: #fff;
  border: 1px solid #d2dadd;
  box-shadow: none;
}

.card-footer .btn {
  margin-left: .5rem;
}

.card-footer .btn:first-child {
  margin-left: 0;
}

.btn-sm-scalable {
  padding: 0.34rem 0.9375rem;
  font-size: 0.875rem;
  line-height: 1.428571;
  border-radius: 0.25rem;
}

@media (min-width: 992px) {
  .btn-sm-scalable {
    padding: 1rem 1.5rem;
    font-size: 1.125rem;
    line-height: 1.25rem;
    border-radius: 0.25rem;
  }
}

.btn-arrow-tail {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn-arrow-tail::after {
  display: block;
  width: 24px;
  height: 24px;
  margin-left: 8px;
  content: "";
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 59.1 (86144) - https://sketch.com --%3E%3Ctitle%3Ebutton-arrow-tail%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='button-arrow-tail-copy' transform='translate(-107.000000, -14.000000)'%3E%3Cg id='Group-2' transform='translate(77.000000, 8.000000)'%3E%3Cg id='Group' transform='translate(30.000000, 4.000000)'%3E%3Crect id='Rectangle' x='0' y='0' width='16' height='20'%3E%3C/rect%3E%3Cpath d='M8,4 L14,10 L8,16 M0,10 L14,10' id='Path-2' stroke='%23FFFFFF' stroke-width='3'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E ");
  background-position: center;
  background-repeat: no-repeat;
}

.btn-wizard-wrap.sd-mt-4 {
  margin-top: 24px !important;
}

.btn-wizard-wrap.sd-mt-2 {
  margin-top: 16px !important;
}

.btn-wizard-wrap .free {
  font-size: 14px;
  margin-top: 2px;
}

.btn-wizard-wrap .free .glyphicon-ok {
  display: inline-block;
  margin-bottom: -3px;
  width: 14px;
  height: 14px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%231abd5e' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 9.5L7.5 15 18 4.5'/%3E%3C/svg%3E%0A");
}

.card, .post-card-container .post-card-wrapper .post-card {
  font-size: 0.875rem;
  border: 0;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.16);
}

@media (max-width: 767.98px) {
  .card.outside-container, .post-card-container .post-card-wrapper .outside-container.post-card {
    margin-right: -15px;
    margin-left: -15px;
    border-top: 1px solid #e6ebec;
    border-bottom: 1px solid #e6ebec;
    border-radius: 0;
    box-shadow: none;
  }
}

.card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.63;
  color: #627881;
  background-color: transparent;
  border: 0;
  box-shadow: inset 0 -1px 0 0 #e6ebec;
}

.card-header .title {
  order: -1;
  max-width: 100%;
  overflow: hidden;
  font-family: "SofiaPro";
  color: #627881;
}

.card-header .button-container ~ .title {
  max-width: 70%;
  margin-right: .5rem;
}

.card-header .header-dropdown {
  margin-top: .5rem;
}

@media (min-width: 576px) {
  .card-header .header-dropdown {
    width: auto;
    margin: -.3rem 0;
  }
}

.card-header .header-notification {
  order: 4;
  width: 100%;
  margin: .5rem 0 0;
  font-size: 0.875rem;
}

@media (min-width: 992px) {
  .card-header .header-notification {
    order: 2;
    width: auto;
    margin: 0 .5rem 0 auto;
  }
}

.card-header .button-container {
  order: 3;
  margin: -.3rem 0;
}

.card-body {
  padding: 0;
}

.card-body .container, .card-body .b2c-page-container, .card-body .hero-container {
  padding: 17px 20px 19px;
}

.card-body > .list-group .list-group-item:last-child {
  border-radius: 0.25rem;
}

.filter-option-wrapper .filter-option {
  padding: 13px 15px;
  color: #364247;
}

.vertical-list .list-element:last-of-type, .vertical-list .navigation-list-toggle:last-of-type, .vertical-list .nav-list-element:last-of-type {
  margin-bottom: 0 !important;
}

@media (min-width: 992px) {
  .horizontal-list-lg .list-element, .horizontal-list-lg .navigation-list-toggle, .horizontal-list-lg .nav-list-element {
    margin-bottom: 0 !important;
  }
}

@media (min-width: 768px) {
  .horizontal-list-md .list-element, .horizontal-list-md .navigation-list-toggle, .horizontal-list-md .nav-list-element {
    margin-bottom: 0 !important;
  }
}

.list-element a, .navigation-list-toggle a, .nav-list-element a {
  display: flex;
  align-items: center;
}

.bg-accent-light {
  background-color: #a1d4e9 !important;
}

.bg-accent-lighter {
  background-color: #d0e9f4 !important;
}

.color-accent {
  color: #1493c8 !important;
}

.color-accent-light {
  color: #a1d4e9 !important;
}

.color-accent-lighter {
  color: #d0e9f4 !important;
}

.bg-primary-light {
  background-color: #d1f2df !important;
}

.bg-primary-lighter {
  background-color: #e8f8ef !important;
}

.color-white {
  color: #fff !important;
}

.accordion .card, .accordion .post-card-container .post-card-wrapper .post-card, .post-card-container .post-card-wrapper .accordion .post-card {
  white-space: normal;
  border: 0;
  border-radius: 0;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.08), 0 0 14px 0 rgba(0, 0, 0, 0.16);
}

.accordion .card-header {
  padding: 0;
  border-radius: 0;
}

.accordion .card-header .btn {
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 1rem 1.25rem;
  margin: 0;
  color: #364247;
  text-align: left;
  text-decoration: none;
  white-space: normal;
  box-shadow: none;
}

.accordion .card-header .btn:hover {
  text-decoration: none;
}

.accordion .card-header .btn .arrow-icon::after {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='%231abd5e' fill-rule='nonzero' d='M8 10.841l6.675-5.72a.5.5 0 0 1 .65.759l-7 6a.5.5 0 0 1-.65 0l-7-6a.5.5 0 1 1 .65-.76L8 10.841z'/%3E%3C/svg%3E%0A") no-repeat;
  border: 0;
}

.accordion .card-header .btn[aria-expanded="true"] .arrow-icon::after {
  transform: rotate(180deg);
}

.accordion .card-body {
  font-size: 0.875rem;
  color: #627881;
  background-color: #f8f9fa;
}

.accordion .card-body .faq-answer {
  padding: .8125rem 1.25rem;
}

@media (min-width: 768px) {
  .accordion .card-body .faq-answer {
    padding-right: 0;
  }
}

.accordion .card-body p {
  margin-bottom: 0;
}

.accordion ul,
.accordion ol {
  padding-left: 1rem;
}

@media (max-width: 991.98px) {
  .menu-collapse {
    height: 0;
    overflow: hidden;
    transition: all 250ms ease-in-out;
  }
  .menu-expand {
    height: 110px;
  }
}

.btn-light:hover {
  background-color: #1abd5e;
}

.btn-light:hover.text-muted {
  color: #fff !important;
}

/**
The styles below are required to style the ui-bootstrap datepicker directive
This directive adds bootstrap 3 classes, so we add these custom styles to match the design with our styleguide
These styles will support both ui-bootstrap versions 0.x and 2.x
 */
.uib-datepicker-popup * {
  outline: none;
}

.uib-datepicker-popup,
div[datepicker] {
  overflow: hidden;
  border: 1px solid #e6ebec;
  box-shadow: 0 0 8px 0 #e6ebec;
}

.uib-datepicker-popup .uib-daypicker,
div[datepicker] .uib-daypicker {
  background-color: #fcfcfc;
}

.uib-datepicker-popup.dropdown-menu,
div[datepicker].dropdown-menu {
  min-width: 0;
}

.uib-datepicker-popup table,
div[datepicker] table {
  width: 100%;
  font-size: 0.875rem;
}

.uib-datepicker-popup tbody::after,
div[datepicker] tbody::after {
  display: inline-block;
  width: 100%;
  height: 20px;
}

.uib-datepicker-popup .active,
div[datepicker] .active {
  background-color: #eff2f3;
}

.uib-datepicker-popup tr > td:first-child,
div[datepicker] tr > td:first-child {
  font-size: 0.875rem;
  color: #627881;
  background-color: #fcfcfc;
}

.uib-datepicker-popup tr small,
div[datepicker] tr small {
  font-size: 100%;
}

.uib-datepicker-popup tr em,
div[datepicker] tr em {
  font-style: normal;
}

.uib-datepicker-popup thead *,
div[datepicker] thead * {
  padding: 0;
  color: #627881;
  border: none;
}

.uib-datepicker-popup thead tr:first-child,
div[datepicker] thead tr:first-child {
  background-color: #fff;
  border-bottom: 1.5px solid #e6ebec;
}

.uib-datepicker-popup thead tr:last-child th,
div[datepicker] thead tr:last-child th {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.uib-datepicker-popup .btn,
div[datepicker] .btn {
  padding: .375rem .45rem;
  border: none;
  border-radius: 0;
  box-shadow: none;
  color: #627881;
}

.uib-datepicker-popup .btn:not(.active),
div[datepicker] .btn:not(.active) {
  background-color: #fff;
}

.uib-datepicker-popup .btn.uib-title, .uib-datepicker-popup .btn[role="heading"],
div[datepicker] .btn.uib-title,
div[datepicker] .btn[role="heading"] {
  padding: .75rem 0 .75rem 0;
  border: none;
}

.uib-datepicker-popup .btn.uib-left, .uib-datepicker-popup .btn.pull-left,
div[datepicker] .btn.uib-left,
div[datepicker] .btn.pull-left {
  transform: rotate(180deg);
}

.uib-datepicker-popup .btn .text-muted,
div[datepicker] .btn .text-muted {
  color: #a4b3ba;
}

.uib-datepicker-popup .btn:not(:disabled):not(.uib-title):hover,
div[datepicker] .btn:not(:disabled):not(.uib-title):hover {
  color: #fff;
  background-color: #1abd5e;
}

.uib-datepicker-popup .btn:not(:disabled):not(.uib-title):hover .text-muted,
div[datepicker] .btn:not(:disabled):not(.uib-title):hover .text-muted {
  color: #fff !important;
}

.uib-datepicker-popup .btn span,
div[datepicker] .btn span {
  background-color: transparent;
}

.uib-datepicker-popup .btn.uib-left, .uib-datepicker-popup .btn.pull-left, .uib-datepicker-popup .btn.uib-right, .uib-datepicker-popup .btn.pull-right,
div[datepicker] .btn.uib-left,
div[datepicker] .btn.pull-left,
div[datepicker] .btn.uib-right,
div[datepicker] .btn.pull-right {
  width: 30px;
  height: 23px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%231abd5e' fill-rule='nonzero' d='M3.645 1.284a.455.455 0 0 1 .71-.568l4 5a.455.455 0 0 1 0 .568l-4 5a.455.455 0 0 1-.71-.568L7.418 6 3.645 1.284z'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position-x: .8rem;
  background-position-y: .35rem;
  border: none;
}

.uib-datepicker-popup .btn.uib-left:not(:disabled):hover, .uib-datepicker-popup .btn.pull-left:not(:disabled):hover, .uib-datepicker-popup .btn.uib-right:not(:disabled):hover, .uib-datepicker-popup .btn.pull-right:not(:disabled):hover,
div[datepicker] .btn.uib-left:not(:disabled):hover,
div[datepicker] .btn.pull-left:not(:disabled):hover,
div[datepicker] .btn.uib-right:not(:disabled):hover,
div[datepicker] .btn.pull-right:not(:disabled):hover {
  color: #fff !important;
  background-color: transparent;
}

.uib-datepicker-popup .btn.uib-left:active, .uib-datepicker-popup .btn.pull-left:active, .uib-datepicker-popup .btn.uib-right:active, .uib-datepicker-popup .btn.pull-right:active,
div[datepicker] .btn.uib-left:active,
div[datepicker] .btn.pull-left:active,
div[datepicker] .btn.uib-right:active,
div[datepicker] .btn.pull-right:active {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%231abd5e' fill-rule='nonzero' d='M3.645 1.284a.455.455 0 0 1 .71-.568l4 5a.455.455 0 0 1 0 .568l-4 5a.455.455 0 0 1-.71-.568L7.418 6 3.645 1.284z'/%3E%3C/svg%3E%0A");
}

.uib-datepicker-popup .btn.uib-left, .uib-datepicker-popup .btn.pull-left,
div[datepicker] .btn.uib-left,
div[datepicker] .btn.pull-left {
  margin-left: .25rem;
}

.uib-datepicker-popup .btn.disabled,
.uib-datepicker-popup .btn:disabled,
div[datepicker] .btn.disabled,
div[datepicker] .btn:disabled {
  cursor: not-allowed;
  background-color: #e6e6e6;
}

.uib-datepicker-popup .btn-info:not(:disabled):not(.disabled).active,
div[datepicker] .btn-info:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #1abd5e;
}

.edit-photo-dropdown {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 20;
}

.edit-photo-dropdown .dropdown-menu {
  overflow: hidden;
}

.edit-photo-dropdown .dropdown-menu[x-placement="top-end"] {
  top: -.3125rem !important;
}

.edit-photo-dropdown .dropdown-menu[x-placement="bottom-end"] {
  top: .3125rem !important;
}

.dropdown-menu {
  border: 0;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.16);
}

.dropdown-menu .dropdown-item {
  border-color: #e6ebec;
}

.dropdown-menu .dropdown-item:last-of-type {
  border-bottom: none;
}

.dropdown .btn {
  border-radius: 2px;
}

.expandable-input:empty::before {
  display: block;
  /* For Firefox */
  color: #81969f;
  content: attr(data-placeholder);
}

.expandable-input {
  max-height: 4rem;
  overflow-y: scroll;
  word-break: break-word;
  cursor: text;
}

.flex-basis-0 {
  flex-basis: 0;
}

.justify-content-space-between {
  justify-content: space-between;
}

@font-face {
  font-family: ProximaNova;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/proxima-nova/ProximaNova-RegularWeb.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: ProximaNova;
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/proxima-nova/ProximaNova-BoldWeb.woff") format("woff");
  font-display: swap;
}

/**
 * @license
 * MyFonts Webfont Build ID 3617159, 2018-08-01T03:22:41-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: SofiaPro-Bold by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/bold/
 *
 * Webfont: SofiaProMedium by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/medium/
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3617159
 * Licensed pageviews: 180,000
 * Webfonts copyright: Copyright &#x00A9; Olivier Gourvat - Mostardesign Type Foundry, 2016. All rights reserved.
 *
 * © 2018 MyFonts Inc
*/
/* @import must be at top of file, otherwise CSS will not work */
@font-face {
  font-family: SofiaPro;
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/sofia-pro/373187_0_0.eot");
  src: url("../fonts/sofia-pro/373187_0_0.eot?#iefix") format("embedded-opentype"), url("../fonts/sofia-pro/373187_0_0.woff2") format("woff2"), url("../fonts/sofia-pro/373187_0_0.woff") format("woff"), url("../fonts/sofia-pro/373187_0_0.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: SofiaPro;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/sofia-pro/373187_1_0.eot");
  src: url("../fonts/sofia-pro/373187_1_0.eot?#iefix") format("embedded-opentype"), url("../fonts/sofia-pro/373187_1_0.woff2") format("woff2"), url("../fonts/sofia-pro/373187_1_0.woff") format("woff"), url("../fonts/sofia-pro/373187_1_0.ttf") format("truetype");
  font-display: swap;
}

.footer-bottom-list, .market-footer .footer-bottom-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: .5rem 0 1.5rem;
}

.footer-bottom-list .nav {
  display: inline-flex;
}

.footer-bottom-list a, .market-footer .footer-bottom-list a {
  color: #81969f;
}

.footer-bottom-list li:first-child .separator {
  display: none;
}

.footer-bottom-list .brand {
  margin-top: .5rem;
  margin-right: 0;
  margin-bottom: 1rem;
  font-family: "SofiaPro";
  color: #a4b3ba;
}

@media (min-width: 992px) {
  .footer-bottom-list .brand {
    margin-top: 0;
    margin-right: 1.25rem;
    margin-bottom: 0;
  }
}

.footer-bottom-list .logo {
  margin-bottom: 1rem;
}

@media (min-width: 992px) {
  .footer-bottom-list .logo {
    margin-bottom: 0;
    margin-left: auto;
  }
}

@media (min-width: 992px) {
  .footer-bottom-list, .market-footer .footer-bottom-list {
    flex-direction: row;
  }
}

.footer-nav {
  flex-direction: column;
  margin-bottom: 2rem;
}

.footer-nav li {
  margin-bottom: .5rem;
}

.footer-nav a {
  color: #81969f;
}

/**
  Dropdown multiselect
 */
.dropdown-select .btn {
  position: relative;
  min-height: 2.125rem;
  padding: 0 calc($dropdown-select-padding-x + $arrow-dropdown-size-x) 0 0.5rem;
  line-height: 1;
}

.dropdown-select .btn.disabled {
  background-color: #eff2f3;
  border-color: rgba(230, 235, 236, 0.5);
  opacity: 1;
}

.dropdown-select .btn .badge {
  top: 0;
}

.dropdown-select .btn::after {
  position: absolute;
  top: calc(50% - 0.21875rem);
  right: .375rem;
}

.dropdown-select .select-label {
  color: #a4b3ba;
}

.show .dropdown-select .select-label {
  color: #627881;
}

.dropdown-select .dropdown-menu {
  font-size: 0.875rem;
}

.dropdown-select .dropdown-item {
  padding: 0.4375rem 0.5rem;
  line-height: 1;
}

.custom-control-inline {
  padding-left: .8125rem;
}

.custom-control-inline.custom-radio .custom-control-label {
  padding: 0 1rem;
}

.custom-control-inline.custom-radio .custom-control-label::after {
  margin: 0 .7rem;
}

.custom-control-inline.custom-radio .custom-control-label::before {
  margin: 0 .7rem;
}

.custom-control-input + label {
  font-weight: 400;
}

.custom-control-label {
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #81969f;
}

.custom-control-label .custom-control-sub-label {
  font-family: "ProximaNova";
  font-size: 14px;
  font-weight: 400;
  color: #81969f;
}

.custom-control-label .custom-control-sub-label.error {
  color: #d9534f;
}

.custom-control-input ~ .custom-control-label::before,
.custom-control-input:focus ~ .custom-control-label::before {
  border: 1px solid #d2dadd;
  box-shadow: none;
}

.custom-radio .custom-control-label,
.custom-checkbox .custom-control-label {
  width: 100%;
  font-weight: 400;
}

.custom-radio .custom-control-label {
  width: 100%;
  padding: 0 1rem;
  font-size: 1rem;
}

.custom-radio .custom-control-label::after {
  margin: 0 .7rem;
}

.custom-radio .custom-control-label::before {
  margin: 0 .7rem;
}

.custom-radio .custom-control-input:active:not(:disabled) ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%231abd5e'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:checked:disabled ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23d1f2df'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #fff;
}

.custom-select {
  appearance: none;
}

.ngb-dp-weekday.small {
  color: #364247;
}

.label-sm {
  margin-bottom: .25rem;
  font-size: 0.875rem;
  line-height: 1.428571;
}

label {
  font-family: "SofiaPro";
  font-size: 1rem;
  font-weight: 700;
  color: #627881;
}

label .help {
  font-size: 12px;
  font-weight: 400;
}

.label-lg {
  font-size: 1.125rem;
}

.form-control:focus {
  color: #627881;
  box-shadow: 0 0 4px 0 #1493c8;
}

.form-control:focus.is-invalid:focus {
  box-shadow: 0 0 4px 0 #1493c8;
}

.custom-control-input:checked + label {
  font-weight: 700;
}

.sd-btn-checkbox {
  display: inline-block;
  width: 305px;
  max-width: 100%;
  padding: 0;
  text-align: left;
  background-color: #fff;
  border: 1px solid #e6ebec;
  border-radius: 0.25rem;
  box-shadow: none;
}

.sd-btn-checkbox:not(.disabled):hover {
  border-color: #81969f;
}

.sd-btn-checkbox label:hover {
  cursor: pointer;
}

.sd-radio-list,
.sd-checkbox-list {
  border-top: 1px solid #e6ebec;
}

.sd-radio-row,
.sd-checkbox-row {
  font-size: 0.875rem;
  border-bottom: 1px solid #e6ebec;
}

.sd-radio-row:hover,
.sd-checkbox-row:hover {
  background-color: #eff2f3;
}

.sd-radio-row .custom-control-input:checked + label,
.sd-checkbox-row .custom-control-input:checked + label {
  font-weight: 700;
}

.sd-radio-row .custom-control-label,
.sd-checkbox-row .custom-control-label {
  padding: .7rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: #364247;
}

.sd-radio-row .custom-control-label.checkbox,
.sd-checkbox-row .custom-control-label.checkbox {
  width: 100%;
  padding: .75rem;
  margin-bottom: 0;
}

.sd-radio-row .custom-control-label.checkbox::after, .sd-radio-row .custom-control-label.checkbox::before,
.sd-checkbox-row .custom-control-label.checkbox::after,
.sd-checkbox-row .custom-control-label.checkbox::before {
  margin: .75rem;
}

.sd-radio-row .custom-control-label::after,
.sd-checkbox-row .custom-control-label::after {
  margin: .7rem;
}

.sd-radio-row .custom-control-label::before,
.sd-checkbox-row .custom-control-label::before {
  margin: .7rem;
}

.sd-radio-row .custom-control-label:hover,
.sd-checkbox-row .custom-control-label:hover {
  cursor: pointer;
}

.sd-radio-row.sd-radio-row-bordered,
.sd-checkbox-row.sd-radio-row-bordered {
  margin-bottom: .5rem;
  border: 1px solid #e6ebec;
  border-radius: 0.25rem;
}

.sd-radio-row.sd-radio-row-bordered.active,
.sd-checkbox-row.sd-radio-row-bordered.active {
  border: 2px solid #1abd5e;
}

.is-invalid:not(input) {
  color: #d9534f;
}

.is-invalid {
  border-color: #d9534f;
}

.form-group.error label {
  color: #d9534f;
}

.form-group.error input,
.form-group.error textarea {
  border-color: #d9534f;
}

.form-group.error .error-message {
  font-size: 0.875rem;
  color: #d9534f;
}

.notification-message-box {
  position: absolute;
  z-index: 1;
  padding: 1rem;
  margin-top: 4px;
  font-size: 14px;
  color: #81969f;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.08), 0 0 14px 0 rgba(0, 0, 0, 0.16);
}

.form-control-plaintext .form-group {
  margin-bottom: 0;
  font-size: 0.875rem;
}

.form-control-plaintext .col-form-label {
  color: #364247;
  text-align: right;
  background-color: #e6ebec;
  border-right: 1px solid #d2dadd;
}

.static-field {
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  margin: 0 16px;
}

.static-field:not(:last-of-type) {
  border-bottom: 1px solid #e6ebec;
}

@media (min-width: 768px) {
  .static-field {
    padding: 10px 0;
  }
}

.static-field label {
  margin-bottom: 2px;
  font-size: 12px;
  line-height: 1.33;
}

@media (min-width: 768px) {
  .static-field label {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.43;
  }
}

@media (min-width: 768px) {
  .static-field:first-child .label-wrapper,
  .static-field:first-child .value-wrapper {
    padding-top: 1rem;
  }
  .static-field:last-child .label-wrapper,
  .static-field:last-child .value-wrapper {
    padding-bottom: 1rem;
  }
}

.sub-label {
  font-size: 12px;
  font-weight: 400;
}

.contact-details-container {
  border-top: solid 1px #e6ebec;
}

@media (min-width: 768px) {
  .contact-details-container {
    border-top: none;
    border-left: solid 1px #e6ebec;
  }
}

.row-items {
  font-size: 0.875rem;
  color: #627881;
}

.row-items .data-item {
  padding: .25rem 0;
}

/** Form PIN **/
.pin-inputs {
  display: flex;
  justify-content: flex-start;
}

.pin-inputs input {
  min-width: 3rem;
  font-size: 1.67rem;
  text-align: center;
}

.datepicker ~ .dropdown-menu {
  min-width: auto;
  font-family: "SofiaPro";
}

[class*="icon-upload-"] {
  width: 16px;
  height: 16px;
}

.icon-upload-clear {
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M2.854 2.146L8 7.293l5.146-5.147a.5.5 0 01.708.708L8.707 8l5.147 5.146a.5.5 0 01-.708.708L8 8.707l-5.146 5.147a.5.5 0 01-.708-.708L7.293 8 2.146 2.854a.5.5 0 01.708-.708z' fill='%23364247' fill-rule='nonzero'/%3E%3C/svg%3E");
}

.icon-upload-loading {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' %3E%3Cpath fill='%23D0E9F4' d='M20 35c-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15zm0-2c7.18 0 13-5.82 13-13S27.18 7 20 7 7 12.82 7 20s5.82 13 13 13z'/%3E%3Cpath fill='%231493C8' d='M29.192 10.808A12.96 12.96 0 0 0 20 7V5c4.142 0 7.892 1.679 10.607 4.393l-1.415 1.415z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.7s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
}

.input-icon, .sd-form-row-invalid .custom-control::after {
  position: absolute;
  top: 0;
  right: -32px;
  width: 20px;
  height: 100%;
  margin: auto;
  background-repeat: no-repeat;
  background-position: center;
}

.input-icon.icon-invalid, .sd-form-row-invalid .icon-invalid.custom-control::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='%23D9534F' fill-rule='nonzero' d='M10 8.586l5.293-5.293a1 1 0 0 1 1.414 1.414L11.414 10l5.293 5.293a1 1 0 0 1-1.414 1.414L10 11.414l-5.293 5.293a1 1 0 1 1-1.414-1.414L8.586 10 3.293 4.707a1 1 0 1 1 1.414-1.414L10 8.586z'/%3E%3C/svg%3E%0A");
}

.input-icon.icon-loading, .sd-form-row-invalid .icon-loading.custom-control::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' %3E%3Cpath fill='%23D0E9F4' d='M20 35c-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15zm0-2c7.18 0 13-5.82 13-13S27.18 7 20 7 7 12.82 7 20s5.82 13 13 13z'/%3E%3Cpath fill='%231493C8' d='M29.192 10.808A12.96 12.96 0 0 0 20 7V5c4.142 0 7.892 1.679 10.607 4.393l-1.415 1.415z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.7s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
}

.input-icon.icon-valid, .sd-form-row-invalid .icon-valid.custom-control::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%231abd5e' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 9.5L7.5 15 18 4.5'/%3E%3C/svg%3E%0A");
}

.input-icon.icon-search, .sd-form-row-invalid .icon-search.custom-control::after {
  right: 12px;
  background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="24/search"><path id="Ellipse 29" d="M16.5 9.5C16.5 13.366 13.366 16.5 9.5 16.5C5.63401 16.5 2.5 13.366 2.5 9.5C2.5 5.63401 5.63401 2.5 9.5 2.5C13.366 2.5 16.5 5.63401 16.5 9.5Z" stroke="%23627881" stroke-linejoin="round"/><path id="Vector 47" d="M21 21L14.5 14.5" stroke="%23364247" stroke-linejoin="round"/></g></svg>');
  z-index: 701;
}

.input-icon.icon-clear, .sd-form-row-invalid .icon-clear.custom-control::after {
  right: 12px;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='%23364247' fill-rule='nonzero' d='M8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854a.5.5 0 0 1 .708-.708L8 7.293z'/%3E%3C/svg%3E%0A");
}

.background-icon {
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 24px;
}

.background-icon.icon-search {
  background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="24/search"><path id="Ellipse 29" d="M16.5 9.5C16.5 13.366 13.366 16.5 9.5 16.5C5.63401 16.5 2.5 13.366 2.5 9.5C2.5 5.63401 5.63401 2.5 9.5 2.5C13.366 2.5 16.5 5.63401 16.5 9.5Z" stroke="%23627881" stroke-linejoin="round"/><path id="Vector 47" d="M21 21L14.5 14.5" stroke="%23364247" stroke-linejoin="round"/></g></svg>');
}

.sd-form-row-invalid .custom-control-label,
.sd-form-row-invalid label {
  color: #d9534f;
}

.sd-form-row-invalid .form-control,
.sd-form-row-invalid button,
.sd-form-row-invalid select {
  border-color: #d9534f;
}

.sd-form-row-invalid .btn-input-group {
  border-left-color: #e6ebec;
}

.sd-form-row-valid .form-control,
.sd-form-row-valid label,
.sd-form-row-valid button,
.sd-form-row-valid select {
  border-color: #1abd5e;
}

.sd-form-row-valid .btn-input-group {
  border-left-color: #e6ebec;
}

.form-help-text {
  font-size: 0.875rem;
  color: #81969f;
}

.wizard-homedeal {
  color: #364247;
}

.wizard-homedeal .form-wizard .progress {
  margin-bottom: 25px;
}

.wizard-homedeal .form-wizard .progress .progress-bar {
  background-color: #337ab7;
}

.wizard-homedeal .form-wizard fieldset .form-heading {
  font-size: 26px;
  line-height: 32px;
  margin-bottom: 20px;
}

.wizard-homedeal .form-wizard fieldset .form-group > label {
  margin-bottom: 8px;
  line-height: 20px;
  color: #627881;
}

.wizard-homedeal .form-wizard fieldset .form-group.hide-placeholder .form-control::-webkit-input-placeholder {
  color: #fff;
}

.wizard-homedeal .form-wizard fieldset .form-group.hide-placeholder .form-control:-moz-placeholder {
  color: #fff;
}

.wizard-homedeal .form-wizard fieldset .form-group.hide-placeholder .form-control::-moz-placeholder {
  color: #fff;
}

.wizard-homedeal .form-wizard fieldset .form-group.hide-placeholder .form-control:-ms-input-placeholder {
  color: #fff;
}

input[type="date"] {
  height: calc(1.5em + 1.125rem + 2px);
}

.radio-block {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 12px 16px;
  overflow: hidden;
  font-family: "SofiaPro";
  cursor: pointer;
  border: 2px solid #e6ebec;
  border-radius: 0.25rem;
}

.radio-block .title {
  font-weight: 700;
}

.radio-block p {
  color: #627881;
}

.radio-block .footer {
  position: absolute;
  right: 4px;
  bottom: 0;
  left: 4px;
  padding-top: .125rem;
  padding-left: 12px;
  color: #627881;
  background-color: #e6ebec;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

@media (min-width: 992px) {
  .radio-block .footer {
    right: 0;
    bottom: -7px;
    left: 0;
  }
}

.radio-block.selected, .radio-block:hover {
  border-color: #1abd5e;
}

.radio-block.selected .footer, .radio-block:hover .footer {
  color: #fff;
  background-color: #1abd5e;
}

.radio-block.selected .title {
  color: #364247;
}

.radio-block.selected p {
  font-weight: 700;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.card.card-faq, .post-card-container .post-card-wrapper .card-faq.post-card {
  border-radius: 0;
}

.card.card-faq .card-header, .post-card-container .post-card-wrapper .card-faq.post-card .card-header {
  border-radius: 0;
}

.card.card-faq .card-body, .post-card-container .post-card-wrapper .card-faq.post-card .card-body {
  box-shadow: none;
}

.page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.page-footer {
  z-index: 5;
  width: 100%;
  padding: 1rem 0;
  margin-top: 3rem;
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.16);
}

html,
body {
  height: 100%;
  font-size: 1rem;
  background-color: #fcfcfc;
}

.loading-mask,
.login-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: .95;
}

.loading-mask .content,
.login-mask .content {
  position: relative;
  top: 50%;
  text-align: center;
}

.loading-mask .content h1,
.login-mask .content h1 {
  color: #627881;
}

.loading-mask .content img,
.login-mask .content img {
  margin-bottom: 8px;
}

.content-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.indent-level-1 {
  margin-left: 0;
}

.indent-level-2 {
  margin-left: 0rem;
}

.indent-level-3 {
  margin-left: 1.25rem;
}

.indent-level-4 {
  margin-left: 2.5rem;
}

.indent-level-5 {
  margin-left: 3.75rem;
}

.indent-level-6 {
  margin-left: 5rem;
}

.angular-google-map-container {
  height: 400px;
}

.notice-container {
  position: fixed;
  top: 10%;
  right: 0;
  left: 0;
  z-index: 1200;
}

.notice-container .notice {
  position: relative;
  display: inline-block;
  max-width: 90%;
  padding: 1.25em 1.5em;
  margin: 0 auto;
  color: #1abd5e;
  background-color: #d1f2df;
  border-color: #d1f2df;
  border-radius: 0.25rem;
}

@media (min-width: 576px) {
  .notice-container .notice {
    padding: 1.25rem 3.5rem;
  }
}

.scrollable {
  max-height: 10rem;
  overflow-y: auto;
}

.box-shadow {
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
}

.box-shadow-bottom {
  box-shadow: inset 0 -1px 0 0 #e6ebec;
}

.box-shadow-bottom-right-lg {
  box-shadow: 8px 8px 0 0 rgba(20, 48, 102, 0.03);
}

.d-sm-width-auto {
  width: 100%;
}

@media (min-width: 576px) {
  .d-sm-width-auto {
    width: auto;
  }
}

.prevent-vertical-growth {
  margin-top: -.5rem;
  margin-bottom: -.5rem;
}

@media (min-width: 576px) {
  .prevent-vertical-growth-sm {
    margin-top: -.5rem;
    margin-bottom: -.5rem;
  }
}

@media (min-width: 768px) {
  .prevent-vertical-growth-md {
    margin-top: -.5rem;
    margin-bottom: -.5rem;
  }
}

@media (min-width: 992px) {
  .prevent-vertical-growth-lg {
    margin-top: -.5rem;
    margin-bottom: -.5rem;
  }
}

@media (min-width: 1200px) {
  .prevent-vertical-growth-xl {
    margin-top: -.5rem;
    margin-bottom: -.5rem;
  }
}

.col-grow {
  flex: 1 1 auto;
}

@media screen and (max-width: 767px) {
  .col-xs-12 {
    width: 100%;
  }
}

.w-50 {
  width: 50%;
}

@media (min-width: 576px) {
  .w-50-sm {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .w-50-md {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .w-50-lg {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .w-50-xl {
    width: 50%;
  }
}

.input-group .ng-invalid:not(.ng-pristine) {
  border-color: #d9534f;
}

.input-group .ng-invalid:not(.ng-pristine) .btn {
  border-color: #d9534f;
}

.form-error * {
  font-size: 12px;
  line-height: 1.67;
  color: #d9534f;
}

.label-invalid {
  color: #d9534f;
}

.form-control-sm ~ .input-group-append > .input-group-text {
  font-size: 0.875rem;
  line-height: 1.428571;
}

.jumbotron {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  margin-top: 1.5rem;
  margin-bottom: .5rem;
}

.jumbotron .body {
  flex: 1 1 auto;
  flex-grow: 100;
  margin-bottom: .5rem;
}

@media (min-width: 768px) {
  .jumbotron .body {
    margin-bottom: 0;
  }
}

.jumbotron .button-group {
  padding-left: 1rem;
}

.jumbotron .title {
  flex: 2 100%;
  font-size: 1rem;
  font-weight: 700;
}

.jumbotron.success {
  background-color: #d1f2df;
}

.jumbotron.warning {
  background-color: #fce5da;
}

.jumbotron.primary {
  background-color: #d0e9f4;
}

.jumbotron.primary a {
  color: #1493c8;
}

.jumbotron.danger {
  background-color: #f7dddc;
}

@media (max-width: 767.98px) {
  .jumbotron {
    flex-flow: column;
  }
  .jumbotron .button-group {
    padding-left: 0;
  }
}

.power-link:not([href]):not([tabindex]) {
  color: #1abd5e;
  text-decoration: none;
}

.power-link:not([href]):not([tabindex]):hover, .power-link:not([href]):not([tabindex]):focus {
  color: #17aa55;
  text-decoration: underline;
}

.power-link:not([href]):not([tabindex]):focus {
  outline: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.list-group .list-group-item {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: .5rem 1rem;
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
}

.list-group .list-group-item:first-of-type {
  padding-top: 1rem;
}

.list-group .list-group-item:last-of-type {
  padding-bottom: 1rem;
}

.list-group .list-group-item.link-container {
  padding-top: 0;
  border: 0;
}

.list-group .list-group-item.link-container .panel-link {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-family: "SofiaPro";
  line-height: 1.42;
  color: #627881;
  cursor: pointer;
  border-bottom: 1px solid #e6ebec;
}

.list-group .list-group-item.link-container .panel-link::after {
  display: inline-block;
  width: 0.75rem;
  height: 0.75rem;
  margin-top: 3px;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23627881' fill-rule='nonzero' d='M3.645 1.284a.455.455 0 0 1 .71-.568l4 5a.455.455 0 0 1 0 .568l-4 5a.455.455 0 0 1-.71-.568L7.418 6 3.645 1.284z'/%3E%3C/svg%3E%0A") no-repeat;
}

.list-group .list-group-item.link-container .panel-link:hover::after {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23364247' fill-rule='nonzero' d='M3.645 1.284a.455.455 0 0 1 .71-.568l4 5a.455.455 0 0 1 0 .568l-4 5a.455.455 0 0 1-.71-.568L7.418 6 3.645 1.284z'/%3E%3C/svg%3E%0A") no-repeat;
}

.list-group.cards .list-group-item {
  flex-direction: column;
}

.list-group.smaller-text {
  list-style: none;
}

.list-group.smaller-text li {
  margin-bottom: 1rem;
  font-size: 0.875rem;
}

.list-group .card-header {
  padding: 0;
  font-size: 0.875rem;
  font-weight: 700;
  border: 0;
  box-shadow: none;
}

.list-group .card-body {
  padding: 0;
  font-size: 0.875rem;
}

.list-group.no-border .list-group-item {
  border: 0;
  box-shadow: none;
}

.list-group > button {
  font-size: 0.875rem;
  font-weight: 700;
  color: #627881;
}

.list-group > button:hover {
  color: #364247;
}

.list-bordered {
  margin-bottom: 0;
  list-style-type: none;
}

.list-bordered li {
  display: inline-block;
  padding: 0 10px;
}

.list-bordered li a {
  font-family: "SofiaPro";
  font-size: .8125rem;
  color: #627881;
}

.list-bordered li + li {
  border-left: 1px solid #d2dadd;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  background-color: transparent;
}

/**
Below is the timeline layout
*/
.timeline-vertical {
  padding-left: 47px;
  margin-top: 1rem;
  margin-right: 1rem;
}

.timeline-vertical::before {
  position: absolute;
  top: 6.1rem;
  bottom: 3.6rem;
  left: .6rem;
  width: .15rem;
  margin-left: 25px;
  content: " ";
  background-color: #a1d4e9;
}

.timeline-vertical .timeline-item .timeline-badge {
  top: 1rem;
  left: 0;
  float: left;
  width: 42px;
  height: 42px;
}

.timeline-vertical .timeline-item {
  width: auto;
  margin-bottom: 1rem;
  margin-left: -2rem;
  overflow: hidden;
}

.timeline-vertical .timeline-item .timeline-panel {
  margin-left: 1rem;
  font-size: 0.875rem;
  color: #364247;
}

.list-group.list-group-item.link-container {
  padding-top: .5rem;
  border: 0;
}

.list-group.list-group-item.link-container .panel-link {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: .5rem;
  padding-bottom: 1rem;
  font-family: "SofiaPro";
  line-height: 1.7;
  color: #627881;
  text-decoration: none;
  cursor: pointer;
  border-bottom: 1px solid #e6ebec;
}

.list-group.list-group-item.link-container .panel-link:hover {
  color: #364247;
}

.list-group.list-group-item.link-container .panel-link::after {
  display: inline-block;
  width: 0.75rem;
  height: 0.75rem;
  margin: .3rem 0;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23627881' fill-rule='nonzero' d='M3.645 1.284a.455.455 0 0 1 .71-.568l4 5a.455.455 0 0 1 0 .568l-4 5a.455.455 0 0 1-.71-.568L7.418 6 3.645 1.284z'/%3E%3C/svg%3E%0A") no-repeat;
}

.list-group.list-group-item.link-container .panel-link:hover::after {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23364247' fill-rule='nonzero' d='M3.645 1.284a.455.455 0 0 1 .71-.568l4 5a.455.455 0 0 1 0 .568l-4 5a.455.455 0 0 1-.71-.568L7.418 6 3.645 1.284z'/%3E%3C/svg%3E%0A") no-repeat;
}

.list-group-check {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  list-style: none;
}

.list-group-check .list-group-check-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-left: calc(1rem + 1.25rem);
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  border: 0;
}

.list-group-check .list-group-check-item::before {
  position: absolute;
  top: calc((1rem * 1.5 - 1.25rem) / 2);
  left: 0;
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  content: "";
  border: 0;
}

@media (min-width: 768px) {
  .list-group-check .list-group-check-item {
    font-size: 1.125rem;
    line-height: 1.444444;
  }
  .list-group-check .list-group-check-item::before {
    top: calc((1.125rem * 1.444444 - 1.25rem) / 2);
  }
}

.list-group-check .list-group-check-item.list-group-check-item-primary::before {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%231abd5e' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 9.5L7.5 15 18 4.5'/%3E%3C/svg%3E%0A") no-repeat;
  background-size: 1.25rem 1.25rem;
}

.list-group-check .list-group-check-item.list-group-check-item-accent::before {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%231493c8' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 9.5L7.5 15 18 4.5'/%3E%3C/svg%3E%0A") no-repeat;
  background-size: 1.25rem 1.25rem;
}

.list-group-check .list-group-check-item.list-group-check-item-gray-500::before {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23a4b3ba' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 9.5L7.5 15 18 4.5'/%3E%3C/svg%3E%0A") no-repeat;
  background-size: 1.25rem 1.25rem;
}

.list-group-check .list-group-check-item:not(:last-of-type) {
  margin-bottom: 0.25rem;
}

.list-group-check .list-group-check-item.list-group-check-item-sm {
  padding-left: 1.5rem;
  margin-bottom: .75rem;
  font-size: 0.875rem;
}

.list-group-check .list-group-check-item.list-group-check-item-sm::before {
  background-size: .75rem .75rem;
}

.loader {
  width: 2.5rem;
  height: 2.5rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' %3E%3Cpath fill='%23D0E9F4' d='M20 35c-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15zm0-2c7.18 0 13-5.82 13-13S27.18 7 20 7 7 12.82 7 20s5.82 13 13 13z'/%3E%3Cpath fill='%231493C8' d='M29.192 10.808A12.96 12.96 0 0 0 20 7V5c4.142 0 7.892 1.679 10.607 4.393l-1.415 1.415z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.7s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
}

.iframe-loading {
  height: 100%;
  min-height: 400px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' %3E%3Cpath fill='%23D0E9F4' d='M20 35c-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15zm0-2c7.18 0 13-5.82 13-13S27.18 7 20 7 7 12.82 7 20s5.82 13 13 13z'/%3E%3Cpath fill='%231493C8' d='M29.192 10.808A12.96 12.96 0 0 0 20 7V5c4.142 0 7.892 1.679 10.607 4.393l-1.415 1.415z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.7s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E") center center no-repeat;
  background-size: 2.5rem 2.5rem;
}

.btn-loading {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn-loading::after {
  display: block;
  width: calc(1rem * 1.5);
  height: calc(1rem * 1.5);
  margin-left: 0.5rem;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' %3E%3Cpath fill='%23ffffff50' d='M20,35 C11.7157288,35 5,28.2842712 5,20 C5,11.7157288 11.7157288,5 20,5 C28.2842712,5 35,11.7157288 35,20 C35,28.2842712 28.2842712,35 20,35 Z M20,33 C27.1797017,33 33,27.1797017 33,20 C33,12.8202983 27.1797017,7 20,7 C12.8202983,7 7,12.8202983 7,20 C7,27.1797017 12.8202983,33 20,33 Z'/%3E%3Cpath fill='%23ffffff' d='M29.1923882,10.8076118 C26.8398509,8.45507456 23.5898509,7 20,7 L20,5 C24.1421356,5 27.8921356,6.67893219 30.6066017,9.39339828 L29.1923882,10.8076118 Z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.7s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E") no-repeat;
}

.modal-content {
  border: 0;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.16);
}

.modal-body {
  box-shadow: inset 0 -1px 0 0 #e6ebec;
}

.modal-body .top-description {
  margin-bottom: 17px;
  font-size: 0.875rem;
  line-height: 1.43;
  color: #627881;
}

.modal-body .top-label {
  font-weight: 700;
  color: #627881;
}

.modal-body .form-group:not(.row) {
  margin-bottom: 0;
}

.modal-body .form-row,
.modal-body .sd-form-row {
  padding-top: 1.4375rem;
}

.modal-body .form-row:first-child,
.modal-body .sd-form-row:first-child {
  padding-top: .625rem;
}

.modal-body .form-row:last-child,
.modal-body .sd-form-row:last-child {
  padding-bottom: .625rem;
}

.modal-title {
  font-weight: 700;
  line-height: 1.63;
}

.fade.in {
  opacity: 1;
}

.modal.in .modal-dialog {
  transform: translate(0, 0);
}

.modal-backdrop.in {
  opacity: .7;
}

.modal-header {
  align-items: center;
  padding: 17px 20px 19px;
  border: 0;
  box-shadow: inset 0 -1px 0 0 #e6ebec;
}

.modal-footer {
  padding: .875rem 1.25rem;
  border: 0;
}

.modal-footer .btn {
  margin-right: 0;
  margin-left: .625rem;
}

@media (max-width: 991.98px) {
  .modal-footer .btn.btn-block {
    margin-left: 0;
  }
}

.modal-footer .btn-container-vertical-md-horizontal {
  display: flex;
  flex-direction: column;
}

.modal-footer .btn-container-vertical-md-horizontal .btn {
  width: 100%;
  margin-bottom: .5rem;
  margin-left: 0;
}

@media (min-width: 768px) {
  .modal-footer .btn-container-vertical-md-horizontal {
    flex-direction: row;
    float: right;
  }
  .modal-footer .btn-container-vertical-md-horizontal .btn {
    width: auto;
    margin-bottom: 0;
    margin-left: .625rem;
  }
}

.google-maps-container .angular-google-map-container {
  height: 400px;
}

.photo-select {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
  font-size: 1rem;
  font-weight: 700;
  color: #627881;
  cursor: pointer;
  background-color: #d2dadd;
}

.photo-select button {
  pointer-events: none;
  background-color: transparent;
  border: 0;
}

.photo-edit {
  display: flex;
  align-items: flex-end;
  width: 120px;
  height: 120px;
  background-color: #d2dadd;
}

.photo-edit label {
  width: 100%;
  padding: 5px;
  margin: 0;
  color: #fff;
  background-color: rgba(68, 91, 105, 0.5);
}

svg {
  position: relative;
}

.carousel .delete-button {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 30px;
  height: 30px;
  padding: 3px 5px;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #d2dadd;
  border-radius: 30px;
}

.carousel .delete-button svg {
  color: #1abd5e;
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus,
.carousel-control-prev,
.carousel-control-next {
  opacity: 1;
}

.carousel-caption {
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem 1.25rem;
  background-color: rgba(68, 91, 105, 0.5);
}

.date-picker-icon {
  padding: .25rem 0 0 .25rem;
  border: 1px solid #d2dadd;
  border-left: none;
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.icon-md {
  float: left;
  width: 20px;
  margin-right: .5rem;
}

.image-container {
  max-width: 100%;
  max-height: 168px;
}

.btn-select-photo {
  width: 100%;
  height: 340px;
  border: 0;
}

.selected-photo {
  max-width: 100%;
  max-height: 430px;
}

.winback-benefit {
  width: 74px;
  height: 74px;
}

.logo-container {
  position: relative;
  width: 250px;
  height: 120px;
  overflow: hidden;
  border: solid 1px #e6ebec;
}

.companyprofile-logo-container {
  position: relative;
  height: 168px;
  overflow: hidden;
  border: solid 1px #e6ebec;
}

.logo-container-small {
  width: 70px;
  height: 48px;
  padding: 2px;
  background-color: #fcfcfc;
  border: solid 1px #e6ebec;
}

.logo-container-small .image-container {
  max-width: 100%;
  max-height: 100%;
}

.arrow-light > * {
  color: #d0e9f4;
}

[class*="icon-zoom-"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.icon-zoom-out {
  background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: sketchtool 48.1 (47250) - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3ECB9D919C-952C-4EA9-8B74-FEA591DC78DA%3C/title%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Icons-/-zoom-out' fill='%238CA5B5' fill-rule='nonzero'%3E%3Cpath d='M10,17.5 C14.1421356,17.5 17.5,14.1421356 17.5,10 C17.5,5.85786438 14.1421356,2.5 10,2.5 C5.85786438,2.5 2.5,5.85786438 2.5,10 C2.5,14.1421356 5.85786438,17.5 10,17.5 Z M10,19 C5.02943725,19 1,14.9705627 1,10 C1,5.02943725 5.02943725,1 10,1 C14.9705627,1 19,5.02943725 19,10 C19,14.9705627 14.9705627,19 10,19 Z M5.75,9.25 L14.25,9.25 C14.6642136,9.25 15,9.58578644 15,10 L15,10 C15,10.4142136 14.6642136,10.75 14.25,10.75 L5.75,10.75 C5.33578644,10.75 5,10.4142136 5,10 L5,10 C5,9.58578644 5.33578644,9.25 5.75,9.25 Z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.icon-zoom-in {
  background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: sketchtool 48.1 (47250) - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3EFF6880C1-6466-408A-B289-36DC533B16EC%3C/title%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Icons-/-zoom-out' fill='%238CA5B5' fill-rule='nonzero'%3E%3Cpath d='M10,17.5 C14.1421356,17.5 17.5,14.1421356 17.5,10 C17.5,5.85786438 14.1421356,2.5 10,2.5 C5.85786438,2.5 2.5,5.85786438 2.5,10 C2.5,14.1421356 5.85786438,17.5 10,17.5 Z M10,19 C5.02943725,19 1,14.9705627 1,10 C1,5.02943725 5.02943725,1 10,1 C14.9705627,1 19,5.02943725 19,10 C19,14.9705627 14.9705627,19 10,19 Z M5.75,9.25 L14.25,9.25 C14.6642136,9.25 15,9.58578644 15,10 L15,10 C15,10.4142136 14.6642136,10.75 14.25,10.75 L5.75,10.75 C5.33578644,10.75 5,10.4142136 5,10 L5,10 C5,9.58578644 5.33578644,9.25 5.75,9.25 Z M10.75,5.75 L10.75,14.25 C10.75,14.6642136 10.4142136,15 10,15 L10,15 C9.58578644,15 9.25,14.6642136 9.25,14.25 L9.25,5.75 C9.25,5.33578644 9.58578644,5 10,5 L10,5 C10.4142136,5 10.75,5.33578644 10.75,5.75 Z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.cr-slider-wrap {
  height: 59px;
  background-color: #e6ebec;
}

.cr-slider-wrap .cr-slider {
  width: 70%;
}

.cr-slider-wrap .cr-slider::-webkit-slider-thumb {
  background-color: #1abd5e;
}

.cr-slider-wrap .cr-slider::-webkit-slider-runnable-track {
  background: #d2dadd;
}

.cr-slider-wrap > * {
  margin: auto 5px;
}

.croppie-container {
  margin: 20px 0;
}

.croppie-container .cr-viewport {
  border-color: #364247;
  box-shadow: 0 0 2000px 2000px rgba(54, 66, 71, 0.5);
}

.pencil {
  border-bottom: 2px solid transparent;
  fill: #5f3;
}

.pencil:hover {
  cursor: pointer;
  border-bottom: 2px solid currentColor;
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.close-x {
  margin-right: -38px;
}

.logo-text {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  color: #fff;
  background-color: rgba(68, 91, 105, 0.5);
}

.svg-container svg {
  width: 30px;
  height: 40px;
}

.payment-icon-mastercard {
  width: 40px;
  height: 24px;
  color: transparent;
  background-position: 0 0 !important;
}

.payment-icon-paypal {
  width: 76px;
  height: 24px;
  color: transparent;
  background-position: -160px 0 !important;
}

.payment-icon-secure {
  width: 14px;
  height: 18px;
  margin-bottom: -4px;
  color: transparent;
  background-position: -286px 0 !important;
}

.payment-icon-visa {
  width: 65px;
  height: 24px;
  color: transparent;
  background-position: -72px 0 !important;
}

.payment-icon-amex {
  width: 38px;
  height: 38px;
  color: transparent;
  background-position: -58px -26px !important;
}

.payment-icon-directdebit_uk {
  width: 69px;
  height: 24px;
  color: transparent;
  background-position: -98px -26px !important;
}

.payment-icon-directdebit_sepa {
  width: 70px;
  height: 24px;
  color: transparent;
  background-position: -175px -29px !important;
}

.payment-icon-adyen_credit_card {
  width: 34px;
  height: 24px;
  color: transparent;
  background-position: -242px 0 !important;
}

.payment-icon-adyen {
  width: 55px;
  height: 18px;
  color: transparent;
  background-position: -245px -29px !important;
}

.nav-link {
  font-family: "SofiaPro";
}

.navbar, .page-navigation-navbar, .b2b-navbar, .postcode-hero-navigation-navbar {
  z-index: 800;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.16);
}

@media (min-width: 992px) {
  .navbar .navbar-collapse.collapsing, .page-navigation-navbar .navbar-collapse.collapsing, .b2b-navbar .navbar-collapse.collapsing, .postcode-hero-navigation-navbar .navbar-collapse.collapsing {
    height: auto !important;
    transition: none;
  }
}

.navbar .dropdown-toggle-custom::after, .page-navigation-navbar .dropdown-toggle-custom::after, .b2b-navbar .dropdown-toggle-custom::after, .postcode-hero-navigation-navbar .dropdown-toggle-custom::after {
  top: 1.8rem;
}

@media (max-width: 991.98px) {
  .navbar .dropdown-toggle-custom::after, .page-navigation-navbar .dropdown-toggle-custom::after, .b2b-navbar .dropdown-toggle-custom::after, .postcode-hero-navigation-navbar .dropdown-toggle-custom::after {
    top: 1rem;
  }
}

@media (min-width: 992px) {
  .navbar .navbar-nav .dropdown-menu-right, .page-navigation-navbar .navbar-nav .dropdown-menu-right, .b2b-navbar .navbar-nav .dropdown-menu-right, .postcode-hero-navigation-navbar .navbar-nav .dropdown-menu-right {
    right: 0.7rem;
  }
  .navbar .last + .dropdown-menu-right, .page-navigation-navbar .last + .dropdown-menu-right, .b2b-navbar .last + .dropdown-menu-right, .postcode-hero-navigation-navbar .last + .dropdown-menu-right {
    right: 0;
  }
}

.navbar .dropdown-menu, .page-navigation-navbar .dropdown-menu, .b2b-navbar .dropdown-menu, .postcode-hero-navigation-navbar .dropdown-menu {
  top: 3.5rem;
}

.navbar .navbar-brand, .page-navigation-navbar .navbar-brand, .b2b-navbar .navbar-brand, .postcode-hero-navigation-navbar .navbar-brand {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #1abd5e;
}

.navbar .navbar-brand svg, .page-navigation-navbar .navbar-brand svg, .b2b-navbar .navbar-brand svg, .postcode-hero-navigation-navbar .navbar-brand svg {
  max-width: 15rem;
  height: 2.25rem;
}

.navbar .navbar-brand:hover, .page-navigation-navbar .navbar-brand:hover, .b2b-navbar .navbar-brand:hover, .postcode-hero-navigation-navbar .navbar-brand:hover {
  color: #17aa55;
}

.navbar .divider, .page-navigation-navbar .divider, .b2b-navbar .divider, .postcode-hero-navigation-navbar .divider {
  margin: 1.2rem;
  border-left: 1px solid #d2dadd;
}

.navbar .dropdown-item, .page-navigation-navbar .dropdown-item, .b2b-navbar .dropdown-item, .postcode-hero-navigation-navbar .dropdown-item {
  font-size: 0.875rem;
  color: #364247;
}

@media (max-width: 991.98px) {
  .navbar, .page-navigation-navbar, .b2b-navbar, .postcode-hero-navigation-navbar {
    padding-left: 1rem;
  }
  .navbar .navbar-nav, .page-navigation-navbar .navbar-nav, .b2b-navbar .navbar-nav, .postcode-hero-navigation-navbar .navbar-nav {
    margin-bottom: 1.5rem;
  }
  .navbar .divider, .page-navigation-navbar .divider, .b2b-navbar .divider, .postcode-hero-navigation-navbar .divider {
    width: 100%;
    height: 0;
    margin: 0;
    border-top: 1px solid #d2dadd;
    border-left: none;
  }
  .navbar .dropdown-menu, .page-navigation-navbar .dropdown-menu, .b2b-navbar .dropdown-menu, .postcode-hero-navigation-navbar .dropdown-menu {
    box-shadow: none;
  }
  .navbar .dropdown-item, .page-navigation-navbar .dropdown-item, .b2b-navbar .dropdown-item, .postcode-hero-navigation-navbar .dropdown-item {
    color: #627881;
    box-shadow: none;
  }
}

.navbar.navbar-transparant, .navbar-transparant.page-navigation-navbar, .navbar-transparant.b2b-navbar, .navbar-transparant.postcode-hero-navigation-navbar {
  background: transparent;
}

@media (min-width: 992px) {
  .modal-open {
    padding-right: 17px;
  }
  .modal-open .absolute-menu > div {
    padding-right: 17px;
  }
}

.absolute-submenu {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
  padding-top: 64px;
  overflow: auto;
  background-color: #fff;
}

@media (min-width: 992px) {
  .absolute-submenu {
    padding-top: 72px;
  }
}

.navbar-light .nav-item:hover .nav-link {
  box-shadow: inset 0 -4px 0 0 #d2dadd;
}

.navbar-light .nav-item.active .nav-link {
  box-shadow: inset 0 -4px 0 0 #1abd5e;
}

.navbar-toggler {
  display: flex;
  align-items: center;
  padding: 0;
  font-size: 1rem;
  border: 0;
}

.navbar-toggler .navbar-toggler-icon {
  width: 1.75rem;
  height: 1.5rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23364247' fill-rule='nonzero' d='M2.5 6a.5.5 0 1 1 0-1h19a.5.5 0 1 1 0 1h-19zm0 6.5a.5.5 0 0 1 0-1h19a.5.5 0 1 1 0 1h-19zm0 6.5a.5.5 0 1 1 0-1h19a.5.5 0 1 1 0 1h-19z'/%3E%3C/svg%3E%0A");
}

.navbar-height, .homedeal-navbar {
  height: 64px;
}

@media (min-width: 992px) {
  .navbar-height, .homedeal-navbar {
    height: 72px;
  }
}

.navbar-nav [class="nav-item"]:first-of-type {
  margin-left: 0;
}

.navbar-nav .nav-item .nav-link {
  padding: 1.7rem .3rem;
  margin: 0 .7rem;
  font-weight: 400;
  cursor: pointer;
}

.navbar-nav .nav-item .nav-link.last {
  margin-right: 0;
}

.navbar-nav .nav-item .nav-link-small {
  padding-top: 1rem;
}

@media (max-width: 991.98px) {
  .navbar-nav .nav-item:hover .nav-link,
  .navbar-nav .nav-item.active .nav-link {
    box-shadow: none;
  }
  .navbar-nav .nav-item .nav-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    margin: 0;
  }
}

.account-switcher {
  min-width: 16rem;
}

@media (max-width: 991.98px) {
  .account-switcher {
    min-width: 100%;
  }
}

.account-switcher .btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
  padding-left: 1rem;
  text-align: left;
}

.account-switcher .dropdown-item {
  padding: 0 1.25rem;
}

.dropdown-menu {
  min-width: 100%;
  padding: 0;
  margin-top: -2px;
}

.dropdown-item {
  border: 0;
  box-shadow: inset 0 -1px 0 0 #e6ebec;
}

.dropdown-item .card, .dropdown-item .post-card-container .post-card-wrapper .post-card, .post-card-container .post-card-wrapper .dropdown-item .post-card {
  padding: .875rem 0 1.25rem;
  margin: 0;
  background-color: transparent;
  border: 0;
  box-shadow: none;
}

.dropdown-item .card-header {
  padding: 0;
  background-color: transparent;
  border: 0;
  box-shadow: none;
}

.dropdown-item .card-body {
  padding: 0;
  background-color: transparent;
}

.dropdown-item a {
  color: #364247;
  text-decoration: none;
}

.dropdown-item.active, .dropdown-item:active {
  color: #364247;
  background-color: #eff2f3;
}

.second-navbar {
  padding: 10px 0;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
}

.search-icon {
  width: 24px;
  height: 24px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23627881' fill-rule='nonzero' d='M16 15.292l5.354 5.354a.5.5 0 0 1-.708.708l-5.354-5.355a8 8 0 1 1 .707-.707zM10 17a7 7 0 1 0 0-14 7 7 0 0 0 0 14z'/%3E%3C/svg%3E") no-repeat;
}

.search-icon:hover {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%231abd5e' fill-rule='nonzero' d='M16 15.292l5.354 5.354a.5.5 0 0 1-.708.708l-5.354-5.355a8 8 0 1 1 .707-.707zM10 17a7 7 0 1 0 0-14 7 7 0 0 0 0 14z'/%3E%3C/svg%3E") no-repeat;
}

.search-icon:active {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%2317aa55' fill-rule='nonzero' d='M16 15.292l5.354 5.354a.5.5 0 0 1-.708.708l-5.354-5.355a8 8 0 1 1 .707-.707zM10 17a7 7 0 1 0 0-14 7 7 0 0 0 0 14z'/%3E%3C/svg%3E") no-repeat;
}

.absolute-menu {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 900;
  width: 100%;
  max-height: 100vh;
  overflow: auto;
}

@media (min-width: 992px) {
  .absolute-menu {
    overflow: visible;
    background-color: transparent;
  }
}

.absolute-menu .header-submenu {
  width: 100%;
  overflow: hidden;
}

.absolute-menu .header-submenu .header-submenu-margins {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

@media (min-width: 992px) {
  .absolute-menu .header-submenu .header-submenu-margins {
    margin-top: 3rem;
    margin-bottom: 4.5rem;
  }
}

.absolute-menu .nav-link:not(.btn) {
  font-family: "SofiaPro";
  color: #627881;
  -webkit-appearance: none;
}

.absolute-menu .nav-link:not(.btn):hover {
  color: #1abd5e;
}

.absolute-menu .nav-link:not(.btn):active {
  color: #17aa55;
}

.white-header {
  z-index: 800;
  height: 64px;
  background: #fff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.16);
}

@media (min-width: 992px) {
  .white-header {
    height: 72px;
  }
}

.pagination {
  justify-content: center;
  font-family: "SofiaPro";
  font-size: 0.875rem;
}

.page-item .page-link {
  min-width: 2.125rem;
  text-align: center;
  border: 1.5px solid transparent;
}

.page-item .page-link .arrow-dropdown-toggle::after {
  vertical-align: middle;
}

.page-item .page-text {
  padding: 0.375rem 0.6rem;
  margin-left: 0;
}

.page-item:hover .page-link {
  background-color: #e6ebec;
  border-color: #e6ebec;
}

.page-item:first-child:disabled, .page-item:first-child.disabled, .page-item:last-child:disabled, .page-item:last-child.disabled {
  opacity: .65;
}

.page-item:first-child:not(.disabled):hover .page-link, .page-item:last-child:not(.disabled):hover .page-link {
  border-color: #a4b3ba;
}

.page-item:first-child .page-link, .page-item:last-child .page-link {
  color: #1abd5e;
  background-color: transparent;
  border-color: #d2dadd;
}

.page-item:first-child .page-link {
  margin-right: 5px;
}

.page-item:last-child .page-link {
  margin-left: 5px;
}

.page-item.active.disabled:not(:first-child):not(:last-child) .page-link {
  font-weight: 700;
  color: #1abd5e;
  background-color: #d1f2df;
  border-color: transparent;
}

.navigation-panel {
  margin-bottom: 5rem;
}

.choice-container {
  display: flex;
  flex-direction: column;
}

.choice-container .choice-panel {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  border: 1px solid #e6ebec;
  border-radius: 0.25rem;
}

.choice-container .choice-panel + .choice-panel {
  margin-top: 1rem;
  margin-left: 0;
}

@media (min-width: 768px) {
  .choice-container {
    flex-direction: row;
  }
  .choice-container .choice-panel + .choice-panel {
    margin-top: 0;
    margin-left: 2rem;
  }
}

.choice-header {
  padding: 20px 20px 0;
}

@media (min-width: 768px) {
  .choice-header {
    padding: 32px 32px 0;
  }
}

.choice-header h3 {
  margin-bottom: .25rem;
}

.choice-header p {
  margin-bottom: 0;
}

.choice-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 20px;
  margin-top: 12px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .choice-body {
    padding: 0 2rem;
    margin-bottom: 32px;
  }
}

.choice-body label {
  margin-bottom: 0;
  font-size: 0.875rem;
}

.choice-body p {
  font-size: 0.875rem;
}

.choice-footer {
  padding: 20px;
}

@media (min-width: 768px) {
  .choice-footer {
    padding: 2rem;
  }
}

.ribbon {
  position: absolute;
  top: 25px;
  right: -25px;
  width: 120px;
  font-family: "SofiaPro";
  color: #fff;
  text-align: center;
  background-color: #ef7b45;
  transform: rotate(45deg);
}

.ribbon-wrapper {
  position: absolute;
  top: -1px;
  right: -1px;
  width: 100px;
  height: 100px;
  overflow: hidden;
}

.sticky-button-panel {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 6;
  width: 100%;
  padding: 8px 16px;
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.08), 0 0 14px 0 rgba(0, 0, 0, 0.16);
}

.pre-wrap {
  white-space: pre-wrap;
}

.bg-gray-light {
  background-color: #e6ebec;
}

.bg-gray-light .rating {
  color: #364247;
}

.bg-primary-light {
  background-color: #d1f2df;
}

.bg-primary-light .rating {
  color: #1abd5e;
}

.bg-warning-light {
  background-color: #fce5da;
}

.bg-warning-light .rating {
  color: #ef7b45;
}

.bg-danger-light {
  background-color: #f7dddc;
}

.bg-danger-light .rating {
  color: #d9534f;
}

.rating-label {
  display: inline-block;
  padding: 2px 10px;
  font-family: "SofiaPro";
  font-size: 1rem;
  font-weight: 400;
  color: #627881;
  border-radius: 2px;
}

.rating-star {
  display: inline-block;
  color: #d2dadd;
}

.rating-star.star-sm {
  width: 1.25rem;
  height: 1.25rem;
  margin: 2px;
}

.rating-star.star-sm:first-child {
  margin-left: 0;
}

.rating-star.star-sm:last-child {
  margin-right: 0;
}

.rating-star.star-md {
  width: 1.5rem;
  height: 1.5rem;
  margin: 4px 2px;
}

.rating-star.star-md:first-child {
  margin-left: 0;
}

.rating-star.star-md:last-child {
  margin-right: 0;
}

.company-rating-unknown.rating-box {
  background-color: #e6ebec;
}

.company-rating-unknown .rating {
  color: #627881;
}

.company-rating-very-bad.rating-box {
  background-color: #f7dddc;
}

.company-rating-very-bad .rating {
  font-weight: 700;
  color: #d9534f;
}

.company-rating-bad.rating-box {
  background-color: #fce5da;
}

.company-rating-bad .rating {
  font-weight: 700;
  color: #ef7b45;
}

.company-rating-medium.rating-box {
  background-color: #d1f2df;
}

.company-rating-medium .rating {
  font-weight: 700;
  color: #1abd5e;
}

.company-rating-good.rating-box {
  background-color: #d1f2df;
}

.company-rating-good .rating {
  font-weight: 700;
  color: #1abd5e;
}

.company-rating-very-good.rating-box {
  background-color: #d1f2df;
}

.company-rating-very-good .rating {
  font-weight: 700;
  color: #1abd5e;
}

.rating-summary {
  padding: 0;
  border-top: 1px solid #e6ebec;
  border-bottom: 1px solid #e6ebec;
}

.review-summary {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.review-summary .review-summary-label {
  margin: 0 1rem 0 0;
}

.review-summary .review-count {
  padding-left: .625rem;
}

.review-reply {
  background-color: #eff2f3;
  border-radius: 0.25rem;
}

button:focus,
a:focus {
  outline: 0;
}

.min-h-0 {
  min-height: 0;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: #e6ebec;
}

.card-body > .table {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.table {
  margin-bottom: 0;
  overflow: hidden;
  color: #627881;
}

.table tbody > .disabled {
  background-color: #eff2f3;
  opacity: .5;
}

.table th {
  padding: .5rem 1rem;
  font-family: "SofiaPro";
  font-size: 0.875rem;
}

.table td {
  height: 3.325rem;
  padding: .5rem 1rem;
  font-size: 0.875rem;
  vertical-align: middle;
}

.table .thead-light th {
  color: #627881;
  background-color: #e6ebec;
  border: 0;
}

.table .no-rows td {
  padding: 7rem 1rem;
  font-size: 1rem;
  color: #81969f;
  text-align: center;
  background-color: #eff2f3;
}

.table.hoverable tr:hover {
  background-color: #eff2f3;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  color: #fff;
}

.table-card .card-body {
  padding: 0;
}

.flex-table-header, .flex-table-data {
  padding-top: .875rem;
  padding-bottom: .875rem;
  color: #627881;
  vertical-align: top;
}

.shadow-bottom.flex-table-header, .shadow-bottom.flex-table-data {
  box-shadow: 0 1px #e6ebec;
}

.shadow-top.flex-table-header, .shadow-top.flex-table-data {
  box-shadow: 0 -1px 1px #e6ebec;
}

@media (min-width: 992px) {
  .shadow-lg-none.flex-table-header, .shadow-lg-none.flex-table-data {
    box-shadow: none;
  }
}

.flex-table-header {
  padding: .5rem 0;
  font-weight: 700;
  color: #627881;
  background-color: #e6ebec;
}

.nav-tabs .nav-link {
  font-weight: 700;
  color: #627881;
}

.nav-tabs .nav-link:not(:disabled):not(.disabled):hover {
  color: #627881;
}

h1,
.h1,
.postcode-hero-container .postcode-hero-title {
  margin-bottom: 2rem;
  line-height: 1.25;
}

@media (min-width: 768px) {
  h1,
  .h1,
  .postcode-hero-container .postcode-hero-title {
    margin-bottom: 3.5rem;
    font-size: 2.375rem;
    line-height: 1.263158;
  }
}

h2, .h2, .banner .banner-body .banner-title, .form-headline {
  margin-bottom: 1.5rem;
  line-height: 1.333333;
}

@media (min-width: 768px) {
  h2, .h2, .banner .banner-body .banner-title, .form-headline {
    margin-bottom: 2rem;
    font-size: 2rem;
    line-height: 1.25;
  }
}

h3, .h3 {
  margin-bottom: .5rem;
  line-height: 1.333333;
}

@media (min-width: 768px) {
  h3, .h3 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    line-height: 1.333333;
  }
}

h4, .h4 {
  margin-bottom: .5rem;
  line-height: 1.5;
}

@media (min-width: 768px) {
  h4, .h4 {
    margin-bottom: .5rem;
    font-size: 1.125rem;
    line-height: 1.444444;
  }
}

h5, .h5 {
  margin-bottom: 0;
  line-height: 1.5;
}

@media (min-width: 768px) {
  h5, .h5 {
    font-size: 1rem;
    line-height: 1.5;
  }
}

h6, .h6 {
  margin-bottom: 0;
  line-height: 1.25;
}

@media (min-width: 768px) {
  h6, .h6 {
    font-size: 0.875rem;
    line-height: 1.428571;
  }
}

a {
  font-family: "SofiaPro";
  cursor: pointer;
}

a:active {
  font-weight: 700;
}

p > a {
  font-family: "SofiaPro";
}

.link-secondary {
  font-family: "SofiaPro";
  color: #81969f;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

.link-secondary:hover {
  color: #81969f;
  text-decoration: underline;
}

.link-secondary:active {
  font-weight: 700;
}

.link-accent {
  font-family: "SofiaPro";
  color: #1493c8;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

.link-accent:hover {
  color: #1493c8;
  text-decoration: underline;
}

.link-accent:active {
  font-weight: 700;
}

p {
  font-size: 1rem;
  line-height: 1.5;
}

.p-small, .postcode-hero-container .postcode-hero-form-container .postcode-hero-text .no-obligation {
  font-size: 0.875rem;
  line-height: 1.428571;
}

.p-large, .entry-content > p:first-child, .banner .banner-body .banner-text {
  font-size: 1rem;
  line-height: 1.5;
}

@media (min-width: 768px) {
  .p-large, .entry-content > p:first-child, .banner .banner-body .banner-text {
    font-size: 1.125rem;
    line-height: 1.444444;
  }
}

.smaller, .radio-block p, .review-summary .review-count {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.font-size-md, .custom-control.control-checkbox-btn label {
  font-size: 1rem !important;
}

.font-size-lg {
  font-size: 1.125rem;
}

.font-size-xs {
  font-size: 0.625rem;
}

.lead {
  font-size: 1.125rem !important;
  line-height: 1.625rem !important;
}

.text-lg {
  font-size: 2.875rem;
}

.text-gray-dark, .post-card-container .post-card-wrapper .post-card .card-text {
  color: #364247 !important;
}

.text-gray, .banner .banner-body .banner-text {
  color: #627881 !important;
}

.text-gray-light, .post-card-container .post-card-wrapper .post-card .card-title {
  color: #81969f;
}

.text-gray-lighter {
  color: #a4b3ba;
}

.text-empty-state {
  font-style: italic;
  color: #a4b3ba;
}

@media (max-width: 768px) {
  .text-sm-center {
    text-align: center !important;
  }
}

.label {
  font-weight: 700;
  color: #627881;
}

.block-link {
  display: block;
  padding: 16px 0;
  font-family: "ProximaNova";
  color: #627881;
}

.block-link h3 {
  margin-bottom: 12px;
}

.block-link p:last-of-type {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .block-link {
    padding: 20px 0;
  }
}

.block-link:hover {
  padding: 16px 15px;
  margin: 0 -15px;
  color: #627881;
  text-decoration: none;
  background-color: #eff2f3;
}

@media (min-width: 992px) {
  .block-link:hover {
    padding: 20px 15px;
  }
}

.phone-number-accent {
  font-size: 26px;
  font-weight: 700;
  color: #1493c8;
}

.font-sofia-pro {
  font-family: "SofiaPro";
}

.font-proxima-nova {
  font-family: "ProximaNova";
}

.text-strikethrough {
  text-decoration: line-through;
}

.word-break {
  word-break: break-word;
}

.company-profile-carousel-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.company-profile-carousel-wrap::after {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  background: #1a1817;
  opacity: .85;
}

.company-profile-carousel {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
}

.company-profile-carousel .carousel-caption {
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0;
  background: linear-gradient(to bottom, rgba(26, 24, 23, 0), rgba(26, 24, 23, 0.5));
  opacity: .85;
}

.company-profile-photos {
  margin-right: .5rem;
  margin-bottom: 1rem;
}

.company-profile-photos img {
  width: 15rem;
  height: 10rem;
  cursor: pointer;
}

@media (max-width: 767.98px) {
  .company-profile-photos img {
    width: 11.25rem;
    height: 7.5rem;
  }
}

.company-profile-photos:last-child {
  margin-right: 0;
}

.carousel-image {
  display: block;
  max-width: calc(100vw - 10vw);
  max-height: calc(100vh - 25vh);
}

@media screen and (max-device-height: 440px) {
  .carousel-image {
    max-height: calc(100vh - 35vh);
  }
}

.carousel-controls-container {
  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 10000;
  margin-bottom: 2rem;
  transform: translateX(-50%);
}

@media screen and (max-device-height: 540px) {
  .carousel-controls-container {
    margin-bottom: 1rem;
  }
}

.carousel-controls-text {
  width: 5.5rem;
  font-size: 0.875rem;
  line-height: 1.25;
  color: #fff;
  text-align: center;
}

.carousel-close-container {
  position: fixed;
  top: 3%;
  right: 3%;
  z-index: 10000;
}

.carousel-action-btn {
  border: 1px solid #3e9cfa;
  padding: calc(.5rem - .0625rem) calc(.5rem - .0625rem);
  font-size: 0.625rem;
  line-height: 1;
  border-radius: 0.25rem;
}

.hide-scroll-bar {
  overflow: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.preview-loader-padding {
  padding: 50px;
}

@media (max-width: 767.98px) {
  .preview-loader-padding {
    padding: 40px;
  }
}

.anonymous-review-question-icon {
  width: 16px;
  height: 16px;
  margin-left: 4px;
  font-size: 12px;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background-color: #81969f;
  border: 1px solid #81969f;
  border-radius: 50%;
}

.anonymous-review-question-icon.active, .anonymous-review-question-icon[aria-describedby] {
  color: #81969f;
  background-color: #fff;
}

.anonymous-review-question-icon:hover {
  cursor: pointer;
}

.wp-block-quote {
  padding-left: 1em;
  margin: 0 0 1.75em;
  border-left: .25em solid;
}

.wp-block-quote.has-text-align-right {
  padding-right: 1em;
  padding-left: 0;
  border-right: .25em solid;
  border-left: none;
}

.wp-block-quote.has-text-align-center {
  padding-left: 0;
  border: 0;
}

.wp-block-table thead {
  text-align: center;
  border-bottom: 3px solid;
}

.wp-block-table td,
.wp-block-table th {
  padding: .5em;
  word-break: normal;
  border: 1px solid;
}

.wp-block-table figcaption {
  font-size: 13px;
  color: #555;
  text-align: center;
}

.wp-block-table tfoot {
  border-top: 3px solid;
}

.modal-content {
  border: 0;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.16);
}

.modal-body {
  box-shadow: inset 0 -1px 0 0 #e6ebec;
}

.modal-body .top-description {
  margin-bottom: 17px;
  font-size: 0.875rem;
  line-height: 1.43;
  color: #627881;
}

.modal-body .top-label {
  font-weight: 700;
  color: #627881;
}

.modal-body .form-group:not(.row) {
  margin-bottom: 0;
}

.modal-body .form-row,
.modal-body .sd-form-row {
  padding-top: 1.4375rem;
}

.modal-body .form-row:first-child,
.modal-body .sd-form-row:first-child {
  padding-top: .625rem;
}

.modal-body .form-row:last-child,
.modal-body .sd-form-row:last-child {
  padding-bottom: .625rem;
}

.modal-title {
  font-weight: 700;
  line-height: 1.63;
}

.fade.in {
  opacity: 1;
}

.modal.in .modal-dialog {
  transform: translate(0, 0);
}

.modal-backdrop.in {
  opacity: .7;
}

.modal-header {
  align-items: center;
  padding: 17px 20px 19px;
  border: 0;
  box-shadow: inset 0 -1px 0 0 #e6ebec;
}

.modal-footer {
  padding: .875rem 1.25rem;
  border: 0;
}

.modal-footer .btn {
  margin-right: 0;
  margin-left: .625rem;
}

@media (max-width: 991.98px) {
  .modal-footer .btn.btn-block {
    margin-left: 0;
  }
}

.modal-footer .btn-container-vertical-md-horizontal {
  display: flex;
  flex-direction: column;
}

.modal-footer .btn-container-vertical-md-horizontal .btn {
  width: 100%;
  margin-bottom: .5rem;
  margin-left: 0;
}

@media (min-width: 768px) {
  .modal-footer .btn-container-vertical-md-horizontal {
    flex-direction: row;
    float: right;
  }
  .modal-footer .btn-container-vertical-md-horizontal .btn {
    width: auto;
    margin-bottom: 0;
    margin-left: .625rem;
  }
}

.google-maps-container .angular-google-map-container {
  height: 400px;
}

.modal-content {
  padding: 20px;
}

@media (min-width: 768px) {
  .modal-content {
    padding: 50px;
  }
}

.modal-content .modal-close-button {
  position: absolute;
  top: 0;
  right: 0;
}

.modal-content .modal-close-button:hover {
  background-color: transparent;
}

.modal-header {
  padding: 0 28px 0 0;
  margin-bottom: .75rem;
  box-shadow: none;
}

@media (min-width: 768px) {
  .modal-header {
    padding-right: 0;
    margin-bottom: 1rem;
  }
}

.modal-body {
  padding: 0;
  box-shadow: none;
}

.modal-footer {
  padding: 0;
  margin-top: .5rem;
}

@media (min-width: 768px) {
  .modal-footer {
    margin-top: 1rem;
  }
}

/**
  Below specify Homedeal custom SASS files
 */
.entry-content {
  word-wrap: break-word;
}

.entry-content h1 {
  margin-top: 2rem;
  margin-bottom: .5rem;
}

@media (min-width: 992px) {
  .entry-content h1 {
    margin-top: 3.5rem;
  }
}

.entry-content h2 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

@media (min-width: 992px) {
  .entry-content h2 {
    margin-top: 3.5rem;
  }
}

.entry-content h3 {
  margin-top: 1.5rem;
  margin-bottom: .75rem;
}

@media (min-width: 992px) {
  .entry-content h3 {
    margin-top: 2.5rem;
  }
}

.entry-content h4 {
  margin-top: 1.5rem;
}

.entry-content h5,
.entry-content h6 {
  margin-top: 1rem;
}

.entry-content p {
  margin-bottom: 1.5rem;
}

.entry-content > *:last-child {
  margin-bottom: 0;
}

.entry-content img {
  max-width: 100%;
  height: auto;
}

.entry-content .article-table {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
  border: solid 1px #e6ebec;
  border-radius: .25rem;
}

.entry-content .article-table table {
  width: 100%;
  margin-bottom: 0;
}

.entry-content .article-table table th {
  padding: 8px 10px 12px;
  color: #627881;
  background-color: #e6ebec;
}

.entry-content .article-table table tbody tr:not(:last-child) {
  border-bottom: 1px solid #e6ebec;
}

.entry-content .article-table table td {
  padding: 12px 10px;
  color: #364247;
  background-color: #fff;
}

@media (min-width: 992px) {
  .entry-content .article-table table td,
  .entry-content .article-table table th {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.entry-content > h1:first-child {
  margin-top: 0;
}

.entry-content > h2:first-child {
  margin-top: 0;
}

.entry-content > h3:first-child {
  margin-top: 0;
}

.entry-content > h4:first-child {
  margin-top: 0;
}

.entry-content > h5:first-child {
  margin-top: 0;
}

.entry-content > h6:first-child {
  margin-top: 0;
}

.work-areas-selection .custom-control {
  padding-left: 1rem;
}

.work-areas-selection .custom-control-label {
  padding: 0 .5rem;
  font-size: 1rem;
}

.work-areas-selection .custom-control-label::before {
  margin: 0 .5rem;
}

.work-areas-selection .custom-control-label::after {
  margin: 0 .5rem;
}

.input-group-form .form-group {
  margin-bottom: 0;
}

.area-tool-panel .input-group {
  flex-wrap: nowrap;
}

.area-list .custom-control-label {
  color: #627881;
}

.navigation .btn-primary.btn {
  padding: .875rem 1.5rem;
  font-size: 1.125rem;
}

.navigation .back-button-navigation {
  font-size: 1rem;
  color: #627881;
}

@media (max-width: 767.98px) {
  .navigation .back-button-navigation {
    margin-top: 1.5rem;
  }
}

.number-of-requests {
  max-width: 116px;
}

.hero-no-obligation {
  margin-left: .125rem;
  font-family: "SofiaPro";
}

@media (min-width: 576px) {
  .radius-postcode {
    flex: 1 0 auto;
  }
}

.step-check .input-icon, .step-check .sd-form-row-invalid .custom-control::after, .sd-form-row-invalid .step-check .custom-control::after {
  position: static;
  color: #1493c8;
}

.step-check .input-icon.icon-valid, .step-check .sd-form-row-invalid .icon-valid.custom-control::after, .sd-form-row-invalid .step-check .icon-valid.custom-control::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%231493c8' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 9.5L7.5 15 18 4.5'/%3E%3C/svg%3E%0A");
}

.form-input-container .form-control {
  padding-right: 3rem;
}

.form-input-container .input-icon, .form-input-container .sd-form-row-invalid .custom-control::after, .sd-form-row-invalid .form-input-container .custom-control::after {
  right: 15px;
}

.banner {
  display: flex;
  flex-direction: row;
  padding-left: 1.25rem;
  overflow: hidden;
  background-color: #d0e9f4;
  border-radius: 0.25rem;
}

@media (min-width: 768px) {
  .banner {
    padding-right: 3.125rem;
    padding-left: 3.125rem;
  }
}

.banner .banner-body {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

@media (min-width: 768px) {
  .banner .banner-body {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
}

.banner .banner-body .banner-title {
  padding-right: 1.25rem;
  margin-bottom: 1rem;
}

.banner .banner-body .banner-text {
  width: 75%;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .banner .banner-body .banner-text {
    margin-bottom: 2.5rem;
  }
}

.banner.banner-sm {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.banner.banner-sm .banner-body {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.banner .banner-image {
  position: relative;
  display: flex;
  align-items: flex-end;
}

.banner .banner-image img {
  position: absolute;
  right: -2rem;
  bottom: -3.25rem;
  max-height: 225px;
}

@media (min-width: 768px) {
  .banner .banner-image img {
    right: 0;
    bottom: 0;
    max-height: 250px;
  }
}

@media (min-width: 992px) {
  .banner .banner-image img {
    position: relative;
    max-height: 305px;
  }
}

.backdrop, .menu-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  cursor: pointer;
  background: #627881;
  opacity: 0.5;
  animation: fade-in 200ms ease-in;
}

.menu-backdrop {
  z-index: 799;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
}

/* stylelint-disable */
.yoast-breadcrumb {
  margin-top: 21px;
  margin-bottom: 30px;
  font-family: "SofiaPro";
  font-size: 0.875rem;
  color: #81969f;
}

@media (min-width: 768px) {
  .yoast-breadcrumb {
    margin-bottom: 46px;
  }
}

.yoast-breadcrumb a {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  font-family: "SofiaPro";
  color: #81969f;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.yoast-breadcrumb .breadcrumb-separator ~ span a {
  margin-right: 6px;
  content: "";
}

.yoast-breadcrumb .breadcrumb-separator ~ .breadcrumb_last {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.yoast-breadcrumb .breadcrumb-separator ~ span a::before,
.yoast-breadcrumb .breadcrumb-separator ~ .breadcrumb_last::before {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 6px;
  margin-bottom: 3px;
  vertical-align: middle;
  content: "";
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3E%3Cpath fill='%2381969f' fill-rule='evenodd' d='M4.62 2.825a.5.5 0 0 1 .76-.65l3 3.5a.5.5 0 0 1 0 .65l-3 3.5a.5.5 0 1 1-.76-.65L7.341 6l-2.72-3.175z'/%3E%3C/svg%3E");
  border: none;
}

.yoast-breadcrumb .breadcrumb_last > * {
  white-space: normal;
}

/* stylelint-enable */
.btn.btn-link {
  font-size: 1rem;
  color: #1493c8;
  text-decoration: none;
}

.btn.btn-link:hover {
  text-decoration: underline;
}

.btn-hero {
  min-width: 16rem;
}

.btn-arrow, .btn-arrow-secondary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn-arrow::after, .btn-arrow-secondary::after {
  display: block;
  width: calc(1rem * 1.5);
  height: calc(1rem * 1.5);
  padding-right: calc(1rem * 1.5);
  margin-left: 0.5rem;
  content: "";
  background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 60.1 (88133) - https://sketch.com --%3E%3Ctitle%3EIcon-24-arrow_white%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Icons' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='Icons-/-24-/-arrow-green-Copy' stroke='%23FFFFFF' stroke-width='2'%3E%3Cpath d='M2,12 L22,12 M15,5 L22,12 L15,19' id='Path-25'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat;
}

.btn-arrow-secondary::after {
  background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 60.1 (88133) - https://sketch.com --%3E%3Ctitle%3EIcon-24-arrow_white%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Icons' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='Icons-/-24-/-arrow-green-Copy' stroke='%231abd5e' stroke-width='2'%3E%3Cpath d='M2,12 L22,12 M15,5 L22,12 L15,19' id='Path-25'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat;
}

@media (hover) {
  .shift-up {
    margin-top: 4px;
  }
  .shift-up:hover {
    transform: translateY(-4px);
  }
}

.copy-button .disabled-state-translation {
  display: none;
}

.copy-button.copied .initial-state-translation {
  display: none;
}

.copy-button.copied .disabled-state-translation {
  display: inline-block;
}

.btn-secondary {
  border-width: 2px;
  padding: calc(0.625rem - .125rem) calc(1.5rem - .125rem);
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.btn-secondary.btn-lg, .btn-group-lg > .btn-secondary.btn {
  padding: calc(1rem - .125rem) calc(1.5rem - .125rem);
  font-size: 1.125rem;
  line-height: 1.25rem;
  border-radius: 0.25rem;
}

.btn-secondary.btn-sm, .btn-group-sm > .btn-secondary.btn {
  padding: calc(0.34rem) calc(0.9375rem);
  font-size: 0.875rem;
  line-height: 1.428571;
  border-radius: 0.25rem;
}

@media (min-width: 768px) {
  .btn-md-inline-block {
    display: inline-block;
    width: auto;
  }
}

.company-coc-search-result .btn-custom-select-company {
  width: max-content;
}

@media (min-width: 768px) {
  .company-coc-search-result .btn-custom-select-company {
    padding: .625rem 1rem;
  }
}

@media (max-width: 767.98px) {
  .company-coc-search-result .btn-custom-select-company {
    font-size: 14px;
    height: 32px;
    line-height: 32px;
    padding: 0 1rem;
  }
}

.company-profile-carousel-wrap::after {
  background: #627881;
}

.carousel-action-btn {
  border-color: #d2dadd;
}

.carousel-action-btn img {
  filter: invert(56%) sepia(41%) saturate(6539%) hue-rotate(110deg) brightness(97%) contrast(80%);
}

.call-to-action {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.call-to-action .call-to-action-title {
  margin: 0;
}

@media (min-width: 576px) {
  .call-to-action {
    flex-direction: row;
    align-items: center;
  }
}

.call-to-action .call-to-action-button {
  margin-top: 1rem;
}

@media (min-width: 576px) {
  .call-to-action .call-to-action-button {
    margin-top: 0;
    margin-left: 2rem;
  }
}

@media (min-width: 992px) {
  .call-to-action .call-to-action-button {
    margin-left: 4rem;
  }
}

@media (min-width: 1200px) {
  .call-to-action .call-to-action-button {
    margin-left: 5rem;
  }
}

.card-image-container, .text-carousel {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  margin-right: -15px;
  margin-left: -15px;
}

@media (min-width: 768px) {
  .card-image-container, .text-carousel {
    flex-direction: row;
    margin-right: 0;
    margin-left: 0;
  }
}

.card-image-container .right-image-container .image, .text-carousel .right-image-container .image {
  width: 100%;
}

@media (min-width: 768px) {
  .card-image-container .right-image-container .image, .text-carousel .right-image-container .image {
    border-radius: 0.25rem;
  }
}

@media (min-width: 768px) {
  .card-image-container .right-image-container, .text-carousel .right-image-container {
    width: 64%;
    margin-top: 2.5rem;
  }
}

.description-card-container {
  margin-right: 15px;
  margin-left: 15px;
}

@media (min-width: 768px) {
  .description-card-container {
    z-index: 15;
    order: 0;
    width: 39%;
    margin-right: 0;
    margin-left: 0;
  }
}

.description-card-container .description-card {
  position: relative;
  padding: 1.25rem;
  margin-top: -3rem;
}

.description-card-container .description-card .description-card-title {
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .description-card-container .description-card .description-card-title {
    margin-bottom: 2rem;
  }
}

.description-card-container .description-card p {
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .description-card-container .description-card {
    padding: 1.875rem;
    margin-top: 0;
    margin-right: -2rem;
  }
}

@media (min-width: 992px) {
  .description-card-container .description-card {
    padding: 3.125rem;
  }
}

.post-card-container {
  padding-right: .5rem;
  padding-left: .5rem;
}

@media (min-width: 992px) {
  .post-card-container {
    padding-right: 0;
    padding-left: 0;
  }
}

.post-card-container .post-card-wrapper {
  padding: .5rem;
}

@media (min-width: 992px) {
  .post-card-container .post-card-wrapper {
    padding: 0 15px;
  }
}

.post-card-container .post-card-wrapper .post-card {
  height: 100%;
  transition: transform .05s ease-in;
}

.post-card-container .post-card-wrapper .post-card .card-img-top {
  object-fit: cover;
  height: 122px;
}

@media (min-width: 576px) {
  .post-card-container .post-card-wrapper .post-card .card-img-top {
    height: 200px;
  }
}

.post-card-container .post-card-wrapper .post-card .card-body {
  height: 95px;
  margin: 1.25rem;
  overflow: hidden;
}

@media (min-width: 768px) {
  .post-card-container .post-card-wrapper .post-card .card-body {
    height: auto;
  }
}

.post-card-container .post-card-wrapper .post-card .card-title {
  margin-bottom: 0;
  font-weight: 400;
}

.post-card-container .post-card-wrapper .post-card a {
  text-decoration: none;
}

.post-card-container .post-card-wrapper .post-card:hover {
  transform: translateY(-4px);
}

.accordion .card-body {
  background-color: #fff;
}

.accordion .card-body .faq-answer {
  padding-top: 0;
  padding-bottom: 1rem;
}

.accordion .card-header {
  box-shadow: none;
}

.accordion .card-header .btn {
  border: 0;
}

.dropdown-autocomplete .dropdown-menu {
  max-height: 242px;
  overflow-y: auto;
  font-size: 1.1rem;
  color: #364247;
}

.dropdown-autocomplete .dropdown-menu .dropdown-item {
  padding: 8px 13px;
  cursor: pointer;
  box-shadow: none;
}

.dropdown-autocomplete .dropdown-menu .dropdown-item.first {
  background: #eff2f3;
}

.dropdown-autocomplete .no-results .dropdown-item {
  font-size: 1.1rem;
  font-weight: 400;
  color: #627881;
}

.category-list {
  max-height: 35px;
  overflow: hidden;
  transition: max-height 250ms ease-in-out;
}

.toggle-vertical-list {
  max-height: 200px;
  overflow: hidden;
  transition: max-height 400ms ease-in-out;
}

@media (min-width: 992px) {
  .toggle-vertical-list {
    max-height: 180px;
  }
}

.sd-toggled + .navigation-list-toggle {
  color: #364247 !important;
}

.sd-toggled + .navigation-list-toggle::after {
  transform: rotate(0deg);
}

.sd-toggled + .navigation-list-toggle::after {
  display: inline-block;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23364247' fill-rule='nonzero' d='M10.716 3.645a.455.455 0 0 1 .568.71l-5 4a.455.455 0 0 1-.568 0l-5-4a.455.455 0 0 1 .568-.71L6 7.418l4.716-3.773z'/%3E%3C/svg%3E%0A") no-repeat;
  border: 0;
}

.sd-toggled + .navigation-list-toggle::after {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
}

.sd-toggled + .navigation-list-toggle::after {
  transform: rotate(180deg);
}

.sd-toggled + .navigation-list-toggle:hover {
  color: #627881 !important;
}

.sd-toggled + .navigation-list-toggle::after {
  margin-left: .25rem;
}

.faq-list-heading {
  margin-bottom: 1.5rem;
}

@media (min-width: 992px) {
  .faq-list-heading {
    margin-bottom: 2rem;
  }
}

@media (min-width: 768px) {
  .justify-content-md-end {
    justify-content: end;
  }
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 25;
  display: flex;
}

.d-soft-flex {
  display: flex;
}

.flex-align-bottom-right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.gap-0_5 {
  gap: 0.5rem;
}

.category-search-input {
  z-index: 701;
}

.category-search-input ~ .icon-clear {
  z-index: 703;
}

.category-search-input ~ .icon-loading {
  z-index: 704;
}

.category-search-backdrop {
  z-index: 700;
}

.label-description {
  font-size: 0.875rem;
}

.form-header {
  font-size: 1.5rem;
}

@media (min-width: 768px) {
  .form-header {
    font-size: 2.375rem;
  }
}

.radio-boxed {
  position: relative;
}

.radio-boxed label {
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 7px 0;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  border: solid 1px #d2dadd;
  border-radius: 0.25rem;
  box-shadow: inset 0 -1px 0 0 #d2dadd;
}

.radio-boxed label:hover:not(:checked) {
  background-color: #eff2f3;
}

.radio-boxed input[type="radio"] {
  display: none;
}

.radio-boxed input[type="radio"]:checked + label {
  font-weight: 700;
  color: #fff;
  background-color: #1abd5e;
  border: solid 1px #1abd5e;
  box-shadow: inset 0 -1px 0 0 #1abd5e;
}

.form-control.is-invalid {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='%23D9534F' fill-rule='nonzero' d='M10 8.586l5.293-5.293a1 1 0 0 1 1.414 1.414L11.414 10l5.293 5.293a1 1 0 0 1-1.414 1.414L10 11.414l-5.293 5.293a1 1 0 1 1-1.414-1.414L8.586 10 3.293 4.707a1 1 0 1 1 1.414-1.414L10 8.586z'/%3E%3C/svg%3E%0A");
}

.custom-control.control-checkbox-btn {
  padding-left: 0;
  background-color: #fff;
}

.custom-control.control-checkbox-btn label {
  padding: .75rem 0 .75rem 2.5rem;
  color: #364247;
  cursor: pointer;
}

.custom-control.control-checkbox-btn label::before, .custom-control.control-checkbox-btn label::after {
  top: 1rem;
  left: 1rem;
}

.custom-control.control-checkbox-btn.active {
  font-weight: 700;
  background-color: #d0e9f4;
}

.input-group-append input {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-append .append {
  border-left: none;
}

.input-group-append .append .input-group-text {
  justify-content: center;
  min-width: 3.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-append .append .input-group-text.has-error {
  border-color: #d9534f;
}

.input-group-append input[type="number"] {
  max-width: 120px;
}

input.hide-arrows::-webkit-outer-spin-button, input.hide-arrows::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

input.hide-arrows[type="number"] {
  -moz-appearance: textfield;
}

select {
  appearance: none;
  background-image: url("../images/icon_16_chevron-down.svg");
  background-repeat: no-repeat;
  background-position: right 16px top 50%;
}

.form-headline {
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .form-headline {
    margin-bottom: 1.5rem;
  }
}

.input-lg {
  height: 54px;
  font-size: 18px;
}

body {
  display: flex;
  flex-direction: column;
}

.page-container,
.front-page-container {
  overflow-x: hidden;
}

.b2c-page-container {
  margin-top: 1.5rem;
}

@media (min-width: 992px) {
  .b2c-page-container {
    margin-top: 2rem;
  }
}

.row.no-wrap {
  flex-wrap: nowrap;
  overflow-x: auto;
}

.row.no-wrap .col,
.row.no-wrap > [class^="col-"] {
  min-width: 300px;
}

@media (max-width: 1199.98px) {
  .row.row-narrow > [class^="col-"]:first-child {
    padding-right: 4px;
  }
  .row.row-narrow > [class^="col-"]:last-child {
    padding-left: 4px;
  }
  .row.row-narrow > [class^="col-"]:not(:first-child):not(:last-child) {
    padding-right: 4px;
    padding-left: 4px;
  }
}

.main {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 1;
}

.header, .navbar, .page-navigation-navbar, .b2b-navbar, .postcode-hero-navigation-navbar,
.footer {
  flex-shrink: 0;
}

.static-page-container {
  margin-top: 2rem;
  overflow-x: hidden;
}

@media (min-width: 768px) {
  .static-page-container {
    margin-top: 3.5rem;
  }
}

.component-container {
  margin: 2rem 0;
}

@media (min-width: 992px) {
  .component-container {
    margin: 2rem 0;
  }
}

.component-container-large {
  margin: 2rem 0;
}

@media (min-width: 992px) {
  .component-container-large {
    margin: 7rem 0;
  }
}

.market-component-container {
  margin: 3rem 0;
}

@media (min-width: 992px) {
  .market-component-container {
    margin: 5rem 0;
  }
}

.panel-container {
  margin: 1rem 0;
}

@media (min-width: 992px) {
  .panel-container {
    margin: 2rem 0;
  }
}

.call-to-action-container {
  margin: 1.5rem 0;
}

@media (min-width: 992px) {
  .call-to-action-container {
    margin: 2.5rem 0;
  }
}

.frontpage-component-container {
  margin: 4rem 0;
}

@media (min-width: 992px) {
  .frontpage-component-container {
    margin: 7rem 0;
  }
}

.component-container:first-child,
.component-container-large:first-child,
.panel-container:first-child,
.frontpage-component-container:first-child {
  margin-top: 0;
}

.component-container:last-child,
.component-container-large:last-child,
.panel-container:last-child,
.frontpage-component-container:last-child {
  margin-bottom: 0;
}

.pac-container {
  margin-top: 4px;
  border-radius: 4px;
}

.pac-container .pac-icon {
  display: none;
}

.pac-container .pac-item,
.pac-container .pac-item-query,
.pac-container .pac-matched {
  font-family: "SofiaPro";
  font-size: 14px;
}

.pac-container .pac-item {
  padding: 8px 16px;
  color: #81969f;
  border-top: none;
  border-bottom: none;
}

.pac-container .pac-item:hover {
  cursor: pointer;
  background-color: #eff2f3 !important;
}

.pac-container .pac-item-selected {
  background-color: #eff2f3 !important;
}

.pac-container .pac-item-query {
  color: #364247;
}

.pac-container .pac-matched {
  font-weight: 700;
  color: #364247;
}

.pac-container::after {
  height: 0;
  background-image: none !important;
}

.loading-icon {
  width: 36px;
  height: 36px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' %3E%3Cpath fill='%23d0e9f4' d='M20 35c-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15zm0-2c7.18 0 13-5.82 13-13S27.18 7 20 7 7 12.82 7 20s5.82 13 13 13z'/%3E%3Cpath fill='%231493c8' d='M29.192 10.808A12.96 12.96 0 0 0 20 7V5c4.142 0 7.892 1.679 10.607 4.393l-1.415 1.415z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.7s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 36px;
}

.icon-24 {
  width: 24px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.icon-checkmark {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%231493c8' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 9.5L7.5 15 18 4.5'/%3E%3C/svg%3E%0A");
}

.hero-b2b-container {
  position: relative;
  z-index: 24;
  width: 100%;
  background-color: #d0e9f4;
}

@media (min-width: 992px) {
  .hero-b2b-container.hero-b2b-container-c {
    height: 70vh;
    min-height: 538px;
    max-height: 680px;
  }
  .hero-b2b-container.hero-b2b-container-c .form-panel {
    max-width: 470px;
  }
  .hero-b2b-container .container, .hero-b2b-container .b2c-page-container, .hero-b2b-container .hero-container {
    height: calc(100% - 144px);
  }
}

.hero-b2b-container .hero-image-c {
  object-fit: cover;
  max-height: 280px;
}

@media (min-width: 576px) {
  .hero-b2b-container .hero-image-c {
    max-height: 360px;
  }
}

@media (min-width: 768px) {
  .hero-b2b-container .hero-image-c {
    max-height: 100%;
  }
}

.hero-b2b-container.hero-b2b-container-c .hero-b2b-quote-panel {
  right: 0;
  bottom: -55px;
}

@media (min-width: 576px) {
  .hero-b2b-container.hero-b2b-container-c .hero-b2b-quote-panel {
    bottom: 0;
  }
}

.hero-b2b-container .hero-b2b-image {
  position: absolute;
  top: 14.75rem;
  width: 100%;
}

@media (min-width: 992px) {
  .hero-b2b-container .hero-b2b-image {
    top: auto;
    bottom: 0;
  }
}

.hero-b2b-container .hero-b2b-image img {
  display: block;
  height: 16.25rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 992px) {
  .hero-b2b-container .hero-b2b-image img {
    height: 34.25rem;
  }
}

.hero-b2b-container .hero-b2b-form-panel {
  margin-top: 11.875rem;
}

@media (min-width: 992px) {
  .hero-b2b-container .hero-b2b-form-panel {
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .hero-b2b-container .hero-b2b-form-panel-text {
    width: 370px;
  }
}

.hero-b2b {
  position: relative;
}

@media (min-width: 992px) {
  .hero-b2b {
    padding-top: calc(72px + 3.125rem);
  }
}

.hero-b2b .hero-b2b-catch-phrase {
  padding-top: calc(64px + 2rem);
}

@media (min-width: 992px) {
  .hero-b2b .hero-b2b-catch-phrase {
    padding-top: 2.875rem;
  }
}

@media (min-width: 576px) {
  .hero-b2b-quote-panel {
    max-width: 354px;
  }
}

.hero-container {
  position: relative;
  min-height: 552px;
  padding-top: 96px;
  padding-bottom: 4rem;
}

@media (min-width: 992px) {
  .hero-container {
    display: flex;
    align-items: center;
    height: 666px;
    padding-top: 11rem;
  }
}

.hero-image {
  position: absolute;
  top: 201px;
  right: -30px;
  max-height: 437px;
}

@media (min-width: 992px) {
  .hero-image {
    top: 0;
    right: -344px;
    max-height: 720px;
  }
}

.postcode-hero-image {
  display: flex;
  position: relative;
  height: 65vh;
  min-height: 400px;
  max-height: 568px;
  margin-right: -135px;
  margin-left: -135px;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.postcode-hero-image::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
}

.postcode-hero-container {
  position: absolute;
  margin-right: 135px;
  margin-left: 135px;
  height: 340px;
  align-self: center;
  text-align: center;
}

.postcode-hero-container .postcode-hero-title {
  padding-bottom: 1rem;
  margin-bottom: 0;
  font-size: 2rem;
  color: #fff;
  text-shadow: rgba(0, 0, 0, 0.25) 0 2px 8px;
}

@media (min-width: 768px) {
  .postcode-hero-container .postcode-hero-title {
    font-size: 3rem;
  }
}

.postcode-hero-container .postcode-hero-subtitle {
  font-size: 1rem;
  font-family: "SofiaPro";
}

@media (min-width: 768px) {
  .postcode-hero-container .postcode-hero-subtitle {
    font-size: 1.25rem;
  }
}

.postcode-hero-container input,
.postcode-hero-container button {
  width: 100%;
}

@media (min-width: 768px) {
  .postcode-hero-container {
    height: auto;
  }
  .postcode-hero-container input {
    width: 330px;
  }
  .postcode-hero-container button {
    width: 290px;
  }
}

@media (min-width: 768px) {
  .postcode-hero-container .postcode-hero-form-container {
    margin: auto;
    max-width: 670px;
  }
}

.postcode-hero-container .postcode-hero-form-container .postcode-hero-text .no-obligation {
  margin: 0;
  font-weight: bold;
  color: #fff;
  line-height: 1;
}

.company-logo-container-wrapper {
  max-width: 270px;
}

.company-logo-container-wrapper .company-logo-container {
  height: 84px;
  padding: 14px 7px;
  border: 1px solid #e6ebec;
}

.company-logo-container-wrapper .company-logo-container img {
  max-width: 100%;
  max-height: 56px;
}

@media (min-width: 768px) {
  .company-logo-container-wrapper .company-logo-container {
    height: 130px;
    padding: 20px 18px;
  }
  .company-logo-container-wrapper .company-logo-container img {
    max-height: 80px;
  }
}

.market-hero-image-container {
  position: relative;
  height: 65vh;
  min-height: 400px;
  max-height: 568px;
  margin-right: -135px;
  margin-left: -135px;
  background-position: 50% 50%;
  background-size: cover;
}

.market-hero-image-container::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0));
}

.market-hero-image-container .market-hero-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 340px;
  margin: auto 135px;
}

.market-hero-image-container .market-hero-container .market-hero-trust {
  line-height: 1.25;
}

@media (min-width: 768px) {
  .market-hero-image-container .market-hero-container {
    display: block;
    height: 223px;
    margin: auto;
  }
}

@media (min-width: 768px) {
  .market-hero-image-container .market-hero-container .form-container {
    max-width: 600px;
    margin: auto;
  }
}

.market-hero-image-container .market-hero-container .form-container input,
.market-hero-image-container .market-hero-container .form-container select,
.market-hero-image-container .market-hero-container .form-container button {
  font-size: 18px;
  line-height: 24px;
}

@media (min-width: 768px) {
  .market-hero-image-container .market-hero-container .form-container input,
  .market-hero-image-container .market-hero-container .form-container select,
  .market-hero-image-container .market-hero-container .form-container button {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .market-hero-image-container .market-hero-container .form-container .description {
    margin-left: 50%;
  }
}

.market-hero-image-container .market-hero-container h1 {
  font-size: 36px;
  line-height: 40px;
}

@media (min-width: 768px) {
  .market-hero-image-container .market-hero-container h1 {
    font-size: 48px;
    line-height: 56px;
  }
}

.market-hero-image-container .market-hero-container h2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

@media (min-width: 768px) {
  .market-hero-image-container .market-hero-container h2 {
    font-size: 20px;
    line-height: 26px;
  }
}

.lead-step-progress-bar {
  display: flex;
  align-items: center;
  margin: 1.25rem 0;
  font-family: "SofiaPro";
}

@media (min-width: 992px) {
  .lead-step-progress-bar {
    margin: 1.5rem 0;
  }
}

.lead-step-progress-bar .step {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #a4b3ba;
}

@media (min-width: 992px) {
  .lead-step-progress-bar .step {
    font-size: 16px;
  }
}

.lead-step-progress-bar .step .step-label {
  line-height: 1;
}

@media (max-width: 320px) {
  .lead-step-progress-bar .step .step-label {
    display: none;
  }
}

.lead-step-progress-bar .step .step-circle {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: #e6ebec;
}

@media (min-width: 992px) {
  .lead-step-progress-bar .step .step-circle {
    width: 40px;
    height: 40px;
  }
}

.lead-step-progress-bar .step.completed, .lead-step-progress-bar .step.active {
  font-weight: 700;
}

.lead-step-progress-bar .step.completed {
  color: #1493c8;
  cursor: pointer;
}

.lead-step-progress-bar .step.completed .step-circle {
  background-color: #d0e9f4;
}

.lead-step-progress-bar .step.active {
  color: #364247;
}

.lead-step-progress-bar .step.active .step-circle {
  color: #fff;
  background-color: #1493c8;
}

.lead-step-progress-bar .step.active .step-label {
  display: block;
}

.progress-bar-icons {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 16px;
}

.progress-bar-icons .bar-line {
  flex-grow: 1;
  width: 8.33%;
  height: 2px;
}

.progress-bar-icons .bar-line:first-child {
  flex-grow: 0;
  width: 16px;
}

.progress-bar-icons .bar-line:first-child::before {
  position: absolute;
  left: -16px;
  width: 16px;
  height: 2px;
  content: "";
  background: inherit;
}

@media (min-width: 992px) {
  .progress-bar-icons .bar-line:first-child::before {
    left: -32px;
    width: 32px;
  }
}

.progress-bar-icons .bar-icon {
  width: 8px;
  height: 8px;
  border-radius: 4px;
}

.progress-bar-icons .bar-icon.active {
  width: 24px;
  height: 24px;
  border-radius: 12px;
}

.progress-bar-icons .bar-icon.active svg {
  width: 100%;
  height: 100%;
  vertical-align: inherit;
}

.progress-bar-icons .bar-complete {
  background-color: #1493c8;
}

.progress-bar-icons .bar-success {
  background-color: #1abd5e;
}

.progress-bar-icons .bar-error {
  background-color: #d9534f;
}

.progress-bar-icons .bar-todo {
  background-color: #e6ebec;
}

.progress-bar-speech-bubble {
  position: relative;
  padding: 12px 16px;
  margin-top: 1rem;
  border: 1px solid #e6ebec;
  border-radius: 4px;
}

@media (min-width: 992px) {
  .progress-bar-speech-bubble {
    padding: 32px;
  }
}

.progress-bar-speech-bubble::after {
  position: absolute;
  top: -9px;
  left: 18px;
  width: 16px;
  height: 16px;
  content: "";
  background-color: #fff;
  border: 1px solid #e6ebec;
  border-right: 0;
  border-bottom: 0;
  transform: rotate(45deg);
}

.progress-bar-speech-bubble.center::after {
  left: calc(50% - 8px);
}

.progress-bar-speech-bubble.none::after {
  content: none;
}

.progress-bar-speech-bubble.right::after {
  right: 20px;
  left: auto;
}

.progress-bar-speech-bubble p:last-child {
  margin-bottom: 0;
}

.navigation-list-toggle {
  display: block;
  padding-top: .5rem;
  padding-bottom: .5rem;
  color: #627881;
}

.navigation-list-toggle::after {
  transform: rotate(0deg);
}

.navigation-list-toggle::after {
  display: inline-block;
  content: "";
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23364247' fill-rule='nonzero' d='M10.716 3.645a.455.455 0 0 1 .568.71l-5 4a.455.455 0 0 1-.568 0l-5-4a.455.455 0 0 1 .568-.71L6 7.418l4.716-3.773z'/%3E%3C/svg%3E%0A") no-repeat;
  border: 0;
}

.navigation-list-toggle::after {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
}

@media (min-width: 992px) {
  .navigation-list-toggle {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: .75rem;
  }
}

.navigation-list-toggle:hover {
  color: #364247 !important;
  text-decoration: underline !important;
}

.nav-list-element {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.nav-list-element.active {
  font-weight: 700;
}

.nav-list-element.active::before {
  position: absolute;
  left: 0;
  width: 4px;
  height: 1.5rem;
  content: "";
  background-color: #1493c8;
}

@media (min-width: 992px) {
  .nav-list-element {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: .75rem;
  }
}

.region-col:last-child .custom-control:last-child {
  margin-bottom: 0;
}

.region-col .custom-control {
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .region-col .custom-control:last-child {
    margin-bottom: 0;
  }
}

.list-bold li::marker {
  font-weight: 700;
}

.loading-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' %3E%3Cpath fill='%23FFFFFF' d='M20 35c-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15zm0-2c7.18 0 13-5.82 13-13S27.18 7 20 7 7 12.82 7 20s5.82 13 13 13z'/%3E%3Cpath fill='%231ABD5E' d='M29.192 10.808A12.96 12.96 0 0 0 20 7V5c4.142 0 7.892 1.679 10.607 4.393l-1.415 1.415z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.7s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 48px;
}

.loading-box > div {
  opacity: .2;
}

.market-footer {
  padding-top: 2rem;
  margin-top: 3.5rem;
  background-color: #f8f9fa;
}

.market-footer .footer-bottom-list {
  align-items: normal;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.market-footer .footer-nav {
  margin-bottom: 1.5rem;
}

.market-footer hr {
  margin: 0;
}

.modal-bottom-left {
  bottom: 0;
  left: 0;
}

@media (min-width: 768px) {
  .modal-bottom-left {
    bottom: 52px;
    left: 52px;
  }
}

.modal-bottom-left.panel, .modal-bottom-left.call-to-action {
  position: absolute;
}

.modal-backdrop {
  background-color: rgba(98, 120, 129, 0.75);
}

.modal-backdrop.show {
  opacity: 1;
}

.close {
  cursor: pointer;
}

.nav-link {
  display: flex;
  padding: 0.53125rem 0.75rem;
}

@media (min-width: 992px) {
  .nav-link {
    margin-left: 1rem;
  }
}

.nav-link.btn {
  padding: 0.53125rem 2rem;
}

@keyframes absoluteMenuAnimationOpen {
  0% {
    max-height: 72px;
    background-color: rgba(255, 255, 255, 0);
  }
  33% {
    max-height: 72px;
    background-color: white;
  }
  100% {
    max-height: 100vh;
    background-color: white;
  }
}

@keyframes absoluteMenuAnimationClose {
  0% {
    max-height: 100vh;
    background-color: white;
  }
  66% {
    max-height: 72px;
    background-color: white;
  }
  100% {
    max-height: 72px;
    background-color: rgba(255, 255, 255, 0);
  }
}

.absolute-menu.closing {
  overflow: hidden;
  animation-name: absoluteMenuAnimationClose;
  animation-duration: 250ms;
  animation-timing-function: ease-out;
}

.absolute-menu.opening {
  overflow: hidden;
  animation-name: absoluteMenuAnimationOpen;
  animation-duration: 250ms;
  animation-timing-function: ease-in;
}

.absolute-menu .market-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
}

@media (min-width: 992px) {
  .absolute-menu .market-header {
    height: 4.5rem;
  }
}

.absolute-menu .market-header .header-image {
  height: 24px;
}

.homedeal-navbar {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: -12px;
}

.registration-flow-navbar {
  height: 72px;
  padding: 0;
}

.registration-flow-navbar .navbar-brand {
  padding: 0;
}

.registration-flow-footer {
  background-color: #f8f9fa;
  border-top: solid 1px #e6ebec;
}

.footer {
  padding-top: 3.5rem;
  margin-top: 3.5rem;
  background-color: #f8f9fa;
}

.page-navigation-navbar, .b2b-navbar, .postcode-hero-navigation-navbar {
  display: none;
  padding-right: 0;
  padding-left: 0;
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.16);
}

@media (min-width: 992px) {
  .page-navigation-navbar, .b2b-navbar, .postcode-hero-navigation-navbar {
    display: flex;
  }
}

.page-navigation-navbar .nav-link, .b2b-navbar .nav-link, .postcode-hero-navigation-navbar .nav-link {
  padding: 0 15px 0 15px;
  margin-left: 0;
  color: #364247;
}

.page-navigation-navbar .nav-link .nav-text, .b2b-navbar .nav-link .nav-text, .postcode-hero-navigation-navbar .nav-link .nav-text {
  padding: 15px 0 17px 0;
}

.page-navigation-navbar .nav-link.active, .b2b-navbar .nav-link.active, .postcode-hero-navigation-navbar .nav-link.active {
  color: initial;
  background-color: transparent;
}

.page-navigation-navbar .nav-link.active .nav-text, .b2b-navbar .nav-link.active .nav-text, .postcode-hero-navigation-navbar .nav-link.active .nav-text {
  color: #364247;
  box-shadow: inset 0 -4px 0 0 #1abd5e;
}

.page-navigation-navbar .nav-link:hover:not(.active) .nav-text, .b2b-navbar .nav-link:hover:not(.active) .nav-text, .postcode-hero-navigation-navbar .nav-link:hover:not(.active) .nav-text {
  box-shadow: inset 0 -4px 0 0 #e6ebec;
}

.page-navigation-navbar.expanded .nav-text, .expanded.b2b-navbar .nav-text, .expanded.postcode-hero-navigation-navbar .nav-text {
  padding: 23px 0 25px 0;
}

.b2b-navbar {
  z-index: 23;
}

.b2b-navbar .b2b-navbar-cta {
  display: none;
}

.b2b-navbar.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

@media (min-width: 1200px) {
  .b2b-navbar.sticky .b2b-navbar-cta {
    display: flex;
  }
}

.b2b-navbar.sticky + .content {
  padding-top: 64px;
}

.postcode-hero-navigation-navbar {
  z-index: auto;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1200px) {
  .postcode-hero-navigation-navbar {
    max-width: 1440px;
  }
}

.header-search-input {
  padding: 0.5625rem 0.9375rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 2px;
}

@media (min-width: 992px) {
  .header-search-input {
    padding: 0.8125rem 0.75rem;
    font-size: 1.125rem;
    line-height: 1.444444;
    border-radius: 2px;
  }
}

.header-search-button {
  margin-left: .5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

@media (min-width: 992px) {
  .header-search-button {
    padding: 0.8125rem 0.75rem;
    font-size: 1.125rem;
    line-height: 1.444444;
    border-radius: 0.25rem;
  }
}

.navbar-brand {
  margin-right: 0;
}

.panel-warning {
  padding: 20px;
  font-size: 0.875rem;
  background-color: #fcfcfc;
}

.panel, .call-to-action {
  position: relative;
  padding: 20px;
  background-color: #fff;
  border-radius: 0.25rem;
}

@media (min-width: 768px) {
  .panel, .call-to-action {
    padding: 30px;
  }
}

@media (min-width: 992px) {
  .panel, .call-to-action {
    padding: 50px;
  }
}

.panel.panel-shadowed, .panel-shadowed.call-to-action {
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.08), 0 0 14px 0 rgba(0, 0, 0, 0.16);
}

.panel.panel-bordered, .panel-bordered.call-to-action, .postcode-hero-container .postcode-hero-form-container .call-to-action.postcode-hero-panel, .postcode-hero-container .postcode-hero-form-container .panel.postcode-hero-panel {
  border: 1px solid #e6ebec;
}

.panel.panel-top, .panel-top.call-to-action {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.panel.panel-middle, .panel-middle.call-to-action {
  border-top: 0;
  border-radius: 0;
}

.panel.panel-bottom, .panel-bottom.call-to-action {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.panel.quote, .quote.call-to-action {
  background-image: url("../images/icon-40-quote-grey-300.svg");
  background-repeat: no-repeat;
  background-position: 10px 10px;
  background-size: 29px 28px;
}

.panel .panel-heading, .call-to-action .panel-heading {
  color: #364247;
}

.panel .panel-heading svg, .call-to-action .panel-heading svg {
  fill: #364247;
}

.panel-small, .call-to-action, .postcode-hero-container .postcode-hero-form-container .postcode-hero-panel {
  padding: 20px !important;
}

.panel-medium {
  padding: 30px !important;
}

.panel-large {
  padding: 50px !important;
}

@media (min-width: 576px) {
  .panel-sm-small {
    padding: 20px !important;
  }
  .panel-sm-medium {
    padding: 30px !important;
  }
  .panel-sm-large {
    padding: 50px !important;
  }
}

@media (min-width: 768px) {
  .panel-md-small {
    padding: 20px !important;
  }
  .panel-md-medium, .call-to-action {
    padding: 30px !important;
  }
  .panel-md-large {
    padding: 50px !important;
  }
}

@media (min-width: 992px) {
  .panel-lg-small {
    padding: 20px !important;
  }
  .panel-lg-medium {
    padding: 30px !important;
  }
  .panel-lg-large {
    padding: 50px !important;
  }
}

@media (min-width: 1200px) {
  .panel-xl-small {
    padding: 20px !important;
  }
  .panel-xl-medium {
    padding: 30px !important;
  }
  .panel-xl-large {
    padding: 50px !important;
  }
}

.border-top {
  border-top: 1px solid #e6ebec !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-bottom, .flex-table-data {
  border-bottom: 1px solid #e6ebec !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-right {
  border-right: 1px solid #e6ebec !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-left {
  border-left: 1px solid #e6ebec !important;
}

.border-left-0 {
  border-left: 0 !important;
}

@media (min-width: 576px) {
  .border-top-sm {
    border-top: 1px solid #e6ebec !important;
  }
  .border-top-sm-0 {
    border-top: 0 !important;
  }
  .border-bottom-sm {
    border-bottom: 1px solid #e6ebec !important;
  }
  .border-bottom-sm-0 {
    border-bottom: 0 !important;
  }
  .border-right-sm {
    border-right: 1px solid #e6ebec !important;
  }
  .border-right-sm-0 {
    border-right: 0 !important;
  }
  .border-left-sm {
    border-left: 1px solid #e6ebec !important;
  }
  .border-left-sm-0 {
    border-left: 0 !important;
  }
}

@media (min-width: 768px) {
  .border-top-md {
    border-top: 1px solid #e6ebec !important;
  }
  .border-top-md-0 {
    border-top: 0 !important;
  }
  .border-bottom-md {
    border-bottom: 1px solid #e6ebec !important;
  }
  .border-bottom-md-0 {
    border-bottom: 0 !important;
  }
  .border-right-md {
    border-right: 1px solid #e6ebec !important;
  }
  .border-right-md-0 {
    border-right: 0 !important;
  }
  .border-left-md {
    border-left: 1px solid #e6ebec !important;
  }
  .border-left-md-0 {
    border-left: 0 !important;
  }
}

@media (min-width: 992px) {
  .border-top-lg {
    border-top: 1px solid #e6ebec !important;
  }
  .border-top-lg-0 {
    border-top: 0 !important;
  }
  .border-bottom-lg {
    border-bottom: 1px solid #e6ebec !important;
  }
  .border-bottom-lg-0 {
    border-bottom: 0 !important;
  }
  .border-right-lg {
    border-right: 1px solid #e6ebec !important;
  }
  .border-right-lg-0 {
    border-right: 0 !important;
  }
  .border-left-lg {
    border-left: 1px solid #e6ebec !important;
  }
  .border-left-lg-0 {
    border-left: 0 !important;
  }
}

@media (min-width: 1200px) {
  .border-top-xl {
    border-top: 1px solid #e6ebec !important;
  }
  .border-top-xl-0 {
    border-top: 0 !important;
  }
  .border-bottom-xl {
    border-bottom: 1px solid #e6ebec !important;
  }
  .border-bottom-xl-0 {
    border-bottom: 0 !important;
  }
  .border-right-xl {
    border-right: 1px solid #e6ebec !important;
  }
  .border-right-xl-0 {
    border-right: 0 !important;
  }
  .border-left-xl {
    border-left: 1px solid #e6ebec !important;
  }
  .border-left-xl-0 {
    border-left: 0 !important;
  }
}

a.panel, a.call-to-action {
  display: flex;
  color: #627881;
}

a.panel:hover, a.call-to-action:hover {
  color: #627881;
  text-decoration: none;
}

a.panel:active, a.call-to-action:active {
  font-weight: 400;
}

a.panel.panel-bordered:active, a.panel-bordered.call-to-action:active, .postcode-hero-container .postcode-hero-form-container a.call-to-action.postcode-hero-panel:active, .postcode-hero-container .postcode-hero-form-container a.panel.postcode-hero-panel:active {
  border-color: #d2dadd;
}

a.panel.panel-shadowed:active, a.panel-shadowed.call-to-action:active {
  box-shadow: 0.5rem 0.5rem 0 0 rgba(20, 48, 102, 0.05);
}

.panel-position-absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
}

@media (max-width: 575.98px) {
  .mobile-negative-bottom-margin {
    margin-bottom: -55px;
  }
}

.clickable {
  text-decoration: none;
  cursor: pointer;
}

.clickable:hover {
  font-weight: 400;
}

.clickable:focus {
  font-weight: 400;
}

.underline-text:hover {
  text-decoration: underline;
}

.panel.panel-xs, .panel-xs.call-to-action {
  max-width: 408px;
  padding: 1rem;
  border-radius: 0;
}

@media (min-width: 768px) {
  .panel.panel-xs, .panel-xs.call-to-action {
    padding: 2rem;
  }
}

.popover.sd-popover {
  max-width: 312px;
  border-style: none;
  border-radius: 0;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15);
}

.popover.sd-popover .popover-header {
  display: flex;
  justify-content: center;
  padding: 1.5rem;
  background-color: #d0e9f4;
  border-radius: 0;
}

.popover.sd-popover .popover-header::before {
  border-bottom-color: #d0e9f4;
}

.popover.sd-popover .popover-body {
  padding: 1rem;
}

.popover.sd-popover .arrow::before {
  border-top-color: #fff;
  border-bottom-color: #fff;
}

.popover.bs-popover-bottom .arrow::after, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: #d0e9f4;
}

.popover.bs-popover-bottom .arrow::before, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::before {
  border-top-color: #d0e9f4;
  border-bottom-color: #d0e9f4;
}

.priceguide-graph {
  position: relative;
  width: 100%;
  height: 140px;
  padding-top: 1rem;
  margin-bottom: 4.75rem;
  background-image: url("../images/priceguide.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

@media (min-width: 576px) {
  .priceguide-graph {
    height: 270px;
    padding-top: 2rem;
    margin-bottom: 2.5rem;
  }
}

.priceguide-graph .title {
  font-family: "ProximaNova";
  font-size: .875rem;
  color: #627881;
}

@media (min-width: 576px) {
  .priceguide-graph .title {
    font-size: 1rem;
  }
}

.priceguide-graph .number-accent {
  font-family: "SofiaPro";
  font-size: 1rem;
  font-weight: 700;
  color: #1493c8;
}

@media (min-width: 576px) {
  .priceguide-graph .number-accent {
    font-size: 1.5rem;
  }
}

.priceguide-graph .minimum {
  position: absolute;
  bottom: -3.25rem;
  left: 0;
}

@media (min-width: 576px) {
  .priceguide-graph .minimum {
    bottom: 1.375rem;
  }
}

.priceguide-graph .average {
  float: none;
  padding-top: 1rem;
  text-align: center;
}

@media (min-width: 576px) {
  .priceguide-graph .average {
    padding-top: 2rem;
  }
}

.priceguide-graph .maximum {
  position: absolute;
  right: 0;
  bottom: -3.25rem;
  text-align: right;
}

@media (min-width: 576px) {
  .priceguide-graph .maximum {
    bottom: 1.375rem;
  }
}

.priceguide-graph .interquartile-range {
  float: none;
  margin-top: .75rem;
  text-align: center;
}

@media (min-width: 576px) {
  .priceguide-graph .interquartile-range {
    margin-top: 1.5rem;
  }
}

.priceguide-graph .interquartile-range .number-large {
  font-family: "SofiaPro";
  font-size: 1rem;
  font-weight: 700;
  color: #364247;
}

@media (min-width: 576px) {
  .priceguide-graph .interquartile-range .number-large {
    font-size: 2rem;
  }
}

.rating-label {
  display: inline-block;
  padding: 2px 10px;
  border-radius: 0;
}

.rating-box {
  align-items: center;
  justify-content: center;
  min-width: 44px;
  height: 28px;
  text-align: center;
}

.company-rating-unknown {
  font-weight: 700;
  color: #fff;
}

.company-rating-unknown.rating-box {
  background-color: #a4b3ba;
}

.company-rating-very-bad,
.rating-bad {
  font-weight: 700;
  color: #d9534f;
}

.company-rating-very-bad.rating-box,
.rating-bad.rating-box {
  background-color: #f7dddc;
}

.company-rating-bad,
.rating-poor {
  font-weight: 700;
  color: #ef7b45;
}

.company-rating-bad.rating-box,
.rating-poor.rating-box {
  background-color: #fce5da;
}

.company-rating-medium,
.rating-fair,
.company-rating-good,
.rating-good,
.company-rating-very-good,
.rating-excellent {
  font-weight: 700;
  color: #1abd5e;
}

.company-rating-medium.rating-box,
.rating-fair.rating-box,
.company-rating-good.rating-box,
.rating-good.rating-box,
.company-rating-very-good.rating-box,
.rating-excellent.rating-box {
  background-color: #d1f2df;
}

.company-registration {
  height: 100%;
  padding-top: calc(72px + 32px);
  margin-top: calc(72px * -1);
}

.company-registration label {
  font-size: 1.125rem;
}

@media (min-width: 768px) {
  .company-registration {
    padding-top: calc(72px + 40px);
  }
}

.company-registration.company-registration-confirmation {
  padding-top: 72px;
}

.company-registration .multi-step-container {
  position: relative;
  display: block;
  height: 100%;
}

.company-registration .multi-step-container .areas-select {
  max-height: 600px;
  overflow-y: auto;
}

@media (min-width: 992px) {
  .sd-mt-lg-8 {
    margin-top: 8rem !important;
  }
}

.line-container {
  position: relative;
}

.line-container .horizontal-line {
  position: absolute;
  top: 19px;
  left: 40px;
  width: 75%;
  border: 0;
  border-top: 2px dashed #d0e9f4;
}

@media (min-width: 992px) {
  .line-container .horizontal-line {
    top: 23px;
    left: 48px;
  }
}

.line-container .horizontal-line.horizontal-line-lg {
  top: 31px;
  left: 64px;
  width: 65%;
}

@media (min-width: 992px) {
  .line-container .horizontal-line.horizontal-line-lg {
    top: 47px;
    left: 96px;
  }
}

.line-container .vertical-line {
  position: absolute;
  top: 40px;
  left: 20px;
  height: 70%;
  border: 0;
  border-left: 2px dashed #d0e9f4;
}

.line-container .vertical-line.vertical-line-lg {
  top: 64px;
  left: 32px;
}

.circle {
  position: relative;
  z-index: 15;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-weight: 700;
  color: #1493c8;
  border: solid 2px #1493c8;
  border-radius: 50%;
}

@media (min-width: 992px) {
  .circle {
    width: 48px;
    height: 48px;
  }
}

.circle.circle-sm {
  width: 40px;
  height: 40px;
}

.circle.circle-lg {
  width: 64px;
  height: 64px;
}

.circle.circle-lg img {
  width: 40px;
  height: 40px;
}

@media (min-width: 992px) {
  .circle.circle-lg {
    width: 96px;
    height: 96px;
  }
  .circle.circle-lg img {
    width: 64px;
    height: 64px;
  }
}

.circle.circle-shadow {
  position: absolute;
  top: .25rem;
  left: 1.25rem;
  z-index: 5;
  background-color: #d0e9f4;
  border: 0;
}

.line-separator {
  width: 100%;
  height: 1px;
}

.speech-bubble {
  position: absolute;
  width: 156px;
  padding: .5rem 1rem;
  font-family: "SofiaPro";
  font-size: 14px;
  color: #364247;
  background: #fff;
  border-radius: .4em;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.16);
  animation: pop .2s linear 1;
}

.speech-bubble::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  margin-bottom: -8px;
  margin-left: -8px;
  content: "";
  border: 8px solid transparent;
  border-top-color: #fff;
  border-bottom: 0;
}

.speech-bubble.left::after {
  margin-left: -50px;
}

.speech-bubble.right::after {
  margin-left: 50px;
}

@keyframes pop {
  0% {
    opacity: 0;
  }
  50% {
    opacity: .5;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
  }
}

.step-navigation {
  margin-top: .5rem;
  font-family: "SofiaPro";
  font-size: 0.875rem;
}

.check-small {
  display: inline-block;
  width: 14px;
  height: 14px;
  font-family: "SofiaPro";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%231abd5e' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 9.5L7.5 15 18 4.5'/%3E%3C/svg%3E%0A");
}

.step-progress-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  font-family: "SofiaPro";
}

.step-progress-bar .step {
  z-index: 15;
  display: flex;
  align-items: center;
  color: #1493c8;
}

.step-progress-bar .step.inactive {
  color: #a4b3ba;
}

.step-progress-bar .step.inactive .circle {
  color: #a4b3ba;
  border-color: #a4b3ba;
}

.step-progress-bar .step.inactive .circle::after {
  background-color: #eff2f3;
}

.step-progress-bar .step .step-label {
  margin-left: 10px;
}

.step-progress-bar .circle {
  position: relative;
  z-index: auto;
  flex-shrink: 0;
}

@media (min-width: 992px) {
  .step-progress-bar .circle {
    width: 40px;
    height: 40px;
  }
}

.step-progress-bar .circle::after {
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: -1;
  width: 40px;
  height: 40px;
  content: "";
  background-color: #d0e9f4;
  border-radius: 50%;
}

@media (min-width: 992px) {
  .step-progress-bar .circle::after {
    width: 40px;
    height: 40px;
  }
}

.step-progress-bar .step-separator {
  flex-grow: 1;
  margin-top: 1px;
  margin-right: 10px;
  margin-left: 10px;
  border-top: 2px dashed #d0e9f4;
}

.text-carousel .carousel-item {
  height: 100%;
  transition: none;
}

.text-carousel .carousel-item.carousel-item-right, .text-carousel .carousel-item.carousel-item-left {
  transition: transform 0.15s ease, opacity 0.15s ease;
  opacity: 0;
}

@media (prefers-reduced-motion: reduce) {
  .text-carousel .carousel-item.carousel-item-right, .text-carousel .carousel-item.carousel-item-left {
    transition: none;
  }
}

.text-carousel .carousel-item.carousel-item-next, .text-carousel .carousel-item.carousel-item-prev {
  opacity: 0;
}

.text-carousel .carousel-item.carousel-item-next.carousel-item-right, .text-carousel .carousel-item.carousel-item-next.carousel-item-left, .text-carousel .carousel-item.carousel-item-prev.carousel-item-right, .text-carousel .carousel-item.carousel-item-prev.carousel-item-left {
  opacity: 1;
}

.text-carousel .carousel,
.text-carousel .carousel-inner {
  height: 100%;
}

.text-carousel .panel, .text-carousel .call-to-action {
  z-index: 1;
  height: 400px;
  margin-bottom: 31px;
}

@media (min-width: 768px) {
  .text-carousel .panel, .text-carousel .call-to-action {
    height: 430px;
  }
}

.text-carousel .text-carousel-panel-left-corner-image {
  position: absolute;
  top: -15px;
  left: -15px;
  padding: inherit;
}

@media (min-width: 992px) {
  .text-carousel .text-carousel-panel-left-corner-image {
    top: -20px;
    left: -20px;
  }
}

.text-carousel .text-carousel-panel-left-corner-image img {
  width: 40px;
  height: 40px;
}

@media (min-width: 992px) {
  .text-carousel .text-carousel-panel-left-corner-image img {
    width: 56px;
    height: 40px;
  }
}

.text-carousel .text-carousel-controls-container {
  position: absolute;
  bottom: -31px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 140px;
  height: 62px;
  background-color: #fff;
  border-radius: 31px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.08), 0 0 14px 0 rgba(0, 0, 0, 0.16);
}

.text-carousel .text-carousel-controls-container .text-carousel-control-prev,
.text-carousel .text-carousel-controls-container .text-carousel-control-next {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  height: 100%;
  border-radius: 31px;
}

.text-carousel .text-carousel-controls-container .text-carousel-control-prev img,
.text-carousel .text-carousel-controls-container .text-carousel-control-next img {
  display: block;
  width: 32px;
  height: 32px;
}

.text-carousel .text-carousel-controls-container .text-carousel-control-prev:hover,
.text-carousel .text-carousel-controls-container .text-carousel-control-next:hover {
  cursor: pointer;
}

.text-carousel .text-carousel-controls-container .text-carousel-control-prev:hover img,
.text-carousel .text-carousel-controls-container .text-carousel-control-next:hover img {
  display: block;
  width: 38px;
  height: 38px;
  transition: width .2s linear;
}

.text-carousel .text-carousel-controls-container .text-carousel-control-prev {
  padding-left: 20px;
  border-top-left-radius: 31px;
  border-bottom-left-radius: 31px;
}

.text-carousel .text-carousel-controls-container .text-carousel-control-prev img {
  margin-right: auto;
  transform: rotate(180deg);
}

.text-carousel .text-carousel-controls-container .text-carousel-control-next {
  padding-right: 20px;
  border-top-right-radius: 31px;
  border-bottom-right-radius: 31px;
}

.text-carousel .text-carousel-controls-container .text-carousel-control-next img {
  margin-left: auto;
}

.text-shadow {
  text-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
}

.text-decoration-none {
  text-decoration: none;
}

.text-decoration-none:hover {
  text-decoration: none;
}

.font-proxima-nova {
  font-family: "ProximaNova";
}

.font-size-10 {
  font-size: 10px;
}

.font-size-12 {
  font-size: 12px;
}

@media (max-width: 991.98px) {
  .font-size-sm-30 {
    font-size: 30px;
  }
}

@media (min-width: 768px) {
  .p-lg-base {
    font-size: 1rem;
    line-height: 1.5;
  }
}

.underline {
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-decoration-color: #1493c8;
  text-underline-offset: 3px;
}

.usps-list .usp-item:last-child {
  margin-bottom: 0;
}

.usps-list .usp-item img {
  width: 3rem;
  height: 3rem;
}

.clearfix {
  clear: both;
}

/*# sourceMappingURL=bootstrap-custom.css.map */